


























// Libraries
import { Component } from 'nuxt-property-decorator';
import Lottie from 'vue-lottie';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import { Prop } from '@/node_modules/nuxt-property-decorator';
import { TranslateResult } from '@/node_modules/vue-i18n';
// Services
// Store

import * as animationData from '@/static/loading-icon-data.json';

@Component({
  name: 'loading',
  components: {
    Lottie
  },
  props: {
    external: Boolean
  }
})
export default class Loading extends BaseComponent {
  // VUE.JS Props
  @Prop({ default: 2 })
  public animationSpeedMultiplier: number;
  @Prop({ default: 50 })
  public height: number;
  @Prop({ default: 50 })
  public width: number;
  @Prop({ default: '' })
  public text: string | TranslateResult;
  @Prop({ default: '1rem' })
  public fontSize: string;
  // VUEX
  // Properties
  // Fields
  private animate: any;
  private animationSpeed: number = 500;
  private animationData: object = animationData;
  // Getters
  private get gridColumnTemplateCss(): string {
    return `grid-template-columns: ${this.width}px auto;`;
  }
  private get defaultOptions(): object {
    const data: any = animationData;
    return {
      animationData: data.default
    };
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // public mounted(): void {
  //   this.animate.setSpeed(this.animationSpeedMultiplier);
  // }
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public handleAnimation(anim) {
    this.animate = anim;
    this.animate.setSpeed(this.animationSpeedMultiplier);
  }
  public start() {
    // this.updateLoading(true);
    this.loading = 1;
  }
  public finish() {
    // this.updateLoading(false);
    this.loading = 0;
  }
  // public stop() {
  //   this.animate.stop();
  // }
  // public play() {
  //   this.animate.play();
  // }
  // public pause() {
  //   this.animate.pause();
  // }
  // public onSpeedChange() {
  //   this.animate.setSpeed(this.animationSpeed);
  // }
  // Watchers
  // Emitters
}
