import { Context } from '@nuxt/types';
import { Route } from 'vue-router';
import { authClient } from '@/shared/auth';
import { PageAccessEnum } from '@/enums/page-access';

export default async function<Nuxt, Middleware>({ route, app }: Context) {
  /*
   This logic will be refactored once all routes are explicitly setting their access.
   As of now, all routes must be authenticated, except autherror page
  */
  let anonymousAccess: boolean = false;
  let authAccess = false;

  route.path === '/autherror' ? anonymousAccess = routeOption(route, 'access', PageAccessEnum.Anonymous) : authAccess = true;

  if (anonymousAccess && await authClient.isAuthenticated()) {
    app.router.back();
  }
  if (authAccess && !await authClient.isAuthenticated()) {
    await authClient.loginWithRedirect();
  }
}
export function routeOption(route: Route, key, value) {
  return route.matched.some((m) => {
    if (process.client) {
      // Client
      return Object.values(m.components).some(
        (component: any) => component.options && component.options[key] === value
      );
    } else {
      // SSR
      return Object.values(m.components).some((component: any) =>
        Object.values(component._Ctor).some(
          (ctor: any) => ctor.options && ctor.options[key] === value
        )
      );
    }
  });
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    access?: PageAccessEnum;
  }
}
