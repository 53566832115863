export enum DesiredOutcomeValues {
  MaximizeTargetValue = 'MaximizeTargetValue',
  MinimizeTargetValue = 'MinimizeTargetValue',
  HitExactValue = 'HitExactValue'
}
export enum AlarmStateEnum {
  NA = 'NA',
  Delayed = 'Delayed',
  Active = 'Active',
  Deadband = 'Deadband',
  Inactive = 'Inactive'
}
export enum SeverityEnum {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export enum VisualizationColor {
  Orange = 'Orange',
  Cyan = 'Cyan',
  Blue = 'Blue',
  Purple = 'Purple',
  Pink = 'Pink',
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green'
}

export enum SeriesTypeEnum {
  TargetOutput = 'TargetOutput',
  TargetOutputProbability = 'TargetOutputProbability',
  IncomingData = 'IncomingData',
  IncomingDataTargetActual = 'IncomingDataTargetActual',
  Metric = 'Metric',
  DataFrameOutput = 'DataFrameOutput',
  DataFrameOutputActual = 'DataFrameOutputActual'
}

export enum SeriesDataTypeEnum {
  Constant = 'Constant',
  Percentage = 'Percentage',
  Category = 'Category'
}

export enum ChartType {
  Line = 'Line',
  Area = 'Area',
  Column = 'Column',
  Gauge = 'Gauge',
  Status = 'Status'
}
