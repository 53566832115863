import moment from 'moment';
import { DateTimeInput } from '@/view-models/report-time-model';
import { IDateTimeRange, UnixEpochTime } from '@/view-models';
import { TimeTypeEnum } from '@/enums';
export type DateInput = Date | string;

export function toRelativeTime(dateTime: DateTimeInput): string {
  if (dateTime == null) {
    return '';
  }
  const m = moment(dateTime);
  return m.fromNow();
}

// EDGE does not want the T to be removed from date string. asIs is an optional parameter that determines if T is removed or not
export function asDate(date: any, asIs: boolean = false): Date {
  if (date == null) {
    return null;
  } else if (date instanceof Date) {
    return isNaN(date.valueOf()) ? null : date as Date;
  } else if (typeof date === 'number') {
    return new Date(date);
  } else if (moment.isMoment(date)) {
    return (date as moment.Moment).toDate();
  } else if (typeof date === 'string') {
    if (!asIs) {
      date = date.replace('T', ' ');
    }
    return new Date(Date.parse(date));
  } else if (typeof date === 'boolean') {
    if (!date) {
      return null;
    }
  } else {
    throw new TypeError('Bad date type: ' + date);
  }
}

export function asNumericDateString(date: any): string {
  if (date instanceof Date) {
    return moment(date).format('MM/DD/YY h:mm a');
  } else {
    throw new TypeError('Bad date type: ' + date);
  }
}

export function asUnixTimestamp(date: any): UnixEpochTime {
  const sureDate = asDate(date);
  return sureDate == null ? null : sureDate.valueOf();
}

export function timeConversion(value: number, convertFrom: TimeTypeEnum, convertTo: TimeTypeEnum, disableRounding?: boolean): number {
  if (convertFrom === convertTo || value === 0) {
    return value;
  }

  const secondsMs: number = 1000;
  const minutesMs: number = secondsMs * 60;
  const hoursMs: number = minutesMs * 60;
  const daysMs: number = hoursMs * 24;
  let conversionToMs: number = value;

  switch (convertFrom) {
    case TimeTypeEnum.Days:
      conversionToMs = conversionToMs * daysMs;
      break;
    case TimeTypeEnum.Hours:
      conversionToMs = conversionToMs * hoursMs;
      break;
    case TimeTypeEnum.Minutes:
      conversionToMs = conversionToMs * minutesMs;
      break;
    case TimeTypeEnum.Seconds:
      conversionToMs = conversionToMs * secondsMs;
      break;
    case TimeTypeEnum.Milliseconds:
    default:
      break;
  }

  let conversion: number = conversionToMs;

  switch (convertTo) {
    case TimeTypeEnum.Days:
      conversion = conversion / daysMs;
      break;
    case TimeTypeEnum.Hours:
      conversion = conversion / hoursMs;
      break;
    case TimeTypeEnum.Minutes:
      conversion = conversion / minutesMs;
      break;
    case TimeTypeEnum.Seconds:
      conversion = conversion / secondsMs;
      break;
    case TimeTypeEnum.Milliseconds:
    default:
  }

  return disableRounding ? conversion : Math.round(conversion);
}

export function timeRangeDifference(range: IDateTimeRange, timeType: TimeTypeEnum = TimeTypeEnum.Milliseconds, disableRounding?: boolean): number {
  if (range == null) {
    return null!;
  }

  let difference: number = Math.abs(range.fromDate.getTime() - range.toDate.getTime());

  switch (timeType) {
    case TimeTypeEnum.Days:
      difference = difference / (1000 * 60 * 60 * 24);
      break;
    case TimeTypeEnum.Hours:
      difference = difference / (1000 * 60 * 60);
      break;
    case TimeTypeEnum.Minutes:
      difference = difference / (1000 * 60);
      break;
    case TimeTypeEnum.Seconds:
      difference = difference / 1000;
      break;
    case TimeTypeEnum.Milliseconds:
    default:
      break;
  }

  return disableRounding ? difference : Math.round(difference);
}

export function debounce(name: string, delay: number, action: Function): void {
  window.clearTimeout(window[`debounce${name}`]);

  window[`debounce${name}`] = window.setTimeout(action, delay);
}

export function areOnSameYearDay(dateA: DateInput, dateB: DateInput): boolean {
  return moment(dateA).isSame(dateB, 'day');
}

export function asUsaDate(date: DateInput): string {
  return date == null ? '' : moment(date, 'L').format('L');
}
