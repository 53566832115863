import 'es6-promise/auto';
import { AxiosResponse, AxiosInstance } from 'axios';
import { LocaleMessageObject } from 'vue-i18n';
import BaseService from '@/services/base-service';
import { ILocalizationService } from '@/view-models';

export default class LocalizationService extends BaseService implements ILocalizationService {
	constructor(axios: AxiosInstance) {
		super('locales', axios, '');
	}

	/**
   * Returns language set
   * @param key string locale
   * @returns {Promise<any>}
   */
	public async getLanguageSetForLocale(locale: string): Promise<LocaleMessageObject> {
		const languageSet: any = await this.http
			.get(this.getUrl(`${locale}.json`))
			.then((response: AxiosResponse<LocaleMessageObject>) => response.data);
		return languageSet;
	}
}
