import { createModule, mutation, action } from 'vuex-class-component';
import {
  IHeaterViewModel,
  IDisplayValueSchemaViewModel,
  IHeaterService,
  ReportInputDisplayValueEnum,
  IUnitFormatViewModel
} from '@/view-models';
import { UnitOfMeasurementEnum } from '@/enums';
import { IHeaterStore } from '@/store/types';
import { formatInputVariableValue, defaultUOM, findUnitFormat } from '@/shared/report-helper';
import { HeaterService } from '@/services';
import { getAxios } from '@/shared/http';

const VuexModule = createModule({
  namespaced: 'heater',
  strict: false,
  target: 'nuxt'
});

export class HeaterStore extends VuexModule implements IHeaterStore {
  // State
  public heater: IHeaterViewModel = null;
  public uomCustomerSiteSchema: IDisplayValueSchemaViewModel[] = [];

  // Getters
  public get getInputValueFormatted(): (
    displayValue: ReportInputDisplayValueEnum,
    uom: UnitOfMeasurementEnum, value: number
  ) => number {
    return (displayValue: ReportInputDisplayValueEnum, uom: UnitOfMeasurementEnum, value: number): number => {
      return formatInputVariableValue(value, displayValue, uom, this.uomCustomerSiteSchema);
    };
  }
  public get getInputDefaultUom(): (displayValue: ReportInputDisplayValueEnum) => UnitOfMeasurementEnum {
    return (displayValue: ReportInputDisplayValueEnum): UnitOfMeasurementEnum => {
      return defaultUOM(displayValue, this.uomCustomerSiteSchema);
    };
  }
  public get getDisplayValueDefaultUnitFormat(): (displayValue: ReportInputDisplayValueEnum) => IUnitFormatViewModel {
    return (displayValue: ReportInputDisplayValueEnum): IUnitFormatViewModel => {
      return findUnitFormat(displayValue, this.uomCustomerSiteSchema, u => u.isDefaultUnit);
    };
  }

  // Internals
  private get heaterService(): IHeaterService {
    return new HeaterService(getAxios());
  }

  // Mutations
  @mutation
  public setHeater(heater: IHeaterViewModel): void {
    this.heater = heater;
  }
  @mutation
  public setUomCustomerSiteSchema(schema: IDisplayValueSchemaViewModel[]): void {
    this.uomCustomerSiteSchema = schema;
  }

  // Actions
  @action
  public async loadHeaterWithInputsSettings(key: string): Promise<IHeaterViewModel> {
    const heater = await this.heaterService.getHeater(key);
    this.setHeater(heater);

    return heater;
  }
}
