import { SeriesTypeEnum, SeriesDataTypeEnum } from '@/enums';
import HelperMethods from '@/shared/helper-methods';
import { IDictionary, IKeyPair } from '@/view-models';

export class RealtimeWhatIfProfile {
    constructor(name: string, variables: any[], context: any) {
        this._context = context;
        this.key = HelperMethods.newGuid();
        this.name = name;
        this.variables = variables;
        this.recommendedActions = [];
        this.appliedRecommendations = [];
    }

    private _context: any;

    public key: string;
    public name: string;
    public variables: any[];
    public recommendedActions: any[];
    public appliedRecommendations: any[];
}

export interface ISeriesItem {
    key: string;
    name: string;
    type: SeriesTypeEnum;
    dataType: SeriesDataTypeEnum;
    displayTextOnly: string;
    valueTextMap: IKeyPair<number, string>[]
}

export interface ITrendChartDataRequest {
    startDate: Date,
    endDate: Date,
    fields: string[]
}
export interface ITrendChartDataResponse {
    trendData: IDictionary<[number, number][]>;
}
