import { Plugin } from '@nuxt/types';
import { authClient, authErrorHandler } from '@/shared/auth';

const plugin: Plugin = async ({ $vxm, query, error: errorHandler }): Promise<void> => {
  try {
    await authClient.init($vxm.root.env);
    await authClient.smartHandlePostLogin(query, () => $vxm.user.updateAuthUserLoginStatus());
  } catch (error) {
    authErrorHandler(false, error, errorHandler);
  }
};

export default plugin;
