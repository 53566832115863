import { action, createModule, mutation } from 'vuex-class-component';
import { TowerService } from '@/services';
import {
  IEnvironmentVariables,
  IEquipmentIndicatorResponse,
  IStageInputIndicatorResponse, ITowerDashboardRequest,
  ITowerService,
  StageInputIndicator,
  StageInputIndicatorRequest,
  UnixEpochTime
} from '@/view-models';
import { LocalStorageByUser } from '@/shared/storage-utils';
import {
  createEquipmentIndicator,
  equipmentIndicatorsMap, formatTowerDisplayText,
  getUomByDisplayValue
} from '@/shared/tower-utils';
import {
  ChartTypesEnum,
  DashboardTimeRangesEnum,
  ReportAxis,
  StageInputIndicatorDisplayValuesEnum,
  TowerEquipmentTypeEnum,
  TowerInputTypesEnum,
  TowerStageFilterOptionEnum,
  WidgetTypes
} from '@/enums';
import { getAxios, getCarAxios } from '@/shared/http';
import { IDashboardTimeRangeSetting, ITowerStore } from '@/store';
import { asServerDateTime, dateObjectsToDateRange } from '@/view-models/report-time-model';
import {
  EquipmentIndicator,
  EquipmentIndicatorRequest,
  IObjectPropertyUpdate,
  IStageWidgetDataRequest,
  IStageWidgetDataResponse,
  ITower,
  ITowerAlertHistoryRequest,
  ITowerCustomThresholdRequest,
  ITowerHistoricalAlert,
  ITowerIndicator,
  ITowerStage,
  ITowerStageStorageModel,
  ITowerThreshold,
  ITowerThresholdsResponse,
  ITowerValidation,
  ITowerWidgetDataRequest,
  ITowerWidgetDataResponse,
  IValidationsWidgetDataRequest,
  IValidationsWidgetDataResponse,
  TowerIndicator,
  TowerValidation
} from '@/view-models/tower-view-models';
import { cloneDeep, has, partition, set } from 'lodash';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models/measurement-systems-view-model';
import CustomerPreferenceService from '@/services/customer-preference-service';

const VuexModule = createModule({
  namespaced: 'tower',
  strict: false,
  target: 'nuxt'
});

export class TowerStore extends VuexModule implements ITowerStore {
  // State
  public currentTower: ITower = null;
  public currentStage: ITowerStage = null;
  public currentTimeRange: IDashboardTimeRangeSetting = {
    type: DashboardTimeRangesEnum.OneWeek,
    range: {
      fromDate: new Date(Math.abs(new Date().getTime()) - (7 * 86400000)), // Today - (7 * Total seconds in day) = 1 Week
      toDate: new Date() // Today
    }
  };
  public currentTimeSelections: Array<UnixEpochTime> = [];
  public currentDataSampleSize: number = 400;
  public currentAlertHistory: Array<ITowerHistoricalAlert> = [];
  public towerThresholdsMap: Map<string, Array<ITowerThreshold>> = null;
  public towerStageFilter: TowerStageFilterOptionEnum = TowerStageFilterOptionEnum.All;
  public preferredMeasurementSystem: string = null;
  public uomOptions: IAvailableMeasurementSystemsViewModel[] = [];
  public env: IEnvironmentVariables = {
    baseApiUrl: 'http://localhost:5001/',
    localizationApiUrl: 'http://localhost:3000/',
    envId: 'DEV',
    authClientID: '',
    authDomain: '',
    authAudience: '',
    authErrorRedirect: {},
    childAppDomain: '',
    entityLogsApiUrl: 'http://localhost:3003/',
    burnerTreeApiUrl: 'http://localhost:4501/',
    emaApiUrl: '',
    carApiUrl: '',
    assetEditorApiUrl: '',
    notificationHubUrl: '',
    uomDashboardCustomerKey: 'deb397a6-10b9-4036-b20b-a34ed7b59f73',
    imperialUnitKey: '',
    documentStoreUrl: 'https://content-share.kesportaldev.com'
  };
  // Getters
  public get currentValidationsWidgetData(): Array<ITowerValidation> {
    if (this.currentTower.validations.length > 0 && this.currentTower.validations[0].hasOwnProperty('chartSettings')) {
      return this.currentTower.validations;
    }
  }
  public get currentTowerIndicators(): Array<ITowerIndicator> {
    if (this.currentTower.indicators?.length > 0 && this.currentTower.indicators[0].hasOwnProperty('chartSettings')) {
      return this.currentTower.indicators;
    }
  }

  // Internal
  protected get towerService(): ITowerService {
    return new TowerService(getAxios());
  }
  private get customerPreferenceService(): CustomerPreferenceService {
    return new CustomerPreferenceService(getCarAxios());
  }
  private tableFilterStorage = new LocalStorageByUser<TowerStageFilterOptionEnum>('tower-summary-stages-filter');
  private towerStageStorage = new LocalStorageByUser<ITowerStageStorageModel>('tower-stages-storage');
  // Mutations
  @mutation
  public setEnv(env: IEnvironmentVariables): void {
    this.env = env;
  }
  @mutation
  public setUomOptions(options: IAvailableMeasurementSystemsViewModel[]) {
    this.uomOptions = Object.assign([], options);
  }
  @mutation
  public setPreferredMeasurementSystem(option: string): void {
    this.preferredMeasurementSystem = option;
  }
  @mutation
  public setTower(tower: ITower): void {
    // Update the tower object to include performance indicators for each piece of equipment
    tower.stages = (tower.stages ?? []).map((stage) => {
      stage.equipment = (stage?.equipment ?? []).map((equipment) => {
        equipment.indicators = (equipmentIndicatorsMap[equipment.type] ?? [])
          .map(displayValue => createEquipmentIndicator(equipment, displayValue));
        return equipment;
    });
    stage.input = {
      indicators: Object.values(StageInputIndicatorDisplayValuesEnum).map((displayValue) => {
        return {
          displayValue,
          equipmentType: TowerEquipmentTypeEnum.None,
          type: TowerInputTypesEnum.TowerStageInput
        };
      })
    };

      return stage;
    });
    // Create formatted alert display text
    tower = formatTowerDisplayText(tower);
    // Save the modified tower object to the store
    this.currentTower = tower;
  }

  @mutation
  public setStage(stageKey: string): void {
    this.currentStage = this.currentTower?.stages.find(stage => stage.stageKey === stageKey);
  }
  @mutation
  public setStageWidgetData(response: IStageWidgetDataResponse): void {
    if (!response) { return; }
    // Reset the store to the active stage
    this.currentStage = this.currentTower?.stages.find(stage => stage.stageKey === response.stageKey);

    // Create alerts Set
    const alertsSet = new Set();
    if (this.currentStage.alerts?.length > 0) {
      for (const alert of this.currentStage.alerts) {
        alertsSet.add(alert.equipmentType.toLowerCase() + alert.displayValue.toLowerCase());
      }
    }

    // Separate equipment data from stage input data
    const [equipmentResponses, stageInputResponses] = partition(response?.inputs, { type: TowerInputTypesEnum.TowerEquipment });

    // Format response to front end friendly interface
    const equipmentIndicators = equipmentResponses.map(response => new EquipmentIndicator(response as IEquipmentIndicatorResponse));
    const stageInputIndicators = stageInputResponses.map(response => new StageInputIndicator(response as IStageInputIndicatorResponse));

    const { equipmentIndicatorMap, stageInputIndicatorMap } = this.updateIndicators(equipmentIndicators, alertsSet, stageInputIndicators);

    // Match updated equipment performance indicator to current stage equipment
    this.currentStage.equipment = this.currentStage.equipment.map((equipment) => {
      equipment.indicators = equipment.indicators.map((indicator) => {
        const equipName = indicator.equipmentName?.replace(/\s+/g, '');
        const id = indicator.equipmentName ? `${indicator?.equipmentKey}:${indicator.displayValue}:${equipName}`
        : `${indicator.equipmentKey}:${indicator.displayValue}`;
        if (equipmentIndicatorMap.has(id)) {
          return equipmentIndicatorMap.get(id);
        }
      });
      return equipment;
    });

    // Match updated stage input performance indicator to current stage input
    this.currentStage.input.indicators = this.currentStage.input.indicators?.map((indicator) => {
      const id = `${indicator.displayValue}`;
      if (stageInputIndicatorMap.has(id)) {
        return stageInputIndicatorMap.get(id);
      }
    });
  }
  private updateIndicators(equipmentIndicators: EquipmentIndicator[], alertsSet: Set<unknown>, stageInputIndicators: StageInputIndicator[]) {
    const equipmentIndicatorMap = new Map();
    for (const indicator of equipmentIndicators) {
      this.setIndicatorSettings(indicator, alertsSet);
      const name = indicator.equipmentName?.replace(/\s+/g, '');
      const indicatorKey = indicator.equipmentName ? indicator.key + `:${name}` : indicator.key;
      equipmentIndicatorMap.set(indicatorKey, indicator);
    }

    const stageInputIndicatorMap = new Map();
    for (const indicator of stageInputIndicators) {
      this.setIndicatorSettings(indicator, alertsSet);
      stageInputIndicatorMap.set(indicator.key, indicator);
    }
    return { equipmentIndicatorMap, stageInputIndicatorMap };
  }

  public setIndicatorSettings(indicator: StageInputIndicator | EquipmentIndicator, alertsSet: Set<any>) {
    // let indicator;
    indicator.chartSettings = {
      chartType: ChartTypesEnum.Line,
      widgetType: WidgetTypes.Chart,
      axis: ReportAxis.Left,
      sortOrder: 0,
      isFullScreen: false,
      scale: { min: null, max: null }
    };
    indicator.chartSettings.showTitleAtBottom = indicator.chartSettings.widgetType === WidgetTypes.Chart;

    // Add in thresholds
    let thresholdKey;
    if (indicator.type === TowerInputTypesEnum.TowerEquipment) {
      const equipmentIndicator = indicator as EquipmentIndicator;
      thresholdKey = equipmentIndicator.equipmentName
        ? `${this.currentStage.stageKey}:${equipmentIndicator.equipmentKey}:${equipmentIndicator.displayValue}:${equipmentIndicator.equipmentName}`
        : `${this.currentStage.stageKey}:${equipmentIndicator.equipmentKey}:${equipmentIndicator.displayValue}`;
    } else {
      thresholdKey = `${this.currentStage.stageKey}:${indicator.displayValue}`;
    }

    if (this.towerThresholdsMap.has(thresholdKey)) {
      indicator.thresholds = this.towerThresholdsMap.get(thresholdKey);
    }

    // Determine if indicator has alerts
    indicator.inWarningLevel = alertsSet.has(indicator.equipmentType.toLowerCase() + indicator.displayValue.toLowerCase());
    indicator.stageOrder = this.currentStage.order;

    return indicator;
  }
  @mutation
  public setTowerWidgetData(response: ITowerWidgetDataResponse): void {
    if (response && response.towerKey === this.currentTower.key) {
      // Format response to front end friendly interface
      const towerIndicators = response.inputs.map((input) => {
          return new TowerIndicator(input, response.towerKey);
      });

      for (const indicator of towerIndicators) {
        // Build each performance indicator and prepare data to be used by highcharts API
        indicator.chartSettings = {
          chartType: null,
          widgetType: WidgetTypes.Chart,
          axis: ReportAxis.Left,
          sortOrder: 0,
          isFullScreen: false,
          scale: { min: null, max: null },
          showTitleAtBottom: null
        };
        indicator.chartSettings.chartType = (indicator.chartSettings.widgetType === WidgetTypes.Chart) ? ChartTypesEnum.Line : ChartTypesEnum.Bullet;
        indicator.chartSettings.showTitleAtBottom = indicator.chartSettings.widgetType === WidgetTypes.Chart;
      }

      this.currentTower.indicators = towerIndicators;
    }
  }
  @mutation
  public setValidationsWidgetData(response: IValidationsWidgetDataResponse): void {
    if (response && response.towerKey === this.currentTower.key) {
      // Match response to current tower validations and map to front end interface
      this.currentTower.validations = this.currentTower.validations.map((validation) => {
        const validationData = response.inputs.find(item => item.validationKey === validation.validationKey);
        return new TowerValidation(Object.assign(validation, validationData));
      });

      // Build each validation widget and prepare data to be used by highcharts API
      for (const [index, validation] of this.currentTower.validations.entries()) {
        validation.type = TowerInputTypesEnum.Validations;
        validation.chartSettings = {
          chartType: ChartTypesEnum.Line,
          widgetType: WidgetTypes.Chart,
          axis: ReportAxis.Left,
          isFullScreen: false,
          showTitleAtBottom: false,
          sortOrder: index,
          scale: { min: null, max: null }
        };
      }
    }
  }
  @mutation
  public setTimeRange(settings: IDashboardTimeRangeSetting): void {
    this.currentTimeRange = settings;
  }
  @mutation
  public setCurrentTimeSelections(selections: Array<UnixEpochTime>): void {
    this.currentTimeSelections = selections;
  }
  @mutation
  public setCurrentDataSampleSize(size: number): void {
    this.currentDataSampleSize = size;
  }
  @mutation
  public updateValidationsWidgetData(propertyToUpdate: IObjectPropertyUpdate): void {
    const index = this.currentTower.validations.findIndex(validation => validation.validationKey === propertyToUpdate.validationKey);
    if (has(this.currentTower.validations[index], propertyToUpdate.path)) {
      let validationCopy = { ...this.currentTower.validations[index] };
      validationCopy = set(validationCopy, propertyToUpdate.path, propertyToUpdate.value);
      this.currentTower.validations.splice(index, 1, validationCopy);
    }
  }
  @mutation
  public updateStageWidgetData(propertyToUpdate: IObjectPropertyUpdate): void {
    if (propertyToUpdate.equipmentType === TowerEquipmentTypeEnum.None) {
      const indicatorIndex = this.currentStage.input.indicators.findIndex(indicator => indicator.key === propertyToUpdate.indicatorKey);
      const objectToUpdate = cloneDeep(this.currentStage.input.indicators[indicatorIndex]);
      if (objectToUpdate) {
        const updatedObject = set(objectToUpdate, propertyToUpdate.path, propertyToUpdate.value);
        this.currentStage.input.indicators.splice(indicatorIndex, 1, updatedObject);
      }
      return;
    }
    const equipmentIndex = this.currentStage.equipment.findIndex(equipment =>
        (equipment.equipmentKey === propertyToUpdate.equipmentKey &&
            equipment.type === propertyToUpdate.equipmentType &&
            equipment.name === propertyToUpdate.equipmentName)
    );
    const indicatorIndex = this.currentStage.equipment[equipmentIndex].indicators.findIndex(indicator => indicator.key === propertyToUpdate.indicatorKey);
    const objectToUpdate = cloneDeep(this.currentStage.equipment[equipmentIndex].indicators[indicatorIndex]);
    if (objectToUpdate) {
      const updatedObject = set(objectToUpdate, propertyToUpdate.path, propertyToUpdate.value);
      this.currentStage.equipment[equipmentIndex].indicators.splice(indicatorIndex, 1, updatedObject);
    }
  }
  @mutation
  public setTowerStageFilter(value: TowerStageFilterOptionEnum): void {
    this.tableFilterStorage.setUniqueKey(this.currentTower.key).save(value);
    this.towerStageFilter = value;
  }
  @mutation
  public setTowerThresholds(response: ITowerThresholdsResponse) {
    if (!response) { return; }
    // Remove stages with no equipment
    const stagesWithEquipment = response.stages.filter(stage => stage.equipment.length > 0 || stage.stageInputs?.length > 0);

    // Create thresholds map and save to store
    const towerThresholdsMap = new Map();
    stagesWithEquipment.forEach((stage) => {
      if (stage.stageInputs) {
        stage.stageInputs.forEach((indicator) => {
          const key = `${stage.stageKey}:${indicator.displayName}`;
          towerThresholdsMap.set(key, indicator.thresholds);
        });
      }
      stage.equipment.forEach((equipment) => {
        equipment.performanceIndicators.forEach((indicator) => {
          const key = equipment.equipmentName
              ? `${stage.stageKey}:${equipment.equipmentKey}:${indicator.displayName}:${equipment.equipmentName}`
              : `${stage.stageKey}:${equipment.equipmentKey}:${indicator.displayName}`;
          towerThresholdsMap.set(key, indicator.thresholds);
        });
      });
    });
    this.towerThresholdsMap = towerThresholdsMap;
  }
  @mutation
  public setAlertHistory(response: Array<ITowerHistoricalAlert>) {
    if (!response) { return; }
    this.currentAlertHistory = response;
    for (const alert of this.currentAlertHistory) {
      alert.chartSettings = {
        chartType: ChartTypesEnum.Line,
        widgetType: WidgetTypes.Chart,
        axis: ReportAxis.Left,
        sortOrder: 0,
        isFullScreen: true,
        scale: { min: null, max: null },
        showTitleAtBottom: false
      };
      const isStageInput = alert.equipmentType === TowerEquipmentTypeEnum.None;
      alert.data = [];
      alert.type = isStageInput ? TowerInputTypesEnum.TowerStageInput : TowerInputTypesEnum.TowerEquipment;
      alert.uom = getUomByDisplayValue(alert.displayName, alert.type);
      alert.displayValue = alert.displayName;
    }
  }

  // Actions
  @action
  public async getUomOptions(customerKey:string): Promise<IAvailableMeasurementSystemsViewModel[]> {
    const measurementSystems: IAvailableMeasurementSystemsViewModel[] =
        await this.customerPreferenceService.getAvailableMeasurementSystems(customerKey);
    this.setUomOptions(measurementSystems);
    const preferredMeasureSystem: IAvailableMeasurementSystemsViewModel = measurementSystems.filter(option => option.preferredFlag)[0];
    this.setPreferredMeasurementSystem(preferredMeasureSystem.systemKey);
    return measurementSystems;
  }
  @action
  public async loadTower(key: string): Promise<void> {
    try {
      const tower = await this.towerService.getTower(key);
      this.setTower(tower);
    } finally {
      await this.loadTowerThresholds(this.currentTower.key);
    }
  }
  @action
  public async loadStageWidgetData(measurementSystemKey?: string): Promise<void> {
    // Prepare data to match the equipment indicator request format
    const equipmentIndicators = (this.currentStage?.equipment ?? [])
      .flatMap(i => (i?.indicators ?? []))
      .map(input => new EquipmentIndicatorRequest(input));
    const stageIndicators = (Object.values(StageInputIndicatorDisplayValuesEnum).map(indicator =>
        new StageInputIndicatorRequest(indicator)));
    const requestInputs = [...equipmentIndicators, ...stageIndicators];

    // Create the payload object
    const payload: IStageWidgetDataRequest = {
      towerKey: this.currentTower.key,
      stageKey: this.currentStage.stageKey,
      range: dateObjectsToDateRange(this.currentTimeRange.range),
      sampleSize: this.currentDataSampleSize,
      inputs: requestInputs,
      measurementSystemKey: measurementSystemKey || this.preferredMeasurementSystem
    };

    // Send the payload to the tower service to retrieve stage widget data from API
    const response = await this.towerService.getStageWidgetData(payload);
    // Pass a valid non-empty response to setStageWidgetData, then resolve the promise
    if ((response?.inputs?.length ?? 0) > 0) {
      this.setStageWidgetData(response);
    }
    return Promise.resolve();
  }
  @action
  public async loadTowerWidgetData(request: ITowerDashboardRequest): Promise<void> {
    const towerKey = this.currentTower ? this.currentTower.key : request.key;
    // Create the payload object
    const payload: ITowerWidgetDataRequest = {
      towerKey,
      range: dateObjectsToDateRange(this.currentTimeRange.range),
      sampleSize: this.currentDataSampleSize,
      measurementSystemKey: request.measurementSystemKey || this.env.imperialUnitKey
    };
    // Send the payload to the tower service to retrieve tower widget data from API
    const response = await this.towerService.getTowerWidgetData(payload);

    // Pass a valid non-empty response to setStageWidgetData, then resolve the promise
    if ((response?.inputs?.length ?? 0) > 0) {
      this.setTowerWidgetData(response);
    }
    return Promise.resolve();
  }
  @action
  public async loadTowerValidations(measurementSystemKey?: string): Promise<void> {
    // Prepare the data to fetch tower validations
    const validationInputs = this.currentTower.validations.map((i) => {
      return { 'validationKey': i.validationKey };
    });

    // Create the payload object
    const payload: IValidationsWidgetDataRequest = {
      towerKey: this.currentTower.key,
      sampleSize: 40,
      range: dateObjectsToDateRange(this.currentTimeRange.range),
      inputs: validationInputs,
      measurementSystemKey: measurementSystemKey || this.preferredMeasurementSystem
    };

    // Send the payload to the tower service to retrieve tower validation data from API
    const response = await this.towerService.getValidationWidgetData(payload);

    // Pass a valid non-empty response then resolve the promise
    if ((response?.inputs?.length ?? 0) > 0) {
      this.setValidationsWidgetData(response);
    }
    return Promise.resolve();
  }
  @action
  public async loadTowerThresholds(key: string): Promise<void> {
    try {
      const response = await this.towerService.getTowerThresholds(key);
      this.setTowerThresholds(response);
    } finally {}
  }
  @action
  public async loadAlertHistory(request: ITowerAlertHistoryRequest): Promise<void> {
    try {
      const response = await this.towerService.getAlertHistory(request);
      this.setAlertHistory(response);
    } finally {}
  }
  @action
  public async loadHistoricalAlertData(alert): Promise<void> {
    if (alert) {
      const date = new Date(alert.alertTimestamp);
      const fromDate = asServerDateTime(date.getTime() - 43200000);
      const toDate = asServerDateTime(date.getTime() + 43200000);
      const type = alert.equipmentType === TowerEquipmentTypeEnum.None
          ? TowerInputTypesEnum.TowerStageInput
          : TowerInputTypesEnum.TowerEquipment;
      const payload: IStageWidgetDataRequest = {
        towerKey: alert.towerKey,
        stageKey: alert.stageKey,
        range: {
          fromDate,
          toDate
        },
        sampleSize: 100,
        inputs: [
          {
            equipmentKey: alert.equipmentKey,
            type,
            equipmentType: alert.equipmentType,
            displayValue: alert.displayName,
            equipmentName: alert.equipmentName
          }
        ],
        measurementSystemKey: null
      };

      const response = await this.towerService.getStageWidgetData(payload);
      if (response) {
        alert.data = response.inputs[0].data;
        return alert;
      }
    }
  }

  @action
  public async saveCustomThreshold(request: ITowerCustomThresholdRequest): Promise<void> {
    try {
      const response = await this.towerService.storeCustomThreshold(request);
      this.setTowerThresholds(response);
    } finally {
    }
  }
  @action
  public async removeCustomThreshold(request: ITowerCustomThresholdRequest): Promise<void> {
    try {
      const response = await this.towerService.removeCustomThreshold(request);
      this.setTowerThresholds(response);
    } finally {
    }
  }
}
