import { EventEmitter } from 'events';
import {
  HubConnectionBuilder,
  HubConnection,
  IHttpConnectionOptions,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';

export interface ISocketConnectionOptions {
  connection: string;
  debug?: boolean;
  errorHandler?: (error: any) => void;
  token?: string;
  retries?: number;
  accessTokenFactory?: () => string | Promise<string>;
}

export interface ICustomerOrientedMessage {
  customerKey: string;
}

export function isICustomerOrientedMessage(msg: any): msg is ICustomerOrientedMessage {
  return msg != null && (msg as ICustomerOrientedMessage).customerKey !== undefined;
}

export type ListenerArg = ICustomerOrientedMessage | any;

export type SocketListener = (...args: ListenerArg[]) => (void | Promise<void>);
export interface ISocketHandlerConfig {
  listener: SocketListener;
}
export type SocketHandler = SocketListener | ISocketHandlerConfig;
export function isISocketHandlerConfig(handler: any): handler is ISocketHandlerConfig {
  return handler != null && (handler as ISocketHandlerConfig).listener !== undefined;
}
export function toSocketHandlerConfig(handler: SocketHandler): ISocketHandlerConfig {
  return isISocketHandlerConfig(handler) ? handler : { listener: handler };
}

const defaultOptions: ISocketConnectionOptions = {
  connection: 'http://localhost:5000',
  debug: false,
  token: null,
  retries: 8
};

export class SocketConnection extends EventEmitter {
  public options: ISocketConnectionOptions;

  private listened: string[] = [];
  private socket: HubConnection = null;
  private retried: number = 0;
  private deDup: { [key: string]: string[] } = {};

  constructor(options: ISocketConnectionOptions) {
    super();

    this.options = Object.assign({}, defaultOptions, { accessTokenFactory: () => this.options.token }, options);
    this.handleDebug('Configured Options', this.options);

    this.initialize();
  }

  get offline(): boolean {
    return this.socket == null || this.socket.state === HubConnectionState.Disconnected;
  }

  private initialize(): void {
    try {
      const options: IHttpConnectionOptions = {
        accessTokenFactory: this.options.accessTokenFactory
      };
      this.socket = new HubConnectionBuilder()
        .withUrl(this.options.connection, options)
        .configureLogging(this.options.debug ? LogLevel.Trace : LogLevel.None)
        .withAutomaticReconnect()
        .build();
      this.socket.onclose((error: Error) => {
        this.retry(error);
      });
      this.emit('init');
    } catch (error) {
      this.handleError(error);
    }
  }

  private async retry(error: Error): Promise<void> {
    if (error != null) {
      this.handleError(error);
      if (this.retried < this.options.retries) {
        this.handleDebug('Reconnecting...', error);
        await setTimeout(async () => {
          await this.socket.start().then(() => {
            this.retried = 0;
            this.emit('start');
          });
        }, 15000);
      } else {
        this.handleDebug(`Reached retry limit of ${this.options.retries}`);
        this.retried = 0;
      }
    }
  }

  private handleError(error: any): void {
    if (error != null) {
      if (this.options.errorHandler != null) {
        this.options.errorHandler(error);
      } else {
        // eslint-disable-next-line
        console.error(error);
      }
    }
  }

  private handleDebug(message: string, ...args: any[]): void {
    if (this.options.debug) {
      // eslint-disable-next-line
      console.log(`%c[Vue-SignalR] ${message}`, 'color: #888', ...args);
    }
  }

  public async start() {
    if (!this.offline) {
      this.handleDebug('Cannot call start, active socket. Call Stop or Authenticate instead.');
      return;
    }
    if (this.socket == null) {
      this.initialize();
    }
    this.retried++;
    await this.socket.start().then(() => {
      this.retried = 0;
      this.emit('start');
      this.handleDebug('Sockets Started');
    });
  }

  public async stop() {
    if (this.offline) {
      this.handleDebug('Cannot call stop, no active socket. Call start first.');
    } else {
      await this.socket.stop();
    }
  }

  public async authenticate(accessToken: string) {
    this.options.token = accessToken;
    if (this.offline) {
      await this.start();
    }
  }

  public listen(method: string) {
    if (this.listened.some(v => v === method)) {
      return;
    }
    this.listened.push(method);
    this.deDup[method] = [];

    if (!this.offline) {
      this.bindListener(method);
    } else {
      this.once('start', () => {
        this.bindListener(method);
      });
    }
  }

  private bindListener(method: string) {
    this.socket.on(method, (...args: ListenerArg[]) => {
      this.handleDebug('received', method, ...args);
      if (!this.isDup(method, ...args)) {
        this.emit(method, ...args);
      }
    });
    this.handleDebug('listener registered', method);
  }

  private isDup(method: string, ...args: any[]): boolean {
    const arg: any = this.findFirstObjectInNestedArrays(args);
    if (arg != null && arg.hasOwnProperty('key')) {
      if (this.deDup[method].some((key: string) => key === arg.key)) {
        this.handleDebug('Socket Message isDup');
        return true;
      } else {
        this.deDup[method].push(args[0].key);
        if (this.deDup[method].length > 10) {
          this.deDup[method].shift();
        }
        this.handleDebug('Socket Message DeDuped', method, arg.key, this.deDup[method]);
      }
    }
    return false;
  }

  private findFirstObjectInNestedArrays(array: any[]): object {
    let value: object = null;
    if (Array.isArray(array[0])) {
      value = this.findFirstObjectInNestedArrays(array[0]);
    } else if (typeof array[0] === 'object') {
      value = array[0];
    }
    return value;
  }

  public async send(method: string, ...args: any[]): Promise<void> {
    this.handleDebug('send', method, args);
    if (!this.offline) {
      await this.socket.send(method, ...args);
    } else {
      await this.once('start', () => this.socket.send(method, ...args));
    }
  }

  public async invoke<R>(method: string, ...args: any[]): Promise<R> {
    this.handleDebug('invoke', method, args);
    if (!this.offline) {
      return this.socket.invoke<R>(method, ...args);
    } else {
      await this.once('start', () => this.socket.invoke<R>(method, ...args));
    }
  }
}
