export enum LogType {
    Task = 'Task',
    TaskCompletion = 'TaskCompletion',
    Log = 'Log'
  }

export enum LogOperations {
    Edit = 'Edit',
    Delete = 'Delete'
  }
