import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { DebugLogViewModel, ErrorLogViewModel, ILoggerService } from '@/view-models';

export default class LoggerService extends BaseService implements ILoggerService {
	constructor(axios: AxiosInstance) {
		super('logger', axios);
	}
	/**
   * Sends an error object to be logged in the back-end logger.
   * @param error
   * @returns {Promise<void>}
   */
	public async postError(error: ErrorLogViewModel): Promise<void> {
		await this.http
			.post(this.getUrl('error'), error)
			.then(() => {})
			.catch(this.catch);
	}

	/**
   * Sends a debug message to be logged in the back-end logger.
   * @param message
   * @returns {Promise<void>}
   */
	public async postDebug(message: DebugLogViewModel): Promise<void> {
		await this.http
			.post(this.getUrl('debug'), message)
			.then(() => {})
			.catch(this.catch);
	}

	private catch(error) {
		// eslint-disable-next-line no-console
		console.error(error);
	}
}
