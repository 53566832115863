import Vue from 'vue';
import { action, createModule, mutation } from 'vuex-class-component';
import { getCarAxios } from '@/shared/http';
import { newGuid } from '@/shared/string-utils';
import { DashboardService, VariableDataService } from '@/services';
import { ICopyDashboardViewModel, IDashboardConfigViewModel, IDashboardViewModel, IVariableDetailsResponseViewModel } from '@/view-models';
import { IDashboardStoreV2 } from '@/store/types/dashboard-v2';
import { IChartVariableViewModel, IWidgetViewModel } from '@/view-models/widget-view-models';
import { deepClone, enumObject } from '@/shared/object-utils';
import { globalAppCatch } from '@/shared/helper-methods';
import { DashboardType, GridColumnSize, VisualizationColor, WidgetTypes } from '@/enums';

const VuexModule = createModule({
  namespaced: 'dashboard-v2',
  strict: false,
  target: 'nuxt'
});

export class DashboardStoreV2 extends VuexModule implements IDashboardStoreV2 {
  // #region State
  public dashboardService: DashboardService;
  public variableDataService: VariableDataService;
  public dashboardConfig: IDashboardConfigViewModel;
  public dashboards: Record<string, IDashboardViewModel>;
  public selectedDashboardKey: string;
  public tempWidget: IWidgetViewModel;
  public dashboardVariablesDetails: Array<IVariableDetailsResponseViewModel>;

  constructor() {
    super();
    this.resetState();
  }
  // #endregion State

  // #region Getters
  public get orderedDashboardKeys(): string[] {
    return this.dashboardConfig != null && Array.isArray(this.dashboardConfig.orderedDashboardKeys)
      ? this.dashboardConfig.orderedDashboardKeys
      : [];
  }
  public get nextAvailableColor() {
    const usedColors = [...new Set(this.tempWidget.orderedChartVariables.map(chartVar => chartVar.color))];
    const allColors: VisualizationColor[] = enumObject(VisualizationColor).values;
    const remaining = allColors.filter(color => !usedColors.includes(color));

    return remaining[0] ? remaining[0] : VisualizationColor.Blue;
  }
  // #endregion Getters

  // #region Mutations
  @mutation
  public resetState(): void {
    this.dashboardService = null;
    this.variableDataService = null;
    this.dashboardConfig = null;
    this.dashboards = {};
    this.selectedDashboardKey = null;
    this.tempWidget = null;
    this.dashboardVariablesDetails = [];
  }
  @mutation
  public setDashboardVariablesDetails(variables: Array<IVariableDetailsResponseViewModel>): void {
    variables.forEach((v) => {
      const foundVariable: IVariableDetailsResponseViewModel = this.dashboardVariablesDetails.find(d => d.key === v.key);

      if (foundVariable == null) {
        this.dashboardVariablesDetails.push(v);
      }
    });
  }
  @mutation
  public setDashboardService([customerKey, parentKey]: [string, string]): void {
    if (this.dashboardService == null) {
      this.dashboardService = new DashboardService(getCarAxios(), customerKey, parentKey);
    }
  }
  @mutation
  public setVariableDataService(): void {
    if (this.variableDataService == null) {
      this.variableDataService = new VariableDataService(getCarAxios());
    }
  }
  @mutation
  public setSelectedDashboardKey(dashboardKey: string): void {
    this.selectedDashboardKey = dashboardKey;
  }
  @mutation
  public updateTempWidget(updateOb: IWidgetViewModel): void {
    if (updateOb != null) {
      // if gauge type, change to status type for dashboard v2
      if (this.tempWidget != null && (this.tempWidget.type === WidgetTypes.Gauge || this.tempWidget.type === WidgetTypes.BulletGauge)) {
        this.tempWidget.type = WidgetTypes.Status;
      }

      const typeChanged: boolean = this.tempWidget != null && this.tempWidget.type !== updateOb.type;
      // If user changes widget type while inside widget editor, clear the variables
      if (typeChanged) {
        updateOb.orderedChartVariables = [];
      }
    }
    this.tempWidget = updateOb;
  }
  @mutation
  public updateTempWidgetVariable(chartVariable: IChartVariableViewModel): void {
    if (chartVariable) {
      if (chartVariable.key == null || chartVariable.key.trim() === '') {
        chartVariable.key = newGuid();
      }

      const chartVariableIndex: number = this.tempWidget.orderedChartVariables.findIndex(w => w.key === chartVariable.key);

      if (chartVariableIndex >= 0) {
        this.tempWidget.orderedChartVariables.splice(chartVariableIndex, 1, chartVariable);
      } else {
        this.tempWidget.orderedChartVariables.push(chartVariable);
      }
    }
  }
  @mutation
  private saveDashboardConfigLocal(config: IDashboardConfigViewModel): void {
    this.dashboardConfig = config;
  }
  @mutation
  private saveDashboardLocal(dashboard: IDashboardViewModel): void {
    if (this.dashboards == null) {
      this.dashboards = {};
    }

    if (this.dashboards[dashboard.key] != null) {
      this.dashboards[dashboard.key] = dashboard;
    } else {
      Vue.set(this.dashboards, dashboard.key, dashboard);
    }
  }
  @mutation
  private removeDashboardLocal(dashboardKey: string): void {
    if (this.dashboards[dashboardKey] != null) {
      Vue.delete(this.dashboards, dashboardKey);
    }
  }
  // #endregion Mutations

  // #region Actions
  @action
  public async initDashboards(): Promise<void> {
    if (this.dashboardService == null) {
      return;
    }

    const dashboardConfig: IDashboardConfigViewModel = await this.dashboardService.getDashboardConfig();

    if (!dashboardConfig.orderedDashboardKeys.length) {
      let newDashboard: IDashboardViewModel = {
        key: '',
        name: '',
        type: DashboardType.UserDashboard,
        gridColumnSize: GridColumnSize.Eight,
        orderedWidgets: []
      };

      newDashboard = await this.dashboardService.createDashboard(newDashboard);
      this.saveDashboardLocal(resetDeprecatedOrIncorrectValues(newDashboard));
      this.setSelectedDashboardKey(newDashboard.key);

      dashboardConfig.orderedDashboardKeys.push(newDashboard.key);
      this.saveDashboardConfigLocal(dashboardConfig);
    } else {
      this.saveDashboardConfigLocal(dashboardConfig);

      const dashboards: Array<IDashboardViewModel> = await this.dashboardService.getDashboards(dashboardConfig.orderedDashboardKeys);

      dashboards.forEach((dashboard: IDashboardViewModel, index: number) => {
        if (index === 0) {
          this.setSelectedDashboardKey(dashboard.key);
        }

        this.saveDashboardLocal(resetDeprecatedOrIncorrectValues(dashboard));
      });
    }
  }
  @action
  public async saveDashboardOrder(newOrderedDashboardKeys: string[]): Promise<void> {
    if (this.dashboardService == null) {
      return;
    }

    if (this.dashboardConfig != null) {
      const originalDashboardConfig: IDashboardConfigViewModel = this.dashboardConfig;
      const newDashboardConfig: IDashboardConfigViewModel = deepClone(originalDashboardConfig);
      newDashboardConfig.orderedDashboardKeys = newOrderedDashboardKeys;

      this.saveDashboardConfigLocal(newDashboardConfig);

      try {
        const savedDashboardConfig: IDashboardConfigViewModel = await this.dashboardService.saveDashboardConfig(newDashboardConfig);

        this.saveDashboardConfigLocal(savedDashboardConfig);
      } catch (e) {
        this.saveDashboardConfigLocal(originalDashboardConfig);

        globalAppCatch(e);
      }
    }
  }
  @action
  public async saveDashboard(dashboard: IDashboardViewModel): Promise<void> {
    if (this.dashboardService == null) {
      return;
    }
    let originalDashboard: IDashboardViewModel;
    let savedDashboard: IDashboardViewModel;

    if (dashboard.key != null) {
      originalDashboard = this.dashboards[dashboard.key];

      this.saveDashboardLocal(dashboard);
    }

    try {
      if (dashboard.key == null) {
        savedDashboard = await this.dashboardService.createDashboard(dashboard);
      } else {
        savedDashboard = await this.dashboardService.saveDashboard(dashboard);
      }
      this.saveDashboardLocal(resetDeprecatedOrIncorrectValues(savedDashboard));
    } catch (e) {
      if (originalDashboard != null) {
        this.saveDashboardLocal(originalDashboard);
      }

      globalAppCatch(e);
    }
  }
  @action
  public async removeDashboard(dashboardKey: string): Promise<void> {
    if (this.dashboardService == null) {
      return;
    }

    const originalDashboard: IDashboardViewModel = this.dashboards[dashboardKey];
    this.removeDashboardLocal(dashboardKey);

    try {
      const dashboardKeyResponse: string = await this.dashboardService.removeDashboard(dashboardKey);

      this.removeDashboardLocal(dashboardKeyResponse);
    } catch (e) {
      if (originalDashboard != null) {
        this.saveDashboardLocal(originalDashboard);
      }

      globalAppCatch(e);
    }
  }
  @action
  public async saveWidget([dashboardKey, updatedWidget]: [string, IWidgetViewModel]): Promise<void> {
    if (this.dashboardService == null) {
      return;
    }

    const originalDashboard: IDashboardViewModel = this.dashboards[dashboardKey];

    if (originalDashboard != null) {
      if (updatedWidget.key == null || updatedWidget.key.trim() === '') {
        updatedWidget.key = newGuid();
      }

      const clonedDashboard: IDashboardViewModel = deepClone(originalDashboard);
      const indexOfWidget: number = clonedDashboard.orderedWidgets.findIndex(widget => widget.key === updatedWidget.key);

      if (indexOfWidget >= 0) {
        clonedDashboard.orderedWidgets.splice(indexOfWidget, 1, updatedWidget);
      } else {
        clonedDashboard.orderedWidgets.unshift(updatedWidget);
      }
      this.saveDashboardLocal(clonedDashboard);

      try {
        const dashboardResponse: IDashboardViewModel = await this.dashboardService.saveDashboard(clonedDashboard);

        this.saveDashboardLocal(resetDeprecatedOrIncorrectValues(dashboardResponse));
      } catch (e) {
        this.saveDashboardLocal(originalDashboard);

        globalAppCatch(e);
      }
    }
  }
  @action
  public async removeWidget([dashboardKey, widgetKey]: [string, string]): Promise<void> {
    if (this.dashboardService == null) {
      return;
    }

    const originalDashboard: IDashboardViewModel = this.dashboards[dashboardKey];

    if (originalDashboard != null) {
      const clonedDashboard: IDashboardViewModel = deepClone(originalDashboard);
      const indexOfWidget: number = clonedDashboard.orderedWidgets.findIndex(widget => widget.key === widgetKey);

      if (indexOfWidget >= 0) {
        clonedDashboard.orderedWidgets.splice(indexOfWidget, 1);

        this.saveDashboardLocal(clonedDashboard);
        try {
          const dashboardResponse: IDashboardViewModel = await this.dashboardService.saveDashboard(clonedDashboard);

          this.saveDashboardLocal(dashboardResponse);
        } catch (e) {
          this.saveDashboardLocal(originalDashboard);

          globalAppCatch(e);
        }
      }
    }
  }
  @action
  public async getVariableDetailsByKeys(variableKeys: string[]): Promise<void> {
    this.setVariableDataService();

    try {
      const detailsResponse: Array<IVariableDetailsResponseViewModel> = await this.variableDataService.getVariableDetailsByKeys(variableKeys);

      this.setDashboardVariablesDetails(detailsResponse);
    } catch (e) {
      globalAppCatch(e);
    }
  }
  @action
  public async copyDashboard(request: ICopyDashboardViewModel): Promise<string[]> {
    if (this.dashboardService == null) {
      return;
    }
    try {
      const failedVariableNames: string[] = await this.dashboardService.copyDashboard(request);
      return failedVariableNames;
    } catch (e) {
      globalAppCatch(e);
    }
  }
  // #endregion Actions
}

function resetDeprecatedOrIncorrectValues(dashboard: IDashboardViewModel): IDashboardViewModel {
  dashboard.orderedWidgets.forEach((w) => {
    w.type = w.type === WidgetTypes.Gauge || w.type === WidgetTypes.BulletGauge ? WidgetTypes.Status : w.type;
    w.orderedChartVariables =
      (w.orderedChartVariables as Array<IChartVariableViewModel>).map(v => resetChartVariableValues(v));
  });

  return dashboard;
}

function resetChartVariableValues(chartVariable: IChartVariableViewModel): IChartVariableViewModel {
  chartVariable.groupKey = chartVariable.groupKey ?? newGuid();
  chartVariable.color = (chartVariable.color as string) === 'Emerald' ? VisualizationColor.Green : chartVariable.color;
  chartVariable.thickness = chartVariable.thickness == null ? 2 : chartVariable.thickness;
  chartVariable.lightness = chartVariable.lightness == null ? 1 : chartVariable.lightness;
  chartVariable.thresholds = chartVariable.thresholds ?? [];
  chartVariable.thresholds.forEach((t) => {
    t.color = (t.color as string) === 'Emerald' ? VisualizationColor.Green : t.color;
    t.lightness = t.lightness == null ? 1 : t.lightness;
  });

  return chartVariable;
}
