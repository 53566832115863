import { createModule, mutation, action } from 'vuex-class-component';
import { getAxios } from '@/shared/http';
import { IDateRange, IEnvironmentVariables, ILogsService } from '@/view-models';
import { IAlertsStore } from '@/store/types/alerts';
import { DiagnosticScenario, DiagnosticTask, ITaskLogViewModel } from '@/view-models/assets';
import LogsService from '@/services/logs-service';
import BurnerReadingService from '@/services/burner-reading-service';
import { IDownloadImageRequest, IImageCache, ILogImageViewModel } from '@/enums';
import { areOnSameYearDay as areOnSameDay } from '@/shared/date-time-utils';

const VuexModule = createModule({
  namespaced: 'alerts',
  strict: false,
  target: 'nuxt'
});

export class AlertsStore extends VuexModule implements IAlertsStore {
  private get logsService(): ILogsService {
    return new LogsService(getAxios(), this.env.entityLogsApiUrl);
  }
  private get burnerReadingService() {
    return new BurnerReadingService(getAxios(), this.env.burnerTreeApiUrl);
  }
  public alerts = [];
  public currentTask: DiagnosticTask = null;
  public currentAssetClass: string = '';
  public diagnosticTasks: Array<DiagnosticTask> = [];
  public diagnosticScenario: Array<DiagnosticScenario> = [];
  public taskLogs: Array<ITaskLogViewModel> = [];
  public imageCache: any = [];
  public imageSource: string = '';
  public galleryThumbnail: Array<ILogImageViewModel> = [];
  public selectedImages: Array<ILogImageViewModel> = [];
  public currentCompletedDate: string = '';
  public selectedScenarioKey;
  public scenarioIndex: number = 0;
  public currentTime: string = '';
  public env: IEnvironmentVariables = {
    baseApiUrl: 'http://localhost:5001/',
    localizationApiUrl: 'http://localhost:3000/',
    envId: 'DEV',
    authClientID: '',
    authDomain: '',
    authAudience: '',
    authErrorRedirect: {},
    childAppDomain: '',
    entityLogsApiUrl: 'http://localhost:3003/',
    burnerTreeApiUrl: 'https://localhost:8090/',
    emaApiUrl: '',
    carApiUrl: '',
    assetEditorApiUrl: '',
    notificationHubUrl: '',
    uomDashboardCustomerKey: 'deb397a6-10b9-4036-b20b-a34ed7b59f73',
    imperialUnitKey: '',
    documentStoreUrl: 'https://content-share.kesportaldev.com'
  };
  public logsByDate: any = [];
  public logLoading: boolean = false;
  public assetScenarioHistory: any = {};
  public activeDateRange : IDateRange = null;
  public latestRequestedDate = null;
  public pauseLiveUpdates : boolean = false;
  public burners: any = [];
  public isLoading : boolean = false;

  public get areWeLive(): boolean {
    return this.activeDateRange != null && this.latestRequestedDate != null && this.activeDateRange.endDate >= this.latestRequestedDate && !this.pauseLiveUpdates;
  }

  @mutation
  public setCurrentTask(task: DiagnosticTask): void {
    this.currentTask = task;
  }

  @mutation
  public setCurrentAssetClass(param: string): void {
    this.currentAssetClass = param;
  }
  @mutation
  public setDiagnosticScenarios(diagnosticData: Array<DiagnosticScenario>): void {
    this.diagnosticScenario = diagnosticData;
  }
  @mutation
  public setDiagnosticTasks(diagnosticTaskData: Array<DiagnosticTask>): void {
    this.diagnosticTasks = diagnosticTaskData;
  }
  @mutation
  public setTaskLogs(taskLogs: Array<ITaskLogViewModel>): void {
    this.taskLogs = taskLogs;
  }
  @mutation
  public arrangeLogsByDate(taskLogs): any {
    let logsByDate = [];
    const allLogs = taskLogs;
    if (allLogs.data.length < 1) { return; }

    allLogs.data.forEeach((_, allLogsIndex) => {
      allLogs.data[allLogsIndex].logCollection = [];
      if (logsByDate.length === 0) {
        logsByDate.push(allLogs.data[allLogsIndex]);
      } else {
        let isItemExist = false;
        for (let j = 0; j < logsByDate.length; j++) {
          if (areOnSameDay(allLogs.data[allLogsIndex].lastModifiedAt, logsByDate[j].lastModifiedAt)) {
            isItemExist = true;
            break;
          }
        }
        if (!isItemExist) {
          logsByDate.push(allLogs.data[allLogsIndex]);
        }
      }
    });

    logsByDate = this.groupSameDayAlerts(allLogs, logsByDate);

    this.logsByDate = logsByDate;
  }

  private groupSameDayAlerts(allLogs: any, logsByDate: any[]) {
    return allLogs.data.forEach((_, allLogsIndex) => {
      logsByDate.forEach((_, logByDateIndex) => {
        if (areOnSameDay(allLogs.data[allLogsIndex].lastModifiedAt, logsByDate[logByDateIndex].lastModifiedAt)) {
          logsByDate[logByDateIndex].logCollection.push(allLogs.data[allLogsIndex]);
        }
      });
    });
  }

  @mutation
  public setGalleryThumbnail(image: any): void {
    if (image) {
      this.galleryThumbnail.push(image);
    } else {
      this.galleryThumbnail = [];
      this.selectedImages = [];
    }
  }
  @mutation
  public editGalleryThumbnail(image: any) {
    this.galleryThumbnail = [];
    this.selectedImages = [];
    if (image) {
      image.forEach((element) => {
        element.isSelected = true;
        this.galleryThumbnail.push(element);
        this.selectedImages.push(element);
      });
    } else {
      this.galleryThumbnail = [];
      this.selectedImages = [];
    }
  }
  @mutation
  public selectedImagesForAddLogs() {
    this.selectedImages = this.galleryThumbnail.filter(item => (item.isSelected || (item.isPrevSelected && !item.isRemoved)));
  }
  @mutation
  public removeUnsavedImages() {
    this.galleryThumbnail = this.galleryThumbnail.filter(item => !item.isSelected);
  }
  @mutation
  public updateSelectedGalleryThumbnail(image: ILogImageViewModel) {
    const newImage: ILogImageViewModel = Object.assign({}, image);
    const index: number = this.galleryThumbnail.indexOf(image);
    if (index < 0) {
      return;
    }
    if (image.isPrevSelected) {
      newImage.isRemoved = !image.isRemoved;
    } else {
      newImage.isSelected = !image.isSelected;
    }
    this.galleryThumbnail.splice(index, 1, newImage);
  }
  @mutation
  public updateImageSource(data: IImageCache) {
    this.imageSource = data.source;
    // To clear the image cache on close of image viewer.
    if (data.source === '') {
      this.imageCache = [];
    } else if (data.key !== '') {
      // Caching the image locally when user is viewing the photos.
      const index = this.imageCache.findIndex(item => item.key === data.key);
      const cacheItem: IImageCache = Object.assign({ key: data.key, source: data.source });
      if (index < 0) {
        this.imageCache.push(cacheItem);
      }
    }
  }
  @mutation
  public setThumbNailViewState() {
    this.galleryThumbnail.forEach((item) => {
      item.isSelected = item.isPrevSelected = item.isRemoved = false;
    });
    // To set the selection based on previous selected images.
    if (this.selectedImages.length > 0) {
      this.selectedImages.forEach((selectedImage: ILogImageViewModel) => {
        this.galleryThumbnail.forEach((galleryImage: ILogImageViewModel) => {
          if (selectedImage.mediaKey === galleryImage.mediaKey) {
            galleryImage.isPrevSelected = true;
          }
        });
      });
    }
  }
  @mutation
  public clearMedia(): void {
    this.selectedImages = [];
    this.galleryThumbnail = [];
  }
  @mutation
  public clearTaskLogs(): void {
    this.taskLogs = [];
  }
  @mutation
  public setCurrentDate(date: string): void {
    this.currentCompletedDate = date;
  }
  @mutation
  public setCurrentTime(time: string): void {
    this.currentTime = time;
  }
  @mutation
  public setEnv(env: IEnvironmentVariables): void {
    this.env = env;
  }
  @mutation
  public setSelectedScenarioKey(key: string): void {
    this.selectedScenarioKey = key;
  }
  @mutation
  public setScenarioHistory(data): void {
    this.assetScenarioHistory = data;
  }
  @mutation
  public setActiveDateRange(newTimeRange: IDateRange): void {
    this.activeDateRange = newTimeRange;
  }
  @mutation
  public setPauseLiveUpdates(newState: boolean): void {
    this.pauseLiveUpdates = newState;
  }
  @mutation
  public setBurners(data): void {
    this.burners = data;
  }

  @action
  public async loadBurners(assetKey: string): Promise<void> {
    const burners = await this.burnerReadingService.getBurnerReadings(assetKey);
    this.setBurners(burners);
  }

  @action
  public async addLog(request: ITaskLogViewModel): Promise<ITaskLogViewModel> {
    const taskLog: ITaskLogViewModel = await this.logsService.addTaskLog(request);
    return taskLog;
  }

  @action
  public async updateTaskLog(request: ITaskLogViewModel): Promise<ITaskLogViewModel> {
    const taskLog: ITaskLogViewModel = await this.logsService.updateTaskLog(request);
    return taskLog;
  }

  @action
  public async deleteTaskLog(request: ITaskLogViewModel): Promise<void> {
    await this.logsService.deleteTaskLog(request);
  }

  @action
  public uploadImage(entityKey: string): Promise<any> {
    return this.logsService.uploadImage(entityKey);
  }

  @action
  public downloadImage(request: IDownloadImageRequest): Promise<string> {
    return this.logsService.downloadImage(request);
  }
}
