import Vue from 'vue';
import VueI18n, { LocaleMessageObject } from 'vue-i18n';
import { Context } from '@nuxt/types';
import Axios, { AxiosInstance } from 'axios';
import dateTimeFormats from '@/i18n/date-formats';
import numberFormats from '@/i18n/number-formats';
import { LocalizationService } from '@/services';
import { updateHighchartsNumberFormat } from '@/i18n/highcharts-number-formats';

let i18n: VueI18n = null;

export default async function<Nuxt, Plugin>({ app, $vxm }: Context) {
  Vue.use(VueI18n);

  const fallbackLocale: string = 'en-US';

  i18n = new VueI18n({
    locale: $vxm.root.locale,
    fallbackLocale,
    dateTimeFormats,
    numberFormats,
    silentTranslationWarn: true
  });

  // Get the fallback language set
  const axios: AxiosInstance = Axios.create();
  axios.defaults.baseURL = $vxm.root.env.localizationApiUrl;
  const localizationService = new LocalizationService(axios);

  const fallbackLanguageSet: LocaleMessageObject = await localizationService.getLanguageSetForLocale(fallbackLocale);
  i18n.setLocaleMessage(fallbackLocale, fallbackLanguageSet);

  if (fallbackLocale !== $vxm.root.locale) {
    const languageSet: LocaleMessageObject = await localizationService.getLanguageSetForLocale($vxm.root.locale);
    i18n.setLocaleMessage($vxm.root.locale, languageSet);
  }

  app.i18n = i18n;
  (<any>app.i18n).path = (link: string): string => {
    if (i18n == null || i18n.locale === i18n.fallbackLocale) {
      return `/${link}`;
    }

    return `/${i18n.locale}/${link}`;
  };

  initializeI18N(app.i18n as VueI18n);

  updateHighchartsNumberFormat($vxm.root.locale);
}

export function initializeI18N(newI18n: VueI18n) {
  i18n = newI18n;
}

export function getI18N() {
  return i18n;
}
