export enum DashboardType {
  UserDashboard = 'UserDashboard',
  SystemDashboard = 'SystemDashboard'
}

export enum GridColumnSize {
  Four = 4,
  Six = 6,
  Eight = 8
}
