export enum EntityType {
  Asset = 'Asset',
  AssetGrouping = 'AssetGrouping',
  BurnerGrouping = 'BurnerGrouping',
  Customer = 'Customer',
  CustomerSite = 'CustomerSite',
  Dashboard = 'Dashboard',
  Heater = 'Heater',
  KESCompany = 'KESCompany',
  Organization = 'Organization',
  Product = 'Product',
  ProductSubscription = 'ProductSubscription',
  Report = 'Report',
  ReportInput = 'ReportInput',
  Tower = 'Tower',
  User = 'User',
  UserAssignment = 'UserAssignment',
  UserFavorite = 'UserFavorite',
  UserRole = 'UserRole',
  Unknown = 'Unknown'
}
