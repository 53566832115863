import moment from 'moment';

export enum SortDirection {
  Ascending,
  Descending
}

function nullComparer<T>(a: T, b: T) {
  if (b == null && a == null) {
    return 0;
  }
  if (a == null && b != null) {
    return -1;
  }
  if (a != null && b == null) {
    return 1;
  }

  return null; // neither are null, so cannot compare
}

function keepDirection(dir: SortDirection, ascendingValue: number) {
  return dir === SortDirection.Ascending && ascendingValue !== 0 ? ascendingValue : (ascendingValue * -1);
}

function alphabeticSorter(): (a: string, b: string) => number;
function alphabeticSorter<T>(selector: (o: T) => string): (a: T, b: T) => number;
function alphabeticSorter<T>(selector?: (o: T) => string) {
  return alphabeticSorterImpl(SortDirection.Ascending, selector);
}

function alphabeticSorterDesc(): (a: string, b: string) => number;
function alphabeticSorterDesc<T>(selector: (o: T) => string): (a: T, b: T) => number;
function alphabeticSorterDesc<T>(selector?: (o: T) => string) {
  return alphabeticSorterImpl(SortDirection.Descending, selector);
}

function alphabeticSorterImpl<T>(dir: SortDirection, selector?: (o: T) => string, locale: string = 'en-US') {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = keepDirection(dir, nullComparer(sA, sB));
      const ascendingValue = res != null ? res : sA.localeCompare(sB, locale, { sensitivity: 'base', numeric: true });
      return keepDirection(dir, ascendingValue);
    };
  }

  return (a: string, b: string) => {
    const res = nullComparer(a, b);
    const ascendingValue = res != null ? res : a.localeCompare(b, locale, { sensitivity: 'base', numeric: true });
    return keepDirection(dir, ascendingValue);
  };
}

function numericSorter(): (a: number, b: number) => number;
function numericSorter<T>(selector: (o: T) => number): (a: T, b: T) => number;
function numericSorter<T>(selector?: (o: T) => number) {
  return numericSorterImpl(SortDirection.Ascending, selector);
}

function numericSorterDesc(): (a: number, b: number) => number;
function numericSorterDesc<T>(selector: (o: T) => number): (a: T, b: T) => number;
function numericSorterDesc<T>(selector?: (o: T) => number) {
  return numericSorterImpl(SortDirection.Descending, selector);
}

function numericSorterImpl<T>(dir: SortDirection, selector?: (o: T) => number) {
  if (selector != null) {
    return (a: T, b: T) => {
      const sA = selector(a);
      const sB = selector(b);
      const res = keepDirection(dir, nullComparer(sA, sB));
      const ascendingValue = res != null ? res : sA - sB;
      return keepDirection(dir, ascendingValue);
    };
  }
  return (a: number, b: number) => {
    const res = nullComparer(a, b);
    const ascendingValue = res != null ? res : a - b;
    return keepDirection(dir, ascendingValue);
  };
}

function dateSorter(): (a: Date | string, b: Date | string) => number;
function dateSorter<T>(selector: (o: T) => Date | string): (a: T, b: T) => number;
function dateSorter<T>(selector?: (o: T) => Date | string) {
  return dateSorterImpl(SortDirection.Ascending, selector);
}

function dateSorterDesc(): (a: Date | string, b: Date | string) => number;
function dateSorterDesc<T>(selector: (o: T) => Date | string): (a: T, b: T) => number;
function dateSorterDesc<T>(selector?: (o: T) => Date | string) {
  return dateSorterImpl(SortDirection.Descending, selector);
}

function dateSorterImpl<T>(dir: SortDirection, selector?: (o: T) => Date | string) {
  if (selector != null) {
    return setSortDirection<T>(selector, dir);
  }

  return (a: Date | string, b: Date | string) => {
    const res = nullComparer(a, b);
    if (res != null) {
      return res;
    }
    const anchor = moment(a);
    const ascendingValue = anchor.isSame(b) ? 0 : anchor.isAfter(b) ? -1 : 1;
    return keepDirection(dir, ascendingValue);
  };
}

export {
  alphabeticSorter,
  alphabeticSorterDesc,
  numericSorter,
  numericSorterDesc,
  dateSorter,
  dateSorterDesc
};

function setSortDirection<T>(selector: (o: T) => Date | string, dir: SortDirection) {
  return (a: T, b: T) => {
    const sA = selector(a);
    const sB = selector(b);
    const res = nullComparer(sA, sB);
    if (res != null) {
      return keepDirection(dir, res);
    }

    const anchor = moment(sA);
    const ascendingValue = anchor.isSame(sB) ? 0 : anchor.isAfter(sB) ? -1 : 1;
    return keepDirection(dir, ascendingValue);
  };
}

export function distinctArray<T>(collection: Array<T>): Array<T> {
  return collection == null ? collection : Array.from(new Set(collection));
}

export function cloneArray<T>(array: Array<T>): Array<T> {
  return array != null ? array.slice(0) : null;
}

export function rangeArray(start: number, end: number, step: number = 1) {
  const range = [];
  for (let i = start; i <= end; i += step) {
    range.push(i);
  }
  return range;
}

export function addToLookup<T>(lookup: Record<string, T[]>, key: string, value: T) {
  if (lookup?.[key] == null) {
    lookup[key] = [];
  }

  lookup[key].push(value);
}

export function sortedArrayFindIndex(sortedArray: Array<any>): { find: (value: string | number | Date, comparator?: (sortedArrayItem: any) => string | number | Date) => number, findClosest: (value: string | number | Date, direction?: null | 'above' | 'below', comparator?: (sortedArrayItem: any) => string | number | Date) => number } {
  let _comparator = i => i;
  let lowIndex: number = 0;
  let highIndex: number = sortedArray.length - 1;

  function compareFunction(arrayItem: any): string | number | Date {
    if (arrayItem == null) {
      return null;
    }

    return _comparator(arrayItem);
  }

  function binarySearch(value: string | number | Date): number {
    while (lowIndex <= highIndex) {
      let midIndex = lowIndex + Math.floor((highIndex - lowIndex) / 2);

      while (compareFunction(sortedArray[midIndex]) == null && midIndex > lowIndex && midIndex < highIndex && midIndex <= sortedArray.length - 1) {
        midIndex++;
      }

      if (value instanceof Date && (compareFunction(sortedArray[midIndex]) as Date)?.getTime() === value.getTime()) {
        return midIndex;
      } else if (compareFunction(sortedArray[midIndex]) === value) {
        return midIndex;
      } else if (compareFunction(sortedArray[midIndex]) < value) {
        lowIndex = midIndex + 1;
      } else {
        highIndex = midIndex - 1;
      }
    }

    return -1;
  }

  function upperIndex(value: number | string | Date, currentIndex: number): number {
    while (currentIndex <= sortedArray.length - 1) {
      const compareValue: number | string | Date = compareFunction(sortedArray[currentIndex]);

      if (compareValue != null && compareValue > value) {
        return currentIndex;
      }

      currentIndex++;
    }

    return -1;
  }

  function lowerIndex(value: number | string | Date, currentIndex: number): number {
    while (currentIndex >= 0) {
      const compareValue: number | string | Date = compareFunction(sortedArray[currentIndex]);

      if (compareValue != null && compareValue < value) {
        return currentIndex;
      }

      currentIndex--;
    }

    return -1;
  }

  function closestIndex(value: number | string | Date, currentIndex: number): number {
    let indexArray: number[] = [lowerIndex(value, currentIndex), upperIndex(value, currentIndex)];
    let remaindersArray: number[] = null;

    if (value instanceof Date) {
      remaindersArray = indexArray.map(i => Math.abs((compareFunction(sortedArray[i]) as Date).getTime() - value.getTime()));
    } else if (typeof value === 'string') {
      remaindersArray = indexArray.map((i) => {
        const compareItemSliced: string = (compareFunction(sortedArray[i]) as string).slice(0, value.length);
        const arrayValue: number = Number(compareItemSliced.split('').map(c => c.charCodeAt(0)).join(''));
        const compareValue: number = Number(value.split('').map(c => c.charCodeAt(0)).join(''));

        return Math.abs(arrayValue - compareValue);
      });
    } else {
      remaindersArray = indexArray.map(i => Math.abs((compareFunction(sortedArray[i]) as number) - value));
    }

    // Filter out NaN values
    indexArray = indexArray.filter((_i, index) => !isNaN(remaindersArray[index]));
    remaindersArray = remaindersArray.filter(i => !isNaN(i));

    const lowestRemainder: number = Math.min(...remaindersArray);

    return indexArray[remaindersArray.indexOf(lowestRemainder)];
  }

  return {
    find: (value: string | number | Date, comparator?: (sortedArrayItem: any) => string | number | Date) => {
      if (comparator != null) {
        if (typeof comparator !== 'function') {
          throw new TypeError('TypeError: Comparator must be of type function.');
        }
        _comparator = comparator;
      }

      return binarySearch(value);
    },
    findClosest: (value: string | number | Date, direction?: null | 'above' | 'below', comparator?: (sortedArrayItem: any) => string | number | Date) => {
      if (comparator != null) {
        if (typeof comparator !== 'function') {
          throw new TypeError('TypeError: Comparator must be of type function.');
        }
        _comparator = comparator;
      }

      const binarySearchIndex: number = binarySearch(value);
      const index: number = highIndex;

      if (binarySearchIndex >= 0) {
        return binarySearchIndex;
      }

      switch (direction) {
        case 'above':
          return upperIndex(value, index);
        case 'below':
          return lowerIndex(value, index);
        default:
          return closestIndex(value, index);
      }
    }
  };
}

export function findItemsWithValue<T, TKey extends keyof T>(arr: T[], key: TKey, ...values: T[TKey][]): (T | null)[] {
  const foundItems: (T | null)[] = Array(values.length).fill(null);
  let foundCount: number = 0;

  for (const item of arr) {
    for (let i = 0; i < values.length; i++) {
      if (foundItems[i] == null && item[key] === values[i]) {
        foundItems[i] = item;
        foundCount++;
      }
    }
    if (values.length === foundCount) {
      break;
    }
  }

  return foundItems;
}
