













// Libraries
import { Component } from 'nuxt-property-decorator';
// Components
import BaseComponent from '@/shared/BaseComponent';
import ErrorModal from '@/components/shared/ErrorModal.vue';
import HeaderNavigation from '@/components/layout/HeaderNavigation.vue';
import MainNavigationPhase2 from '@/components/layout/MainNavigationPhase2.vue';
import DeleteTileModal from '@/components/layout/DeleteTileModal.vue';
import FooterSection from '@/components/section/FooterSection.vue';
// Stores

@Component({
  components: {
    DeleteTileModal,
    ErrorModal,
    FooterSection,
    HeaderNavigation,
    MainNavigationPhase2
  },
  name: 'default-layout'
})
export default class DefaultLayout extends BaseComponent {
  // Vuex Properties
  // Public Properties
  // Private Fields
  public get isAuthenticated() {
    return this.$vxm.user.authUser != null;
  }
  // Lifecycle Hooks
  // private mounted(): void {}
  // beforeDestroy(): void {}
}
