export enum WcagColors {
  Red = 'var(--threshold-danger)',
  Yellow = 'var(--threshold-yellow)',
  Green = 'var(--threshold-green)',
  White = 'var(--threshold-white)',
  LightBlue = 'var(--threshold-blue)',
  Highlight = 'var(--update-highlight)',
  '#FFFFFF' = 'White',
  '#5EDF81' = 'Green',
  '#FDF17A' = 'Yellow',
  '#F23838' = 'Red',
  '#8CA6C3' = 'LightBlue',
  'var(--threshold-danger)'= 'Red',
  'var(--threshold-yellow)' = 'Yellow',
  'var(--threshold-green)' = 'Green',
  'var(--threshold-white)' = 'White',
  'var(--threshold-blue)' = 'LightBlue',
  'var(--update-highlight)' = 'Highlight'
}
