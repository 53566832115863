



















// Libraries
import { Component } from 'nuxt-property-decorator';
import { MetaInfo } from 'vue-meta';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import HeaderNavBar from '@/components/shared/HeaderNavBar.vue';
// Stores

@Component({
  name: 'header-navigation',
  components: {
    HeaderNavBar
  },
  head(this: HeaderNavigation) {
    return {
      changed: (metaInfo: MetaInfo) => {
        this.pageTitle = metaInfo.titleChunk;
      }
    };
  }
})
export default class HeaderNavigation extends BaseComponent {
  // Vue.js Props
  // VUEX
  // Properties
  public pageTitle: string = '';
  // Fields
  // Getters
  public get isFirstTimeUser() {
    return this.$vxm.user.firstTimeUser;
  }
  public get headerTitle(): string {
    const [head] = this.pageTitle.split(' / ');
    return (head === this.$t('smartSolutions') || head === '') && this.$route.name !== '' ? '' : head;
  }
  public get currentAsset(): string {
    return this.$vxm.assets?.assetName ?? '';
  }
  public get isSpecificAssetPage(): boolean {
    return /^\/assets\/(.+)\//i.test(this.$route.fullPath) && this.$vxm.assets?.assetName != null;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
