














// Libraries
import { Component } from 'nuxt-property-decorator';
// View Models
// Components
import { IErrorViewModel } from '@/view-models';
import BaseComponent from '@/shared/BaseComponent';
import EventBus from '@/shared/event-bus';
// Services
@Component({
  name: 'error-modal'
})
export default class ErrorModal extends BaseComponent {
  // VUE.JS Props
  // VUEX
  // Properties
  public showModal: boolean = false;
  public messages: Array<IErrorViewModel> = [];
  public redirect: string = null;
  // Fields
  // Getters
  public get hasUnauthorizedResponse(): boolean {
    return this.messages.some(m => m.isUnauthorizedResponse);
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  public created(): void {
    EventBus.$on('error-modal-event', (message: IErrorViewModel) => {
      if (!this.messages.some((e: IErrorViewModel) => e.hash === message.hash)) {
        if (message.redirect != null) {
          this.redirect = message.redirect;
        }
        this.messages.unshift(message);
        this.showModal = true;
      }
    });
  }
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public close(event: Event): void {
    event.preventDefault();
    this.showModal = false;
    this.messages = [];
    const redirect: string = this.redirect;
    this.redirect = null;
    if (redirect != null && this.$route.path !== redirect) {
      window.location.assign(redirect);
    }
  }
  // Watchers
  // Emitters
}
