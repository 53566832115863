import 'es6-promise/auto';
import { AxiosInstance, AxiosResponse } from 'axios';
import BaseService from '@/services/base-service';
import { ICompanyService } from '@/view-models';
import { TableFetchOptions } from '@/view-models/shared/table-display';
import { Company, CompanyDataRow } from '@/view-models/admin/company';
import { PagedResult } from '@/components/types/result';

export default class CompanyService extends BaseService implements ICompanyService {
	constructor(axios: AxiosInstance) {
		super('company', axios);
	}
	public async getCompanies(options: TableFetchOptions): Promise<PagedResult<Company>> {
		return (await this.http.get<PagedResult<Company>>(this.getUrl(''), { params: options })).data;
	}
	// public async createCompany(name: string): Promise<string> {
	// 	return (await this.http.post<string>(this.getUrl(''), { name })).data;
  // }
  public async createCompany(name: string): Promise<CompanyDataRow> {
		const company: CompanyDataRow = await this.http
			.post(this.getUrl(``), {
				name
			})
			.then((response: AxiosResponse<CompanyDataRow>) => response.data);
		return company;
	}
}
