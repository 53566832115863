






















// Libraries
import { Component } from 'nuxt-property-decorator';
import { alphabeticSorter } from '@/shared/array-utils';
// View Models
import { ISelectItem } from '@/components/types/select-box';
import { IAssignedCustomer } from '@/view-models/assets';
// Components
import BaseComponent from '@/shared/BaseComponent';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import { ChildAppRoutesEnum } from '@/plugins/web-components';

@Component({
  components: {
    SelectDropdown
  },
  name: 'customer-switch'
})
export default class CustomerSwitch extends BaseComponent {
  // VUE.JS Props
  // VUEX
  public get availableCustomers(): IAssignedCustomer[] {
    return this.$vxm.user.availableCustomers ?? [];
  }
  public get fetchingCustomers(): boolean {
    return this.$vxm.user.fetchingCustomers;
  }

  public get options(): ISelectItem<string>[] {
    return this.availableCustomers
      .slice()
      .sort(alphabeticSorter(customer => customer.name))
      .map(customer => ({
        text: customer.name,
        value: customer.key
      }));
  }

  public get isFirstTimeUser() {
    return this.$vxm.user.firstTimeUser;
  }

  public get customerName() {
    return this.availableCustomers?.[0]?.name;
  }

  public get currentCustomerKey(): string {
    return this.$vxm.user.authUser.activeCustomerKey;
  }

  public get showDropdown(): boolean {
    return this.availableCustomers.length > 1 && !this.fetchingCustomers;
  }

  public get onlyOne(): boolean {
    return this.availableCustomers.length === 1 && !this.fetchingCustomers;
  }
  public get noCustomers(): boolean {
    return this.availableCustomers.length < 1 && !this.fetchingCustomers;
  }

  public get onlyOneCustomerName(): string {
    return this.availableCustomers?.[0]?.name;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  public created(): void {
    if (this.availableCustomers.length < 1) {
      this.$vxm.user.fetchAvailableCustomers();
    }
  }
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public async onCustomerChange(key: string) {
    if (key !== this.currentCustomerKey) {
      this.$vxm.assets.setAssetName(''); // clear the previous company selected asset name
      await this.$vxm.user.updateActiveCustomer(key);
      if (this.$route.path.includes(ChildAppRoutesEnum.AssetsBasePath)) { // if customer changed when in assets tab
        this.$router.replace('/assets'); // route back to base path for assets tab
      }
    }
  }
}
