/* eslint no-console: 0 */
import { Context } from '@nuxt/types';
import VueI18n from 'vue-i18n';

export default function<Nuxt, Plugin>({ isDev, app }: Context): void {
  if (!isDev) {
    console.clear();
  }
  const i18n = app.i18n as VueI18n;
  console.log(`%c${i18n.t('global.devtools.warning')}`, 'background-color: red;color: yellow;font-size:x-large;');
  console.log(i18n.t('global.devtools.scam'));
  console.log(`%c${i18n.t('global.devtools.pasting')}`, 'color: red;font-size:large;font-weight:bold;');
  console.log(i18n.t('global.devtools.staySafe'));
}
