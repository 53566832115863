export enum RecentType {
  Unknown = 'Unknown',
  Heater = 'Heater',
  FiredHeater = 'FiredHeater',
  Flare = 'Flare',
  Tower = 'Tower',
  Report = 'Report',
  AssetEditor = 'AssetEditor',
  HydraulicModeler = 'HydraulicModeler',
  HierarchyBuilder = 'HierarchyBuilder',
  LayoutEditor = 'LayoutEditor',
  VariableEditor = 'VariableEditor',
  Account = 'Account',
  Company = 'Company',
  Security = 'Security',
  PlantConnector = 'PlantConnector',
  UserManagement = 'UserManagement'
}
