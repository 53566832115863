







// Libraries
import { Component } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import ErrorModal from '@/components/shared/ErrorModal.vue';
import TermsModal from '@/components/shared/TermsModal.vue';
// Services
@Component({
	components: {
    ErrorModal,
    TermsModal
	},
	name: 'anonymous-layout'
})
export default class AnonymousLayout extends BaseComponent {
	// VUE.JS Props
	// VUEX
	// Properties
	// Fields
	// Getters
	// Lifecycle Handlers
	// beforeCreate(): void {}
	// created(): void {}
	// beforeMount(): void {}
	// mounted(): void {}
	// beforeUpdate(): void {}
	// updated(): void {}
	// beforeDestroy(): void {}
	// destroyed(): void {}
	// Private Methods
	// Helper Methods
	// Event Methods
	// Watchers
	// Emitters
}
