export enum SortSections {
  ReportPage = 'ReportPage',
  HomePage = 'HomePage',
  DashboardPage = 'DashboardPage'
}

export const enum TileSortOptions {
  LastModifiedAt = 'LastModifiedAt',
  Type = 'Type',
  WidgetType = 'WidgetType',
  Name = 'Name'
}

export enum TileItemModes {
  Tile = 'Tile',
  List = 'List'
}
