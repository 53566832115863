import { BurnerDiagramValuesEnum } from '@/enums/burners';

export interface IDiagramViewModel {
  key: string;
  burnerCells: Array<IBurnerCellViewModel>;
  labelCells: Array<ILabelCellViewModel>;
  breakLines: Array<IBreakLineViewModel>;
  xOffset: number;
  yOffset: number
}

export interface IDiagramCell {
  key: string;
  name: string;
  x: number;
  y: number;
}

export interface IBurnerCellViewModel extends IDiagramCell {
  data: {[key in BurnerDiagramValuesEnum]: number};
  outOfTolerance?: boolean;
  burnerOff?: boolean;
}

export interface ILabelCellViewModel extends IDiagramCell {
  xSpan: number;
  ySpan: number;
}

export interface IBreakLineViewModel extends IDiagramCell {
  direction: DirectionEnum
  span: number;
}

export enum DirectionEnum {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal'
}
