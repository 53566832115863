import Vue from 'vue';
import { authClient, Auth0ErrorsEnum } from '@/../CommonLibrary/modules/auth/auth0';
import { IUserViewModel } from '@/view-models';
import { NuxtError } from '@nuxt/types';
import { AxiosRequestConfig } from 'axios';

export * from '../../CommonLibrary/modules/auth/auth0';

export function authErrorHandler(logout: boolean, error: any, nuxtErrorHandler: (error: NuxtError) => void) {
  const isObject = typeof error === 'object' && error.constructor === Object;
  const status: number | null = isObject ? error?.response?.status : null;
  // eslint-disable-next-line no-console
  console.error('Authentication check error. Status: ', status, error);

  if (status === 401 && logout) {
    authClient.logOut({ saveReturnPath: true });
  } else if (status != null) {
    nuxtErrorHandler({ statusCode: status, message: 'Error checking Authentication' });
  }
}

export async function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = await authClient.getAuthToken();

  if (token != null) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
}

export const authResponseFulfilledInterceptor = (response: any) => response;
export async function authResponseRejectedInterceptor(error: any) {
  const isObject = typeof error === 'object' && error.constructor === Object;
  const status: number | null = isObject ? error?.response?.status : null;
  const isAuth0Error = isObject &&
    [Auth0ErrorsEnum.LoginRequired, Auth0ErrorsEnum.ConsentRequired].includes(error?.error);

  if (status === 401) {
    authClient.logOut({ saveReturnPath: true });
  } else if (isAuth0Error) {
    await authClient.loginWithRedirect();
    return error;
  } else {
    return Promise.reject(error);
  }
}

export const authData: { user: IUserViewModel } = Vue.observable({ user: null });
