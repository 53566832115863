import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models/measurement-systems-view-model';

export default class CustomerPreferenceServiceV2 extends BaseService {
  constructor(axios: AxiosInstance) {
    super('dv/customerPreference', axios, '');
  }

  /**
   * Get available measurement systems
   * @param customerKey string
   * @returns {Promise<IAvailableMeasurementSystemsViewModel[]>}
   */
   public getAvailableMeasurementSystems(customerKey: string): Promise<IAvailableMeasurementSystemsViewModel[]> {
    return this.httpClient.get<IAvailableMeasurementSystemsViewModel[]>(`availableMeasurementSystems/${customerKey}`);
  }
}
