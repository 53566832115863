import { Vue, Component, Emit } from 'nuxt-property-decorator';
import { ErrorBag, FieldBag } from 'vee-validate';
import moment from 'moment';
import HelperMethods from '@/shared/helper-methods';
import { elementId } from '@/shared/components-utils';
// eslint-disable-next-line import/named
import { BvToastOptions } from 'bootstrap-vue/src/components/toast';
import EventBus from './event-bus';
import { newGuid } from './string-utils';

@Component
export default class BaseComponent extends Vue {
  public errors!: ErrorBag;
  public loading: number = 0;
  public fields!: FieldBag;
  public cid: string = newGuid();

  // VUEX;
  public get isLeftNavLocked(): boolean {
    return this.$vxm.root.isLeftNavLocked;
  }

  public get isLeftNavHovered(): boolean {
    return this.$vxm.root.isLeftNavHovered;
  }

  // Getters
  public get formReady(): boolean {
    return Object.keys(this.$validator.fields).every((key: string) => !this.$validator.fields[key].pristine) && !this.errors.any();
  }

  public get isLoading(): boolean {
    return this.loading > 0;
  }

  // Protected Methods
  protected async runAnimation(
    className: string,
    target: HTMLElement,
    keepClassAfterAnimate: boolean = false
  ): Promise<void> {
    await HelperMethods.runAnimation(className, target, keepClassAfterAnimate);
  }
  protected catch(error: any, sendLog: boolean = true): void {
    HelperMethods.catch(this.$axios, error, { sendLog });
  }
  protected isDateFormat(dateString: string): boolean {
    const tester = RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
    return tester.test(dateString);
  }
  protected roundDecimal(number: number, places: number, abbr: boolean = false): string {
    return HelperMethods.roundDecimal(number, places, abbr);
  }
  protected roundDecimalTranslated(number: number, places: number, translationType: string, abbr: boolean = false): string {
    return HelperMethods.roundDecimalTranslated(number, places, this.$i18n, translationType, abbr);
  }
  protected delayAction(timer: NodeJS.Timeout, delay: number, callback: Function): NodeJS.Timeout {
    if (timer != null) {
      clearTimeout(timer);
    }
    return (setTimeout(() => {
      callback();
    }, delay) as unknown) as NodeJS.Timeout;
  }
  protected emitClonedValue(value: any, callback: Function) {
    const clonedObject = Object.assign({}, value);
    if (callback) {
      callback(clonedObject);
    }
    // This emit will change this.value.
    this.$emit('input', clonedObject);
  }
  protected toggleIsLeftNavLocked(value: boolean): void {
    if (!value) {
      this.toggleIsLeftNavHovered(value);
    }
    this.$vxm.root.setNavLocked(value);
    EventBus.$emit('NAV_LOCKED');
  }
  protected toastMessage(message: string, title?: string, variant?: string, options?: BvToastOptions): void {
    this.$bvToast.toast(message, {
      title,
      variant: variant ?? 'info',
      toaster: 'b-toaster-top-right',
      ...options
    });
  }
  protected toggleIsLeftNavHovered(value: boolean): void {
    this.$vxm.root.setNavHovered(value);
    EventBus.$emit('NAV_HOVERED');
  }

  // Public Methods
  public isValidDate(dateString: string): boolean {
    return (this.isDateFormat(dateString) && moment(dateString).isValid()) || dateString === '';
  }
  public validateState(fieldName: string): boolean {
    if (
      this.fields != null &&
      this.fields[fieldName] != null &&
      (this.fields[fieldName].dirty || this.fields[fieldName].validated)
    ) {
      return !this.errors.has(fieldName);
    }
    return null;
  }

  public getQueryVariable(variableName: string): string {
    return HelperMethods.getQueryVariable(variableName);
  }

  public debounce(name: string, delay: number, action: Function): void {
    HelperMethods.debounce(name, delay, action);
  }
  public clearDebounce(name: string): void {
    HelperMethods.clearDebounce(name);
  }

  protected findIndexBinarySearch(sortedArray: Array<any>, valueToFind: string | number | Date, comparePropertyFunction: (sortedArrayItem: any) => string | number | Date = i => i): number {
    return HelperMethods.findIndexBinarySearch(sortedArray, valueToFind, comparePropertyFunction);
  }

  protected getDateString(date: string, format: string = 'MM/DD/YY HH:mm'): string {
    const oDate: moment.Moment = moment(date);
    return oDate.format(format);
  }

  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;

  public tOr(tag: string, otherwise: string = '', values: any[] | { [key: string]: any } = null) {
    return tag && this.$te(tag) ? this.$t(tag, values) : otherwise;
  }

  @Emit('update-loading')
  public updateLoading(newTask: boolean): void {
    if (newTask) {
      this.loading++;
    } else if (this.loading > 0) {
      this.loading--;
    }
  }
}
