import { createModule, mutation, action } from 'vuex-class-component';
import { ICompanyRequest } from '@/store/types/company';

import { getAxios } from '@/shared/http';
import { TableFetchOptions } from '@/view-models/shared/table-display';
import CompanyService from '@/services/company-service';
import { CompanyDataRow, Company } from '@/view-models/admin/company';
import { DataTable, IDataTable } from '@/view-models/data-table';
import { PagedResult } from '@/components/types/result';
import { ICompanyService } from '@/view-models';
import { ICompanyStore } from '../store/index';

const VuexModule = createModule({
	namespaced: 'company',
	strict: false,
	target: 'nuxt'
  });

export class CompanyStore extends VuexModule implements ICompanyStore {
	private get companyService(): ICompanyService {
		return new CompanyService(getAxios());
	  }
	public companies: IDataTable<CompanyDataRow> = new DataTable<CompanyDataRow>();
	public currentCompany: Company;

	// getters
	get getCompanies() {
		return this.companies;
	}
  	get getCompany() {
		return (key: string) => this.companies.rows.find(c => c.key === key);
	}

	@mutation
	public setCompanies(data: PagedResult<CompanyDataRow>): void {
		const table = new DataTable<CompanyDataRow>();
		table.headerTranslationStrings.push('name');
		table.pageProperties = data;
			// table.rows = result.data.slice();
			data.data.forEach((r: CompanyDataRow) => {
				table.rows.push(r as CompanyDataRow);
			});
		this.companies = table;
	}

	@mutation
	public addCompany(item: CompanyDataRow): void {
		this.companies.rows.push(item as CompanyDataRow);
	}

	@action
	public async loadCompanies(options: TableFetchOptions) {
		const companies = await this.companyService.getCompanies(options);
		this.setCompanies(companies);
	  }

	@action
	public async createCompany(request: ICompanyRequest): Promise<CompanyDataRow> {
		const company: CompanyDataRow = await this.companyService.createCompany(request.name);
		this.addCompany({ key: company.key,
						name: request.name
					});
		return company;
	}
}
