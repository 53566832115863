











import { Component, Emit } from 'nuxt-property-decorator';
import { LocaleMessageObject } from 'vue-i18n';
import Axios, { AxiosInstance } from 'axios';
import LocalizationService from '@/services/localization-service';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import { ISelectItem } from '@/components/types/select-box';
import BaseComponent from '@/shared/BaseComponent';

@Component({
	components: { SelectDropdown }
})
export default class LanguageSelection extends BaseComponent {
  // VUE.JS Props
  // VUEX
  public get locale(): string {
    return this.$vxm.root.locale;
  }

  public set locale(locale: string) {
    this.$vxm.root.setLocale(locale);
  }

  public get locales(): string[] {
    return this.$vxm.root.locales;
  }
  // Properties
  // Fields
  private localizationService: LocalizationService;
  // Getters
  private get options() {
  	return this.locales.map(this.createOption);
  }
  private get disabledDropdown() {
    return this.options == null || this.options.length < 2;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  private mounted(): void {
    const axios: AxiosInstance = Axios.create();
    if (axios) {
      axios.defaults.baseURL = this.$vxm.root.env.localizationApiUrl;
      this.localizationService = new LocalizationService(axios);
    }
  }

  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private createOption(key) {
  	return {
  		translationTag: 'global.locales.' + key,
  		value: key
  	} as ISelectItem<any>;
  }

  // Helper Methods
  // Event Methods
  public async changeLanguage(key: string): Promise<void> {
     	const locale: string = key;
  	try {
      const languageSet: LocaleMessageObject = await this.localizationService.getLanguageSetForLocale(locale);
    		this.$root.$i18n.setLocaleMessage(locale, languageSet);
  		this.locale = locale;
  		this.$root.$i18n.locale = locale;
  	} catch (error) {
  		this.catch(error);
  	}
  }

	// Watchers
	// Emitters
  @Emit('shown')
  public shown(unused: any): void {}

  @Emit('hidden')
  public hidden(unused: any): void {}
}
