export enum CheckedState {
  Checked = 'Checked',
  None = 'None',
  Partial = 'Partial',
  Unchecked = 'Unchecked'
}

export enum ListItemActionEnum {
  Run = 'run',
  Delete = 'delete',
  Copy = 'copy',
  Favorite = 'favorite',
}

export enum ToastStatus {
  Information = 'information',
  WarningHigh = 'warning-high',
  WarningMedium = 'warning-medium',
  WarningLow = 'warning-low',
  Success = 'success'
}
