import { EntityType } from '@/enums';
import { IEntityViewModel } from './global-view-model';
import { Permission } from './admin/role';
import { ILocationViewModel } from './assets/assets-view-models';

export interface IUserViewModel extends IEntityViewModel {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  activeCustomerKey: string;
  isSuperUser?: boolean;
  roleKeys?: string[];
  permissions?: Permission[];
  isCustomerUser?: boolean;
  isSsoUser?: boolean;
}

export interface IUser extends IUserViewModel {
  readonly isKESUser: boolean; // getter
  readonly userAssignments?: IUserAssignmentViewModel[];
}

export class User implements IUserViewModel {
  public email: string;
  public username: string;
  public firstName: string;
  public lastName: string;
  public activeCustomerKey: string;
  public isSuperUser?: boolean;
  public roleKeys?: string[];
  public permissions?: Permission[];
  public createdAt?: string;
  public createdBy?: string;
  public currentError?: string;
  public lastModifiedAt?: string;
  public lastModifiedBy?: string;
  public orgKey?: string;
  public searchName?: string;
  public key: string;
  public name: string;

  public get isKESUser(): boolean {
    return this.orgKey !== this.activeCustomerKey;
  }

  constructor(obj?: IUserViewModel) {
    if (obj != null) {
      Object.assign(this, obj);
    }
  }
}

export interface IUserAssignmentViewModel {
  key?: string;
  userKey?: string;
  createdAt?: null;
  assignedEntityKey: string;
  assignedEntityType: EntityType;
  assignedEntityOrgKey: string;
}

export interface IUserWeatherFavorite {
  location: ILocationViewModel;
  name: string;
  active: boolean;
}
