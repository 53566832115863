import Highcharts, { LangOptions } from 'highcharts';

const locales: { [locale: string]: LangOptions } = {
  'en-US': {
    decimalPoint: '.',
    thousandsSep: ','
  },
  'fr-FR': {
    decimalPoint: ',',
    thousandsSep: ' '
  }
};

const defaultLocale = 'en-US';

export function getHighchartsNumberFormat(locale: string) {
  return locales?.[locale] ?? locales[defaultLocale];
}

export function updateHighchartsNumberFormat(locale: string) {
  Highcharts.setOptions({
    lang: getHighchartsNumberFormat(locale)
  });
}
