import moment from 'moment';
import {
	EftDaysOfWeek,
	EftMonths, InputDateRangeEnum,
	ReportRangeType,
	TotalBy
} from '@/enums';
import { ITimeSpan } from '@/view-models/time-span-model';

export interface IReportTimeInclusion {
	timeSpan: ITimeSpan[];
	daysOfWeek: EftDaysOfWeek[];
	months: EftMonths[];
}
export class ReportTimeInclusion implements IReportTimeInclusion {
	public timeSpan: ITimeSpan[] = [];
	public daysOfWeek: EftDaysOfWeek[] = [];
	public months: EftMonths[] = [];

	constructor(reportTimeInclusion?: IReportTimeInclusion) {
		if (reportTimeInclusion != null) {
			Object.assign(this, reportTimeInclusion);
		}
	}
}
export interface IDateRangeObjects {
	fromDate: Date;
	toDate: Date;
	dateType?: InputDateRangeEnum;
}

export interface IDateRange {
	fromDate: string;
	toDate?: string;
}
export class DateRange implements IDateRange {
	public fromDate: string = null;
	public toDate: string = null;

	constructor(dateRange?: IDateRange) {
		if (dateRange != null) {
			Object.assign(this, dateRange);
		}
	}

	public static prepareRangeForServer(range: IDateRange): IDateRange {
		const from: Date = new Date(range.fromDate);
		const to: Date = new Date(range.toDate);
		return new DateRange({
      fromDate: asServerDateTime(from),
      toDate: asServerDateTime(to)
		});
	}
}

export function dateObjectsToDateRange(range: IDateRangeObjects): IDateRange {
  return new DateRange({
    fromDate: asServerDateTime(range.fromDate),
    toDate: asServerDateTime(range.toDate)
  });
}

export type DateTimeInput = string | Date | moment.Moment | number;

export function asServerDateTime(val: DateTimeInput) {
  return moment(val).toISOString();
}

export interface ITimePeriod {
	dateRange: IDateRange;
	inclusion: IReportTimeInclusion;
}
export class TimePeriod implements ITimePeriod {
	public dateRange: IDateRange = new DateRange();
	public inclusion: IReportTimeInclusion = new ReportTimeInclusion();

	constructor(timePeriod?: ITimePeriod) {
		if (timePeriod != null) {
			Object.assign(this, timePeriod);
			this.dateRange = new DateRange(timePeriod.dateRange);
		}
	}
}

export interface IReportTime {
	reportRangeType: ReportRangeType;
	originReportRangeType: ReportRangeType;
	year: number;
	timePeriod: ITimePeriod;
}
export class ReportTime implements IReportTime {
	public reportRangeType: ReportRangeType = ReportRangeType.Today;
	public originReportRangeType: ReportRangeType = ReportRangeType.Today;
	public year: number = new Date().getFullYear() - 1;
	public timePeriod: ITimePeriod = new TimePeriod();

	constructor(reportTime?: IReportTime) {
		if (reportTime != null) {
			Object.assign(this, reportTime);
			this.timePeriod = new TimePeriod(reportTime.timePeriod);
		}
	}
}

export interface IReportTimeOptions {
	totalBy: TotalBy;
	reportTime: IReportTime;
}
export class ReportTimeOptions implements IReportTimeOptions {
	public totalBy: TotalBy = TotalBy.None;
	public reportTime: IReportTime = new ReportTime();

	constructor(reportTimeOptions?: IReportTimeOptions) {
		if (reportTimeOptions != null) {
			Object.assign(this, reportTimeOptions);
			this.reportTime = new ReportTime(reportTimeOptions.reportTime);
		}
	}
}
