import { IAssetsStore } from '@/store';
import { createModule, mutation, action } from 'vuex-class-component';
import {
  IAssetListItemViewModel
} from '@/view-models/assets';
import {
  IAssignmentTreeService,
  IUserService,
  IUserFavoriteViewModel
} from '@/view-models';
import { UserService } from '@/services';
import { getAxios } from '@/shared/http';
import AssignmentTreeService from '@/services/assignment-tree-service';
import { alphabeticSorter } from '@/shared/array-utils';
import Vue from 'vue';
import EmaCustomerService from '@/services/ema-customer-service';
import { authData } from '@/shared/auth';
import { EntityType } from '@/enums';

const VuexModule = createModule({
  namespaced: 'assets',
  strict: false,
  target: 'nuxt'
});

export class AssetsStore extends VuexModule implements IAssetsStore {
  // State
  public loadingItems: boolean = false;
  public assetListItems: Array<IAssetListItemViewModel> = [];
  public activeAsset: IAssetListItemViewModel = null;
  public searchResults: string[] = [];
  public updatingAssetsScores: boolean = false;
  public hoverKey: string = null;
  public isLeftPanelVisible: boolean = true;
  public updatedAssetListItems: Array<IAssetListItemViewModel> = [];
  public assetItemName: string = '';

  // Internals
  public get assetName(): string {
    return this.assetItemName;
  }

  private get assignmentTreeService(): IAssignmentTreeService {
    return new AssignmentTreeService(getAxios());
  }
  private get userService(): IUserService {
    return new UserService(getAxios());
  }

  // Getters
  public get rootAssetItems(): Array<IAssetListItemViewModel> {
    if (this.searchResults && this.searchResults.length) {
      return this.assetListItems.filter(a => this.searchResults.includes(a.key));
    }
    return this.assetListItems
      .filter(a => a != null && a.parentKey == null)
      .sort(alphabeticSorter(item => item.name));
  }

  public get getChildAssetItems(): (parentKey: string) => Array<IAssetListItemViewModel> {
    return (parentKey: string): Array<IAssetListItemViewModel> => {
      return this.assetListItems.filter(a => a.parentKey === parentKey);
    };
  }

  // Mutations
  @mutation
  public setAssetName(itemName: string): void {
    this.assetItemName = itemName;
  }

  @mutation
  public addAsset(item: IAssetListItemViewModel): void {
    const index: number = this.assetListItems.findIndex(asset => asset.key === item.key);
    if (index > -1) {
      updateAssetItemInStore(this, item);
    } else {
      this.assetListItems.push(item);
    }
  }
  @mutation
  public setAssetListItems(assetItems: Array<IAssetListItemViewModel>): void {
    this.assetListItems = assetItems.map((asset) => {
      const assetItem = this.updatedAssetListItems.find(item => item.key === asset.key);
      if (assetItem) {
        asset.isOpen = assetItem.isOpen ? assetItem.isOpen : false;
        asset.isActive = assetItem.isActive ? assetItem.isActive : false;
        asset.opportunityScore = assetItem.opportunityScore;
      } else {
        asset.isOpen = false;
      }
      return asset;
    });
  }
  @mutation
  public setUpdatedAssetListItems(assetItems: Array<IAssetListItemViewModel>): void {
    this.updatedAssetListItems = assetItems;
  }
  @mutation
  public setAssetListItemScore(payload: { index: number, score: number }): void {
    if (this.assetListItems[payload.index]) {
      this.assetListItems[payload.index].opportunityScore = payload.score;
      Vue.set(this.assetListItems, payload.index, this.assetListItems[payload.index]);
    }
  }
  @mutation
  private updateAssetListItemScore(oppScores: Record<string, number>): void {
    for (let i = 0; i < this.assetListItems.length; i++) {
      if (oppScores[this.assetListItems[i].key]) {
        this.assetListItems[i].opportunityScore = oppScores[this.assetListItems[i].key];
        Vue.set(this.assetListItems, i, this.assetListItems[i]);
      }
    }
  }
  @mutation
  public setIsLeftPanelVisible(value: boolean): void {
    this.isLeftPanelVisible = value;
  }
  @mutation
  public updateAssetListItems(assetItems: Array<IAssetListItemViewModel>): void {
    this.assetListItems.forEach((item, index) => {
      const found = assetItems.find(it => it.key === item.key);
      if (found != null) {
        // Update
        found.isOpen = item.isOpen;
        found.isActive = item.isActive;
        this.assetListItems.splice(index, 1, found);
      } else {
        // Delete
        this.assetListItems.splice(index, 1);
      }
    });

    assetItems.forEach((item) => {
      const found = this.assetListItems.find(it => it.key === item.key);
      if (found == null) {
        // Add
        item.isOpen = false;
        item.isActive = false;
        this.assetListItems.push(item);
      }
    });
  }
  @mutation
  public setLoadingItems(flag: boolean): void {
    this.loadingItems = flag;
  }
  @mutation
  public updateAssetItem(item: IAssetListItemViewModel): void {
    updateAssetItemInStore(this, item);
  }
  @mutation
  public setActiveAsset(itemKey: string) {
    let focusedItem: IAssetListItemViewModel;
    let foundIndex: number = -1;
    this.assetListItems.forEach((item, index) => {
      if (itemKey != null && item.key === itemKey) {
        focusedItem = item;
        foundIndex = index;
      } else {
        item.isActive = false;
        this.activeAsset = null;
      }
    });

    if (focusedItem != null) {
      focusedItem.isActive = true;
      focusedItem.isOpen = !focusedItem.isOpen;
      this.assetListItems.splice(foundIndex, 1, focusedItem);

      if (focusedItem.parentKey) {
        let parent: IAssetListItemViewModel = Object.assign({}, focusedItem);
        do {
          parent = this.assetListItems.find(it => parent.parentKey === it.key);
          if (parent) {
            parent.isOpen = true;
          }
        } while (parent != null);
      }

      this.activeAsset = focusedItem || null;
    }
  }
  @mutation
  public toggleItem(itemKey: string) {
    this.assetListItems.forEach((item) => {
      if (itemKey != null && item.key === itemKey) {
        item.isOpen = !item.isOpen;
      }
    });
  }
  @mutation
  public updateSearchResults(results: string[]): void {
    this.searchResults = results;
  }
  @mutation
  public setUpdatingAssetsScores(flag: boolean): void {
    this.updatingAssetsScores = flag;
  }
  @mutation
  public setHoverKey(hoverKey: string): void {
    this.hoverKey = hoverKey;
  }

  // Actions
  @action
  public async loadAssetList(): Promise<void> {
    this.setLoadingItems(true);

    await this.loadTreeWithEma();

    this.setLoadingItems(false);
  }
  @action
  public async refreshAssetList(): Promise<void> {
    await this.loadTreeWithEma();
  }
  @action
  private async loadTreeWithEma(): Promise<void> {
    const assetList: Array<IAssetListItemViewModel> = await this.assignmentTreeService.getAssignmentsTree(true);
    this.setUpdatedAssetListItems(this.assetListItems);
    this.setAssetListItems(assetList ?? []);

    // Now fetch the opportunity scores to have the heater keys
    if (authData.user?.activeCustomerKey) {
      const heaterKeys = assetList.reduce((acc, item) => {
        if (item.entityType === EntityType.Heater) {
          acc.push(item.key);
        }
        return acc;
      }, [] as string[]);

      if (heaterKeys.length > 0) {
        const emaService = EmaCustomerService.factory();
        const oppScores = await emaService.getHeatersOpportunityScores(authData.user.activeCustomerKey, heaterKeys);
        this.updateAssetListItemScore(oppScores);
      }
    }
  }
  @action
  public async toggleAssetFavourite(item: IAssetListItemViewModel): Promise<void> {
    const request: IUserFavoriteViewModel = {
      favoritedEntityKey: item.key,
      favoritedEntityType: item.entityType
    };
    const newItem = Object.assign({}, item);
    newItem.isFavorite = !item.isFavorite;

    if (item.isFavorite) {
      await this.userService.deleteFavorite(request);
    } else {
      await this.userService.addFavorite(request);
    }

    this.updateAssetItem(newItem);
  }
}

function updateAssetItemInStore(store: IAssetsStore, item: IAssetListItemViewModel): void {
  const index = store.assetListItems.findIndex(
    (assetListItem: IAssetListItemViewModel) => assetListItem.key === item.key
  );
  if (index !== -1) {
    store.assetListItems.splice(index, 1, item);
  }
}
