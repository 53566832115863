











// Libraries
import { Component } from 'nuxt-property-decorator';
import EventBus from '@/shared/event-bus';
import { NoCache } from '@/shared/decorators';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import TermsText from './TermsText.vue';
// Services
// Stores
@Component({
  name: 'terms-modal',
  components: {
    TermsText
  }
})
export default class TermsModal extends BaseComponent {
  // VUE.JS Props
  // VUEX
  // Properties
  public showModal: boolean = false;
  // Fields
  // Getters
  @NoCache
  get termsModal(): any {
    return this.$refs.termsModal as any;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  public created(): void {
    EventBus.$on('terms-modal::show', this.setupModal);
  }
  // beforeMount(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private setupModal(): void {
    this.$nextTick().then(() => {
      this.showModal = true;
    });
  }
  public close(): void {
    this.showModal = false;
  }
  // Helper Methods
  // Event Methods

  // Watchers
  // Emitters
}
