














// Libraries
import { Component } from 'nuxt-property-decorator';
// View Models
import { PageAccessEnum } from '@/enums/page-access';
// Components
import BaseComponent from '@/shared/BaseComponent';
@Component({
  name: 'auth-error-page-view',
  access: PageAccessEnum.Anonymous
})
export default class ErrorPageView extends BaseComponent {
  // VUE.JS Props
  // @Prop()

  // VUEX;
  public get env(): any {
    return this.$vxm.root.env;
  }

  // public error: ErrorParams;
  // public context: Context;
  // VUEX
  // Properties
  // Fields
  // Getters
  public get expireAccess(): boolean {
    const accessExpiredError: string = this.$route.query.error_description.toString();
    return accessExpiredError === 'Access expired';
  }

  public get hasClientId(): boolean {
    const clientID: string = this.$route.query?.['client_id']?.toString();
    return !String.isNullOrWhiteSpace(clientID);
  }

  public get target(): string {
    const clientID: string = this.$route.query.client_id.toString();
    return this.env.authErrorRedirect[clientID];
  }
  // Lifecycle Handlers
  // public beforeCreate(): void {}
  // beforeMount(): void {}
  // public mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
