






































































// Libraries
import { Component } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
// Services
// Stores
@Component({
  name: 'terms-text'
})
export default class TermsText extends BaseComponent {
  // VUE.JS Props
  // VUEX
  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods

  // Watchers
  // Emitters
}
