import { Context } from '@nuxt/types';
import { extractVuexModule, createProxy } from 'vuex-class-component';
import {
  CompanyStore,
  TowerStore,
  HeaterStore,
  RootStore,
  UserStore,
  AssetsStore,
  AlertsStore, ReportVariablesTreeStore, DashboardStoreV2, VisualizationV2
} from '@/store-modules';
import {
  ICompanyStore,
  ITowerStore,
  IRootStore,
  IHeaterStore,
  IUserStore,
  IAssetsStore,
  IAlertsStore,
  IDashboardStoreV2,
  IVisualizationV2
} from '@/store';
import { IReportVariablesTreeStore } from '@/view-models/report-variables-tree/report-variables-tree';

let vxm: IModuleProxies = {
  assets: null,
  company: null,
  dashboardV2: null,
  tower: null,
  root: null,
  heater: null,
  user: null,
  alerts: null,
  reportVariablesTree: null,
  visualizationV2: null
};

export default function<Nuxt, Plugin>({ store }: Context, inject: Function): void {
  // register modules
  store.registerModule('assets', {
    ...(extractVuexModule(AssetsStore).assets)
  });
  store.registerModule('company', {
    ...(extractVuexModule(CompanyStore).company)
  });
  store.registerModule('dashboard-v2', {
    ...(extractVuexModule(DashboardStoreV2)['dashboard-v2'])
  });
  store.registerModule('tower', {
    ...(extractVuexModule(TowerStore).tower)
  });
  store.registerModule('root', {
    ...(extractVuexModule(RootStore).root)
  });
  store.registerModule('heater', {
    ...(extractVuexModule(HeaterStore).heater)
  });
  store.registerModule('user', {
    ...(extractVuexModule(UserStore).user)
  });
  store.registerModule('alerts', {
    ...(extractVuexModule(AlertsStore).alerts)
  });
  store.registerModule('report-variables-tree', {
    ...(extractVuexModule(ReportVariablesTreeStore)['report-variables-tree'])
  });
  store.registerModule('visualization-v2', {
    ...(extractVuexModule(VisualizationV2)['visualization-v2'])
  });

  vxm = {
    assets: createProxy(store, AssetsStore),
    company: createProxy(store, CompanyStore),
    dashboardV2: createProxy(store, DashboardStoreV2),
    tower: createProxy(store, TowerStore),
    root: createProxy(store, RootStore),
    heater: createProxy(store, HeaterStore),
    user: createProxy(store, UserStore),
    alerts: createProxy(store, AlertsStore),
    reportVariablesTree: createProxy(store, ReportVariablesTreeStore),
    visualizationV2: createProxy(store, VisualizationV2)
  };

  inject('vxm', vxm);
}

export interface IModuleProxies {
  assets: IAssetsStore,
  company: ICompanyStore,
  dashboardV2: IDashboardStoreV2,
  tower: ITowerStore,
  root: IRootStore,
  heater: IHeaterStore,
  user: IUserStore,
  alerts: IAlertsStore,
  reportVariablesTree: IReportVariablesTreeStore,
  visualizationV2: IVisualizationV2
}

declare module 'vue/types/vue' {
  interface Vue {
    $vxm?: IModuleProxies;
  }
}
declare module 'vuex' {
  interface Store<S> {
    readonly $vxm?: IModuleProxies;
  }
}

declare module '@nuxt/types' {
  interface Context {
    $vxm?: IModuleProxies;
  }
}
