import axios, {
  AxiosInstance,
  Method,
  CancelToken,
  AxiosRequestConfig,
  CancelTokenSource
} from 'axios';

export default class BaseService {
  protected httpClient: HttpClient;

  constructor(
    private readonly prefix: string,
    protected readonly http: AxiosInstance,
    private readonly path: string = 'api',
    baseUrl: string = ''
  ) {
    this.prefix = prefix || '';
    if (baseUrl) {
      this.path = baseUrl + (path != null && typeof path === 'string' ? path : 'api');
    } else {
    this.path = path != null && typeof path === 'string' ? path : 'api';
    }
    this.http = http;
    const prefixStr = `/${this.prefix}`;
    this.httpClient = new HttpClient(
      this.http,
      `${this.path}${this.prefix ? prefixStr : ''}`
    );
  }

  protected getUrl(action: string): string {
    return `${this.path}/${this.prefix}/${action}`;
  }
}

export class HttpClient {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly axios: AxiosInstance,
    private readonly baseUri: string
  ) {
  }

  protected uri(action?: string) {
    return String.isNullOrWhiteSpace(action) ? this.baseUri : `${this.baseUri}/${action}`;
  }

  public newCancelController(): CancelTokenSource {
    return axios.CancelToken.source();
  }

  public async get<T>(action?: string, query?: {}, cancelToken?: CancelToken, headers?: any): Promise<T> {
    const config: AxiosRequestConfig = {};
    if (query != null) {
      config.params = query;
    }
    if (cancelToken != null) {
      config.cancelToken = cancelToken;
    }
    if (headers != null) {
      config.headers = headers;
    }
    return (await this.axios.get<T>(this.uri(action), config)).data;
  }

  public async post<T>(action?: string, payload?: any, cancelToken?: CancelToken, headers?: any): Promise<T> {
    let config: AxiosRequestConfig = {};
    if (cancelToken != null) {
      config = { cancelToken };
    }
    if (headers != null) {
      config.headers = headers;
    }
    return (await this.axios.post<T>(this.uri(action), payload, config)).data;
  }

  public async patch<T>(action?: string, payload?: any, cancelToken?: CancelToken, headers?: any): Promise<T> {
    let config: AxiosRequestConfig = {};
    if (cancelToken != null) {
      config = { cancelToken };
    }
    if (headers != null) {
      config.headers = headers;
    }
    return (await this.axios.patch<T>(this.uri(action), payload, config)).data;
  }

  public async put<T>(action?: string, payload?: any, cancelToken?: CancelToken, headers?: any): Promise<T> {
    let config: AxiosRequestConfig = {};
    if (cancelToken != null) {
      config = { cancelToken };
    }
    if (headers != null) {
      config.headers = headers;
    }
    return (await this.axios.put<T>(this.uri(action), payload, config)).data;
  }

  public async delete<T>(action?: string, cancelToken?: CancelToken, payload?: any): Promise<T> {
    let config: AxiosRequestConfig = {};
    if (cancelToken != null) {
      config = { cancelToken };
    }
    if (payload !== null) {
      config = Object.assign(config, { data: payload });
    }
    return (await this.axios.delete<T>(this.uri(action), config)).data;
  }

  public async request<T>(method: Method, uri: string, data?: any, cancelToken?: CancelToken) {
    return (await this.axios.request<T>({ data, method, url: uri, cancelToken })).data;
  }
}

export interface IPendingRequest {
  promise: Promise<any>;
  canceler: CancelTokenSource;
  finished?: boolean;
}

export interface IPendingRequestsHashtable {
  [key: string]: IPendingRequest;
}

export class PendingRequestsHandler {
  private pendingRequests: IPendingRequestsHashtable = {};

  public insert(key: string, request: IPendingRequest) {
    request.promise.finally(() => { request.finished = true; });
    this.pendingRequests[key] = request;
  }

  public cancel(key: string) {
    if (this.canCancel(key)) {
      this.pendingRequests[key].canceler.cancel();
    }
    this.remove(key);
  }

  public remove(key: string) {
    if (this.pendingRequests[key] != null) {
      delete this.pendingRequests[key];
    }
  }

  public reset() {
    Object.keys(this.pendingRequests).forEach(this.cancel.bind(this));
  }

  private canCancel(key: string) {
    return this.pendingRequests[key] != null &&
      this.pendingRequests[key].canceler != null &&
      this.pendingRequests[key].finished !== true;
  }
}
