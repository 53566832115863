import 'es6-promise/auto';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import BaseService from '@/services/base-service';
import {
  IUserService,
  IRecentViewModel,
  IUserFavoriteViewModel,
  IFavoriteTileItemViewModel
} from '@/view-models';

export default class UserService extends BaseService implements IUserService {
  constructor(axios: AxiosInstance) {
    super('user', axios);
  }

  public getFavoritesTiles(): Promise<Array<IFavoriteTileItemViewModel>> {
    return this.httpClient.get<Array<IFavoriteTileItemViewModel>>('favorites/tiles');
  }

  public updateSortOrder(request: IUserFavoriteViewModel): Promise<void> {
    return this.http.put(this.getUrl(`favorites`), request);
  }

  public async addFavorite(request: IUserFavoriteViewModel): Promise<void> {
    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'application/json' }
    };
    await this.http.post(this.getUrl(`favorites`), request, config);
  }

  public async deleteFavorite(request: IUserFavoriteViewModel): Promise<void> {
    await this.http.delete(
      this.getUrl(`favorite/${request.favoritedEntityKey}`)
    );
  }

  public getRecents(): Promise<Array<IRecentViewModel>> {
    return this.httpClient.get<Array<IRecentViewModel>>('recents');
  }

  public async addRecent(request: IRecentViewModel): Promise<void> {
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    await this.http.post(this.getUrl('recents'), request, config);
  }
}
