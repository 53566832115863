import { IHierarchyTreeViewModel } from '@/view-models/report-variables-tree/report-variable-node';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IAssetHierarchyService } from '@/view-models';

export class AssetHierarchyService extends BaseService implements IAssetHierarchyService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('report-asset-hierarchy', axios, '', baseUrl);
  }

  public getAssetHierarchyTrees(assetKey: string): Promise<IHierarchyTreeViewModel[]> {
    return this.httpClient.get<IHierarchyTreeViewModel[]>(assetKey, { includeAsset: true });
  }
}
