
import { Context } from '@nuxt/types';

type RedirectSetting = [RegExp | string, string]; // tuple

const redirects: Array<RedirectSetting> = [
  [/^\/account/, '/']
];

export default function<Nuxt, Plugin>({ redirect, route }: Context): void {
  const [, path] = redirects.find(([matcher]) => route.path.match(matcher) != null) || [null, null];
  if (path != null) {
    redirect(path);
  }
}
