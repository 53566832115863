
// If you change any of this ones, make sure to make changes in the translation files too under security.permissions
export enum Permission {
  Unknown = 'Unknown',
  CreateUser = 'CreateUser',
  ManageUsers = 'ManageUsers',
  SetUserAssignments = 'SetUserAssignments',
  ReadBurnerStatus = 'ReadBurnerStatus',
  WriteBurnerStatus = 'WriteBurnerStatus',
  ManageCustomers = 'ManageCustomers',
  ViewCustomers = 'ViewCustomers',
  ReadHeaters = 'ReadHeaters',
  CreateHeaters = 'CreateHeaters',
  ManageHeaters = 'ManageHeaters',
  DeleteLogs = 'DeleteLogs',
  ManageKESCompanies = 'ManageKESCompanies',
  ShowRecommendationOverride = 'ShowRecommendationOverride',
  KESPortalAccess = 'KESPortalAccess',
  ViewAssetDiagram = 'ViewAssetDiagram',
  CreateAssetDiagram = 'CreateAssetDiagram',
  ViewReports = 'ViewReports',
  ManageReports = 'ManageReports',
  ViewDashboards = 'ViewDashboards',
  ManageDashboards = 'ManageDashboards',
  ManagePerformance = 'ManagePerformance',
  ManageHierarchy = 'ManageHierarchy',
  ViewHierarchy = 'ViewHierarchy',
  ViewPerformance = 'ViewPerformance',
  CreateHydraulicModel = 'CreateHydraulicModel',
  ViewHydraulicModel = 'ViewHydraulicModel',
  CreateHydraulicModelTuning = 'CreateHydraulicModelTuning',
  ViewHydraulicModelTuning = 'ViewHydraulicModelTuning',
  ManageAssetConfiguration = 'ManageAssetConfiguration',
  ViewAssetConfiguration = 'ViewAssetConfiguration',
  ManagePlantConnectorAgent = 'ManagePlantConnectorAgent',
  ViewPlantConnectorAgent = 'ViewPlantConnectorAgent',
  DeletePlantConnectorAgent = 'DeletePlantConnectorAgent',
  ManagePlantConnectorApi = 'ManagePlantConnectorApi',
  ReadTowers = 'ReadTowers',
  ReadTowerStages = 'ReadTowerStages',
  ManageTowers = 'ManageTowers',
  CreateTowers = 'CreateTowers',
  ViewUnit = 'ViewUnit',
  ManageUnit = 'ManageUnit',
  CreateUserAndRole = 'CreateUserAndRole',
  ManageUserAndRole = 'ManageUserAndRole',
  DeleteUserAndRole = 'DeleteUserAndRole',
  ViewVariableEditor = 'ViewVariableEditor',
  ManageVariableEditor = 'ManageVariableEditor',
  ManageCustomerAccounts = 'ManageCustomerAccounts',
  DeleteCustomerAccounts = 'DeleteCustomerAccounts',
  ViewBlueprints = 'ViewBlueprints',
  ManageSystemBlueprints = 'ManageSystemBlueprints',
  ManageNonSystemBlueprints = 'ManageNonSystemBlueprints'
}
