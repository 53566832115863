import { Context } from '@nuxt/types';
import Vue from 'vue';
import VueSignalR, { ISocketConnectionOptions } from '@/modules/vue-signalr';
import { authClient } from '@/shared/auth';

export default async function<Nuxt, Plugin>({ isDev, $vxm }: Context, inject: (key: string, value: any) => void): Promise<void> {
  const config: ISocketConnectionOptions = {
    connection: `${$vxm.root.env.notificationHubUrl}notification-hub`,
    debug: isDev,
    errorHandler: (error: any) => {
      // HelperMethods.catch($axios, error);
      // eslint-disable-next-line
      console.error(error);
    },
    accessTokenFactory: () => authClient.getAuthToken()
  };
  Vue.use<ISocketConnectionOptions>(VueSignalR, config);
  if (await authClient.isAuthenticated()) {
    if (!Vue.prototype.$socket.offline) {
      await Vue.prototype.$socket.stop();
    }
    await Vue.prototype.$socket.start();
  }
  inject('socket', Vue.prototype.$socket);
}
