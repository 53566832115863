import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import { IHeaterViewModel, IHeaterService } from '@/view-models';
import BaseService from './base-service';
import AeAssetDetailsService from './ae-asset-service';

export default class HeaterService extends BaseService implements IHeaterService {
  constructor(axios: AxiosInstance) {
    super('heater', axios);
  }

  // This needs to be replaced by IAeAssetDetailsService.getAssetDetails()
  // for now we use it internally and map results to this view model
  public async getHeater(key: string): Promise<IHeaterViewModel> {
    const assetDetailsService = AeAssetDetailsService.factory();
    const assetDetails = await assetDetailsService.getAssetDetails(key);

    // Grabbing last ember run calculation from ember, but with a save guard if it fails to null
    // mainly because this endpoint still fails when it is a new heater for some reason.
    // also converting the date string to the expected format
    // from this "09/30/2021 00:11:18"
    // to this "2021-09-29T23:37:51.168Z"

    return {
      key: assetDetails.key,
      name: assetDetails.emberName,
      orgKey: assetDetails.customerKey,
      lastModifiedAt: assetDetails.lastUpdatedDate,
      lastModifiedBy: assetDetails.lastUpdatedBy,
      searchName: assetDetails.emberName?.toLowerCase(),
      customerKey: assetDetails.customerKey,
      customerSiteKey: assetDetails.siteKey,
      equipmentConfigKey: assetDetails.key, // not the same, but I don't think we use this in the front end
      assetGroupingKey: assetDetails.unitKey,
      location: {
        lat: parseFloat(assetDetails.location?.latitude ?? '0'),
        lng: parseFloat(assetDetails.location?.longitude ?? '0')
      },
      serviceType: assetDetails.equipmentType
    };
  }
}
