































// Libraries
import { NuxtError } from '@nuxt/types';
import { Component, Prop } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
// Services
@Component({
	name: 'error-page-view'
})
export default class ErrorPageView extends BaseComponent {
  // VUE.JS Props
  @Prop()
  public error: NuxtError;
  // VUEX
  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
