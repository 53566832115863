import 'es6-promise/auto';
import { AxiosResponse, AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IAlertsService } from '@/view-models';
import { DiagnosticScenario, DiagnosticTask, TaskNames } from '@/view-models/assets';
import { IDownloadImageRequest } from '@/enums';

export default class AlertsService extends BaseService implements IAlertsService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('diagnostics', axios, 'api', baseUrl);
  }

  /**
   * Populate the list of scenarios for a particular asset.
   * @returns {Promise<Array<DiagnosticScenario>>}
   * @param assetKey The current assetKey to populate the list of scenarios
   */
  public async getDiagnosticScenarios(assetKey: string): Promise<Array<DiagnosticScenario>> {
    const response: AxiosResponse<Array<DiagnosticScenario>> = await this.http.get(
      this.getUrl(`asset/${assetKey}/scenarios`)
    );
    return response.data;
  }

  /**
   * Populate the list of diagnostic tasks for a particular asset.
   * @returns {Promise<Array<DiagnosticTask>>}
   * @param assetKey The current assetKey to populate the list of diagnostic tasks
   */
  public async getDiagnosticTasks(assetKey: string): Promise<Array<DiagnosticTask>> {
    const response: AxiosResponse<Array<DiagnosticTask>> = await this.http.get(
      this.getUrl(`asset/${assetKey}/tasks`)
    );
    return response.data;
  }

   /**
   * To get the pre-signed url of the task's example image
   * @returns {Promise<string>} - returns pre-signed url for image download
   * @param request:IDownloadImageRequest
   */
  public async downloadTaskMedia(request:IDownloadImageRequest): Promise<string> {
    const resultData:string = await this.http
      .get(this.getUrl(`${request.assetKey}/taskmedia/${request.imageKey}/download`))
      .then((response: AxiosResponse<string>) => response.data);
    return resultData;
  }
    /**
   * To update a particular diagnostic task status
   * @returns {Promise<any>} - returns the task details
   * @param request:any
   */
  public async updateTask(request): Promise<any> {
    const resultData:any = await this.http
      .put(this.getUrl(`taskcomplete`), request)
      .then((response: AxiosResponse<any>) => response.data);
    return resultData;
  }

  /**
   * Populate the list of scenario history for a particular asset.
   * @returns {Promise<Array<DiagnosticScenario>>}
   * @param assetKey The current assetKey to populate the list of scenarios
   */
  public async getScenarioHistory(request: any): Promise<any> {
    const response: AxiosResponse<Array<DiagnosticScenario>> = await this.http.get(
      this.getUrl(`asset/${request.assetKey}/scenariohistory?fromDate=${request.fromDate}&toDate=${request.endDate}`)
    );
    return response.data;
  }

  /**
   * Populate the list of task names for a particular asset.
   * @returns {Promise<Array<TaskNames>>}
   * @param assetKey The current assetKey to populate the list of task names
   */
  public async getTaskNames(assetKey: string): Promise<Array<TaskNames>> {
    const response: AxiosResponse<Array<TaskNames>> = await this.http.get(
      this.getUrl(`asset/${assetKey}/taskkeynames`)
    );
    return response.data;
  }
}
