










// Libraries
import { Component, Prop, Emit } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
// Services
// Store

@Component({
  name: 'delete-tile-modal'
})
export default class DeleteTileModal extends BaseComponent {
  // VUE.JS Props
  @Prop({ default: false })
  public value!: boolean;
  @Prop({ default: null })
  public name!: string;

  // VUEX
  // Properties
  // Fields
  // Getters
  public get localShown(): boolean {
    return this.value;
  }
  public set localShown(value: boolean) {
    this.updateValue(value);
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // private created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public confirm(event: Event) {
    event.preventDefault();
    this.deleteItem();
  }
  // Watchers
  // Emitters
  @Emit('delete')
  public deleteItem() {
    this.updateValue(false);
  }

  @Emit('input')
  public updateValue(unused: boolean) {}
}
