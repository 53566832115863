import { Vue } from 'vue-property-decorator';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';

declare global {
  interface Window {
    eftEventBus: Vue
  }
}
interface IEventBus {
  $on(name: string, callback: Function): void;
  $off(name: string, callback?: Function): void;
  $emit(name: string, ...args: any[]): void;
}

window.eftEventBus = new Vue();

// trying to reuse the same object, for some reason just assigning EventBus = window.eftEventBus; was not enough and breaking.
const EventBus: IEventBus = {
  $on(name: string, callback: Function): void {
    window.eftEventBus.$on(name, callback);
  },
  $off(name: string, callback?: Function): void {
    window.eftEventBus.$off(name, callback);
  },
  $emit(name: string, ...args: any[]): void {
    window.eftEventBus.$emit(name, ...args);
  }
};
export default EventBus;

export enum Events {
  WINDOW_RESIZE = 'WindowResize',
  REPORT_SHIFT_RESET = 'reports::ReportShiftReset',
  USER_ACTIVE_CUSTOMER_CHANGED = 'user::ActiveCustomerChanged',
  REFRESH_PERFORMANCE_WIDGETS = 'dashboard::RefreshWidgets',
  SHOW_TOAST_NOTIFICATION = 'dashboard::showNotification',
  ASSET_TREE_ITEM_CLICKED = 'asset::ItemClicked',
  ROUTE_CHANGE = 'onpoint::routeChange',
  ROUTE_CHANGE_CHILD = 'onpoint::routeChangeChild'
}

export class EventBusSubscription {
  constructor(private readonly name: string, private readonly handler: Function) {
    EventBus.$on(this.name, this.handler);
  }

  public unsubscribe(): void {
    EventBus.$off(this.name, this.handler);
  }
}

export type DataStatusHandler<T> = (status: T) => (void | Promise<void>);

export class DataStatus<TDataStatus extends Record<string, any>> {
  private readonly subject$: BehaviorSubject<TDataStatus>;
  public get status(): TDataStatus {
    return this.subject$.getValue();
  }

  constructor(dataStatus: TDataStatus) {
    this.subject$ = new BehaviorSubject<TDataStatus>(dataStatus);
  }

  public emit(status: Partial<TDataStatus>): void {
    this.subject$.next({ ...this.status, ...status });
  }

  public subscribe(handler: DataStatusHandler<TDataStatus>): Subscription {
    return this.subject$.subscribe(async (status: TDataStatus) => {
      await handler(status);
    });
  }

  public subscribeOnce(handler: DataStatusHandler<TDataStatus>): void {
    this.subject$.pipe(first()).subscribe(async (status: TDataStatus) => {
      await handler(status);
    });
  }

  public subscribeUntil(
    predicate: (status: TDataStatus) => boolean,
    handler: DataStatusHandler<TDataStatus>
  ) {
    this.subject$
      .pipe(
        skipWhile((status: TDataStatus) => !predicate(status)),
        first()
      )
      .subscribe(async (status: TDataStatus) => {
        await handler(status);
      });
  }
}
