import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a0ef650 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _26ba12a6 = () => interopDefault(import('../pages/assets.vue' /* webpackChunkName: "pages/assets" */))
const _71fb5990 = () => interopDefault(import('../pages/assets/tower/_key.vue' /* webpackChunkName: "pages/assets/tower/_key" */))
const _48e18fbb = () => interopDefault(import('../pages/assets/tower/_key/index.vue' /* webpackChunkName: "pages/assets/tower/_key/index" */))
const _527b64c0 = () => interopDefault(import('../pages/assets/tower/_key/dashboard/index.vue' /* webpackChunkName: "pages/assets/tower/_key/dashboard/index" */))
const _58d528c2 = () => interopDefault(import('../pages/assets/tower/_key/stages/index.vue' /* webpackChunkName: "pages/assets/tower/_key/stages/index" */))
const _6c1578dc = () => interopDefault(import('../pages/assets/tower/_key/summary/index.vue' /* webpackChunkName: "pages/assets/tower/_key/summary/index" */))
const _4e061186 = () => interopDefault(import('../pages/assets/tower/_key/validations/index.vue' /* webpackChunkName: "pages/assets/tower/_key/validations/index" */))
const _879795a6 = () => interopDefault(import('../pages/assets/tower/_key/stages/_stageKey.vue' /* webpackChunkName: "pages/assets/tower/_key/stages/_stageKey" */))
const _666fdb1c = () => interopDefault(import('../pages/assets/_key.vue' /* webpackChunkName: "pages/assets/_key" */))
const _1447b7b0 = () => interopDefault(import('../pages/assets/_type/_key.vue' /* webpackChunkName: "pages/assets/_type/_key" */))
const _4e1226aa = () => interopDefault(import('../pages/assets/_type/_key/index.vue' /* webpackChunkName: "pages/assets/_type/_key/index" */))
const _450af4ed = () => interopDefault(import('../pages/assets/_type/_key/dashboard.vue' /* webpackChunkName: "pages/assets/_type/_key/dashboard" */))
const _305b86a1 = () => interopDefault(import('../pages/assets/_type/_key/layout.vue' /* webpackChunkName: "pages/assets/_type/_key/layout" */))
const _4d5e52ae = () => interopDefault(import('../pages/autherror/index.vue' /* webpackChunkName: "pages/autherror/index" */))
const _793bdc21 = () => interopDefault(import('../pages/no-permission.vue' /* webpackChunkName: "pages/no-permission" */))
const _17241870 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _a24de766 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _458bc3d3 = () => interopDefault(import('../pages/utilities/index.vue' /* webpackChunkName: "pages/utilities/index" */))
const _3eb02424 = () => interopDefault(import('../pages/admin/companies/index.vue' /* webpackChunkName: "pages/admin/companies/index" */))
const _8fd9af34 = () => interopDefault(import('../pages/admin/organization-management/index.vue' /* webpackChunkName: "pages/admin/organization-management/index" */))
const _209d8b34 = () => interopDefault(import('../pages/admin/plant-connector/index.vue' /* webpackChunkName: "pages/admin/plant-connector/index" */))
const _c3ddf264 = () => interopDefault(import('../pages/admin/user-management/index.vue' /* webpackChunkName: "pages/admin/user-management/index" */))
const _0538062d = () => interopDefault(import('../pages/admin/user-management/role/index.vue' /* webpackChunkName: "pages/admin/user-management/role/index" */))
const _a40c1208 = () => interopDefault(import('../pages/admin/user-management/roles/index.vue' /* webpackChunkName: "pages/admin/user-management/roles/index" */))
const _c67c2cb8 = () => interopDefault(import('../pages/utilities/ember/asset-editor/index.vue' /* webpackChunkName: "pages/utilities/ember/asset-editor/index" */))
const _2bb76e9f = () => interopDefault(import('../pages/utilities/ember/hierarchy-builder/index.vue' /* webpackChunkName: "pages/utilities/ember/hierarchy-builder/index" */))
const _8ea8ee10 = () => interopDefault(import('../pages/utilities/ember/hydraulic-modeler/index.vue' /* webpackChunkName: "pages/utilities/ember/hydraulic-modeler/index" */))
const _2f9d404c = () => interopDefault(import('../pages/utilities/ember/layout-editor/index.vue' /* webpackChunkName: "pages/utilities/ember/layout-editor/index" */))
const _07c06a0c = () => interopDefault(import('../pages/utilities/ember/variable-editor/index.vue' /* webpackChunkName: "pages/utilities/ember/variable-editor/index" */))
const _d42247f2 = () => interopDefault(import('../pages/utilities/ember/variable-editor/_.vue' /* webpackChunkName: "pages/utilities/ember/variable-editor/_" */))
const _3b0fca59 = () => interopDefault(import('../pages/utilities/ember/layout-editor/_.vue' /* webpackChunkName: "pages/utilities/ember/layout-editor/_" */))
const _d4d56df6 = () => interopDefault(import('../pages/utilities/ember/hydraulic-modeler/_.vue' /* webpackChunkName: "pages/utilities/ember/hydraulic-modeler/_" */))
const _5f67cba8 = () => interopDefault(import('../pages/utilities/ember/hierarchy-builder/_.vue' /* webpackChunkName: "pages/utilities/ember/hierarchy-builder/_" */))
const _fa22409e = () => interopDefault(import('../pages/utilities/ember/asset-editor/_.vue' /* webpackChunkName: "pages/utilities/ember/asset-editor/_" */))
const _28335909 = () => interopDefault(import('../pages/admin/user-management/roles/_.vue' /* webpackChunkName: "pages/admin/user-management/roles/_" */))
const _13f2a8ba = () => interopDefault(import('../pages/admin/user-management/role/_.vue' /* webpackChunkName: "pages/admin/user-management/role/_" */))
const _7aafe002 = () => interopDefault(import('../pages/reports/settings/_key.vue' /* webpackChunkName: "pages/reports/settings/_key" */))
const _5410f2c0 = () => interopDefault(import('../pages/reports/view/_key.vue' /* webpackChunkName: "pages/reports/view/_key" */))
const _5041bc4a = () => interopDefault(import('../pages/admin/user-management/_.vue' /* webpackChunkName: "pages/admin/user-management/_" */))
const _0079c941 = () => interopDefault(import('../pages/admin/plant-connector/_.vue' /* webpackChunkName: "pages/admin/plant-connector/_" */))
const _d6bc611a = () => interopDefault(import('../pages/admin/organization-management/_.vue' /* webpackChunkName: "pages/admin/organization-management/_" */))
const _04db4e18 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _1a0ef650,
    name: "admin"
  }, {
    path: "/assets",
    component: _26ba12a6,
    name: "assets",
    children: [{
      path: "tower/:key?",
      component: _71fb5990,
      children: [{
        path: "",
        component: _48e18fbb,
        name: "assets-tower-key"
      }, {
        path: "dashboard",
        component: _527b64c0,
        name: "assets-tower-key-dashboard"
      }, {
        path: "stages",
        component: _58d528c2,
        name: "assets-tower-key-stages"
      }, {
        path: "summary",
        component: _6c1578dc,
        name: "assets-tower-key-summary"
      }, {
        path: "validations",
        component: _4e061186,
        name: "assets-tower-key-validations"
      }, {
        path: "stages/:stageKey",
        component: _879795a6,
        name: "assets-tower-key-stages-stageKey"
      }]
    }, {
      path: ":key?",
      component: _666fdb1c,
      name: "assets-key"
    }, {
      path: ":type?/:key?",
      component: _1447b7b0,
      children: [{
        path: "",
        component: _4e1226aa,
        name: "assets-type-key"
      }, {
        path: "dashboard",
        component: _450af4ed,
        name: "assets-type-key-dashboard"
      }, {
        path: "layout",
        component: _305b86a1,
        name: "assets-type-key-layout"
      }]
    }]
  }, {
    path: "/autherror",
    component: _4d5e52ae,
    name: "autherror"
  }, {
    path: "/no-permission",
    component: _793bdc21,
    name: "no-permission"
  }, {
    path: "/reports",
    component: _17241870,
    name: "reports"
  }, {
    path: "/terms",
    component: _a24de766,
    name: "terms"
  }, {
    path: "/utilities",
    component: _458bc3d3,
    name: "utilities"
  }, {
    path: "/admin/companies",
    component: _3eb02424,
    name: "admin-companies"
  }, {
    path: "/admin/organization-management",
    component: _8fd9af34,
    name: "admin-organization-management"
  }, {
    path: "/admin/plant-connector",
    component: _209d8b34,
    name: "admin-plant-connector"
  }, {
    path: "/admin/user-management",
    component: _c3ddf264,
    name: "admin-user-management"
  }, {
    path: "/admin/user-management/role",
    component: _0538062d,
    name: "admin-user-management-role"
  }, {
    path: "/admin/user-management/roles",
    component: _a40c1208,
    name: "admin-user-management-roles"
  }, {
    path: "/utilities/ember/asset-editor",
    component: _c67c2cb8,
    name: "utilities-ember-asset-editor"
  }, {
    path: "/utilities/ember/hierarchy-builder",
    component: _2bb76e9f,
    name: "utilities-ember-hierarchy-builder"
  }, {
    path: "/utilities/ember/hydraulic-modeler",
    component: _8ea8ee10,
    name: "utilities-ember-hydraulic-modeler"
  }, {
    path: "/utilities/ember/layout-editor",
    component: _2f9d404c,
    name: "utilities-ember-layout-editor"
  }, {
    path: "/utilities/ember/variable-editor",
    component: _07c06a0c,
    name: "utilities-ember-variable-editor"
  }, {
    path: "/utilities/ember/variable-editor/*",
    component: _d42247f2,
    name: "utilities-ember-variable-editor-all"
  }, {
    path: "/utilities/ember/layout-editor/*",
    component: _3b0fca59,
    name: "utilities-ember-layout-editor-all"
  }, {
    path: "/utilities/ember/hydraulic-modeler/*",
    component: _d4d56df6,
    name: "utilities-ember-hydraulic-modeler-all"
  }, {
    path: "/utilities/ember/hierarchy-builder/*",
    component: _5f67cba8,
    name: "utilities-ember-hierarchy-builder-all"
  }, {
    path: "/utilities/ember/asset-editor/*",
    component: _fa22409e,
    name: "utilities-ember-asset-editor-all"
  }, {
    path: "/admin/user-management/roles/*",
    component: _28335909,
    name: "admin-user-management-roles-all"
  }, {
    path: "/admin/user-management/role/*",
    component: _13f2a8ba,
    name: "admin-user-management-role-all"
  }, {
    path: "/reports/settings/:key?",
    component: _7aafe002,
    name: "reports-settings-key"
  }, {
    path: "/reports/view/:key?",
    component: _5410f2c0,
    name: "reports-view-key"
  }, {
    path: "/admin/user-management/*",
    component: _5041bc4a,
    name: "admin-user-management-all"
  }, {
    path: "/admin/plant-connector/*",
    component: _0079c941,
    name: "admin-plant-connector-all"
  }, {
    path: "/admin/organization-management/*",
    component: _d6bc611a,
    name: "admin-organization-management-all"
  }, {
    path: "/",
    component: _04db4e18,
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
