








// Libraries
import { Component, Prop } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import { IIcon } from '@/components/types';
// Services

@Component({
  name: 'icon'
})
export default class Icon extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public icon: IIcon;
  @Prop({ default: '' })
  public classes: string;
  // VUEX
  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // created(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
