import '@nuxtjs/axios';
import { AxiosResponse } from 'axios';
import Vue from 'vue';
import PrettyCheckbox from 'pretty-checkbox-vue';
import Vue2TouchEvents from 'vue2-touch-events';
import { Context } from '@nuxt/types';
import VueFilters from '@/modules/kes-filters';
import { IEnvironmentVariables, IErrorBusMessage } from '@/view-models';
import HelperMethods from '@/shared/helper-methods';

export default async function <Nuxt, Plugin>({ $axios, $vxm, redirect }: Context): Promise<void> {
  const scheme: string = window.location.protocol;
  const host: string = window.location.host;
  const config: IEnvironmentVariables = await $axios
    .get(`${scheme}//${host}/env.json?d=${Date.now().toString()}`)
    .then((response: AxiosResponse) => response.data);

  $vxm.root.setEnv(config);
  $vxm.alerts.setEnv(config);
  $vxm.user.setEnv(config);
  $vxm.tower.setEnv(config);
  $vxm.reportVariablesTree.setEnv(config);
  const globalHandler = function(error: any) {
    HelperMethods.catch($axios, error);
  };
  window.eftEventBus.$on('GLOBAL_ERROR', (errorBusMessage: IErrorBusMessage) => {
    const errorMessage: IErrorBusMessage = Object.assign({
      sendLog: true,
      showMessage: true
    }, errorBusMessage);
    HelperMethods.defaultCatch($axios, errorMessage.error, errorMessage.sendLog, !errorMessage.showMessage, errorMessage.errorTag);
  });
  window.eftEventBus.$on('GLOBAL_ROUTE_CHANGE', (newRoutePath: string) => {
    redirect(newRoutePath);
  });
  window.eftEventBus.$on('GLOBAL_ROUTE_CHANGE_WITH_QUERY', (newRoutePathWithQuery: { path: string; query: string }) => {
    redirect({ path: newRoutePathWithQuery.path, query: { orgKey: newRoutePathWithQuery.query } });
  });
  Vue.config.devtools = true;
  Vue.config.errorHandler = globalHandler;

  Vue.use(PrettyCheckbox);
  Vue.use(VueFilters);
  Vue.use(Vue2TouchEvents);
}
