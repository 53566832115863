import { IEnvironmentVariables } from '@/view-models';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import axios, { AxiosInstance } from 'axios';
import {
  authRequestInterceptor,
  authResponseFulfilledInterceptor,
  authResponseRejectedInterceptor
} from './auth';

let cachedInstance: NuxtAxiosInstance;
let emaInstance: AxiosInstance;
let assetEditorInstance: AxiosInstance;
let customerAssetReportingInstance: AxiosInstance;
let localInstance: AxiosInstance;

export function initializeAxios(axiosInstance: NuxtAxiosInstance) {
  addInterceptors(axiosInstance);
  cachedInstance = axiosInstance;
}

export function initializeAllAxios(axiosInstance: NuxtAxiosInstance, env: IEnvironmentVariables) {
  // initialize portal api axios
  axiosInstance.defaults.baseURL = env.baseApiUrl;
  initializeAxios(axiosInstance);

  // initialize ema api axios
  emaInstance = axios.create({
    baseURL: env.emaApiUrl,
    timeout: 20000
  });
  addInterceptors(emaInstance);

  // Initialize Asset Editor instance
  assetEditorInstance = axios.create({
    baseURL: env.assetEditorApiUrl,
    timeout: 20000
  });
  addInterceptors(assetEditorInstance);

  // Initialize Customer Asset Reporting instance
  customerAssetReportingInstance = axios.create({
    baseURL: env.carApiUrl,
    timeout: 20000
  });
  addInterceptors(customerAssetReportingInstance);

  // Initialize Local instance
  localInstance = axios.create({
    baseURL: 'https://localhost:5003',
    timeout: 20000
  });
  addInterceptors(localInstance);
}

function addInterceptors<T extends(NuxtAxiosInstance | AxiosInstance)>(axiosInstance: T): T {
  axiosInstance.interceptors.request.use(authRequestInterceptor);
  axiosInstance.interceptors.response.use(
    authResponseFulfilledInterceptor,
    authResponseRejectedInterceptor
  );
  return axiosInstance;
}

export function getAxios() {
  return cachedInstance;
}

export function getEmaAxios() {
  return emaInstance;
}

export function getAeAxios() {
  return assetEditorInstance;
}

export function getCarAxios() {
  return customerAssetReportingInstance;
}

export function getLocalAxios() {
  return localInstance;
}
