import { createModule, mutation } from 'vuex-class-component';

import { IRootStore } from '@/store/types';
import { IEnvironmentVariables } from '@/view-models';
import { updateHighchartsNumberFormat } from '@/i18n/highcharts-number-formats';

const VuexModule = createModule({
  namespaced: 'root',
  strict: false,
  target: 'nuxt'
});

const initLocales = ['en-US'];
const initLocale = (() => {
  const locale = window.localStorage.getItem(LANG_KEY) || window.navigator.language;
  const hasLocale: boolean = initLocales.includes(locale);
  return hasLocale ? (locale || 'en-US') : 'en-US';
})();
export const LANG_KEY = 'language';

export class RootStore extends VuexModule implements IRootStore {
  public env: IEnvironmentVariables = {
    baseApiUrl: 'http://localhost:5001/',
    localizationApiUrl: 'http://localhost:3000/',
    envId: 'DEV',
    authClientID: '',
    authDomain: '',
    authAudience: '',
    authErrorRedirect: {},
    childAppDomain: '',
    entityLogsApiUrl: 'http://localhost:3003/',
    burnerTreeApiUrl: 'http://localhost:4501/',
    emaApiUrl: '',
    carApiUrl: '',
    assetEditorApiUrl: '',
    notificationHubUrl: '',
    uomDashboardCustomerKey: 'deb397a6-10b9-4036-b20b-a34ed7b59f73',
    imperialUnitKey: '',
    documentStoreUrl: 'https://content-share.kesportaldev.com'
  };
  public locale: string = initLocale;
  public locales: string[] = initLocales;
  public pageHeader: string = 'Home';
  public theme: string = 'light-theme';
  public online: boolean = true;
  public isLeftNavLocked: boolean = true;
  public isLeftNavHovered: boolean = false;

  // getter
  get envPrefix(): string {
    if (this.env == null) {
      return 'DEV-ERROR';
    }
    if (
      this.env.envId.toUpperCase() === 'DEV' ||
      this.env.envId.toUpperCase() === 'STG'
    ) {
      return 'PROD';
    }
    return this.env.envId.toLowerCase();
  }

  public get envChildAppDomain(): string {
    if (this.env.childAppDomain.substr(-1) === '/') {
      return this.env.childAppDomain;
    } else {
      return this.env.childAppDomain + '/';
    }
  }

  // mutations

  @mutation
  public setEnv(env: IEnvironmentVariables): void {
    this.env = env;
  }

  @mutation
  public setLocale(locale: string): void {
    this.locale = locale;
    window.localStorage.setItem(LANG_KEY, locale);
  }

  @mutation
  public setOnline(online: boolean): void {
    this.online = online;
  }

  @mutation
  public setPageHeader(header: string): void {
    this.pageHeader = header;
  }

  @mutation
  public setNavHovered(open: boolean): void {
    this.isLeftNavHovered = open;
  }

  @mutation
  public setNavLocked(open: boolean): void {
    this.isLeftNavLocked = open;
  }

  // Watchers
  public $watch = {
    locale(newLocale: string) {
      updateHighchartsNumberFormat(newLocale);
    }
  }
}

export * from '@/store/types';
