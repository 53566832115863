import { Vue } from 'nuxt-property-decorator';
import moment from 'moment';
import HelperMethods from '@/shared/helper-methods';
import { elementId } from '@/shared/components-utils';
import { toRelativeTime } from '@/shared/date-time-utils';

export default {
  install(vue: typeof Vue) {
    vue.filter('roundDecimal', function(value: number, places: number = 2, abbr: boolean = false): string {
      return HelperMethods.roundDecimal(value, places, abbr);
    });

    vue.filter('percentString', function(
      value: number,
      places: number = 2,
      isDecimal: boolean = false,
      symbol: string = '%'
    ): string {
      return `${HelperMethods.getPercentString(value, places, isDecimal)}${symbol}`;
    });

    vue.filter('dateString', function(value: string, formatString: string = 'MM/DD/YY HH:mm'): string {
      const oDate: moment.Moment = moment(value);
      return oDate.format(formatString);
    });

    vue.filter('replacePipe', function(
      value: string,
      replaceWith: string = ' to ',
      replaceWhat: RegExp = /\|/gi
    ): string {
      return HelperMethods.replacePipe(value, replaceWith, replaceWhat);
    });

    vue.filter('shortenBinText', function(
      value: string,
      maxLength: number = 15,
      clip: number = 0,
      reverse: boolean = false,
      separator: string = '...'
    ) {
      return HelperMethods.shortenBinText(value, maxLength, clip, reverse, separator);
    });

    vue.filter('shortenText', function(
      value: string,
      maxLength: number = 15,
      clip: number = 0,
      reverse: boolean = false,
      separator: string = '...'
    ) {
      return HelperMethods.shortenText(value, maxLength, clip, reverse, separator);
    });

    vue.filter('elementID', elementId);
    vue.filter('relative', toRelativeTime);
  }
};
