




















// Libraries
import { Component, Prop, Emit } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
// Services
// Stores

@Component({
  name: 'password-reset-modal'
})
export default class PasswordResetModal extends BaseComponent {
  //   // VUE.JS Props
  @Prop({ required: true })
  public passwordRequestModal: boolean;

  @Prop({ required: true })
  public email: string;

  //   // VUEX
  //   // Properties
  //   // Fields
  //   // Getters
  private get modalShown(): boolean {
    return this.passwordRequestModal;
  }
  private set modalShown(isModalShown: boolean) {
    this.updateValue(isModalShown);
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}

  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
   public submit(event: Event): void {
  	event.preventDefault();
    	this.$nextTick().then(() => {
        this.updateValue(false);
  	  });
  }

  // Helper Methods
  // Event Methods

  // Watchers
  // Emitters
  @Emit('change')
  public updateValue(unused: boolean) {}
}
