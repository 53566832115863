































// Libraries
import { Component, Prop, Emit } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import { ISelectItem } from '@/components/types/select-box';
// Services

@Component({
	name: 'select-dropdown'
})
export default class SelectDropdown<T> extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true, default: '' })
  public value: T;
  @Prop({ required: true, default: '' })
  public options: Array<ISelectItem<T>>;
  @Prop()
  public big: boolean;
  @Prop({ required: true, default: 'ID_NEEDED' })
  public id: string;
  @Prop({ default: false })
  public sorted: boolean;
  @Prop({ required: false, default: '???' })
  private placeholder: string;
  @Prop({ default: false })
  public alignRight: boolean;
  @Prop({ default: false })
  public hasTooltip: boolean;
  @Prop({ default: false })
  public disabled: boolean;
  @Prop({ default: option => option ? option.translationTag ? option.translationTag : option.text : '' })
  public optionTemplate: (option) => string;
  // VUEX
  // Properties
  // Fields

  public get orderedOptions(): Array<ISelectItem<T>> {
  	return this.sorted ? this.options.slice(0).sort(this.optionCompare) : this.options;
  }

  public get selectedDisplay(): string {
    const selectedOption = this.optionMap.get(this.value);
    return selectedOption ? this.optionDisplay(selectedOption) : this.placeholder;
  }

  public get hoverTitle(): string|null {
    return (this.hasTooltip && this.selectedDisplay) ? this.selectedDisplay : null;
  }

  private get optionMap() {
    const map = new Map<T, ISelectItem<T>>();
    for (const option of this.options) {
      map.set(option.value, option);
    }
    return map;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // created(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private optionDisplay(option: ISelectItem<T>): string {
    if (this.optionTemplate) {
      return this.optionTemplate(option);
    }
    return option.text || this.tOr(option.translationTag, (option.value || '').toString()).toString();
  }

  private optionCompare(option1: ISelectItem<T>, option2: ISelectItem<T>) {
  	const display1 = this.optionDisplay(option1);
  	const display2 = this.optionDisplay(option2);
  	return display1.localeCompare(display2);
  }

  private itemClicked(unusedEvent, option) {
  	this.onValueChange(option.value);
  }

  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
  @Emit('input')
  public onValueChange(unused: T): void {}

  @Emit('shown')
  public shown(unused: T): void {}

  @Emit('hidden')
  public hidden(unused: T): void {}
}
