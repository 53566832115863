import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IAssignmentTreeService } from '@/view-models';
import { IAssetListItemViewModel } from '@/view-models/assets';
import { EntityType } from '@/enums';
import { IAssignmentTreeNodeViewModel } from '@/view-models/report-variables-tree/report-variable-node';

export default class AssignmentTreeService extends BaseService implements IAssignmentTreeService {
  constructor(axios: AxiosInstance) {
    super('assignmentTree', axios, '', 'users/current');
  }

  public getDashboardAssignmentsTree(): Promise<Array<IAssignmentTreeNodeViewModel>> {
    return this.httpClient.get<Array<IAssignmentTreeNodeViewModel>>();
  }

  public async getAssignmentsTree(onlyForActiveCustomer: boolean = true): Promise<Array<IAssetListItemViewModel>> {
    let items = await this.httpClient.get<Array<IAssetListItemViewModel>>('', { onlyForActiveCustomer });

    const copied = items;
    const location = {
      lat: 0.0002,
      lng: 0.0002
    };

    items = items.map((item) => {
      if (item.entityType === EntityType.Tower) {
        const site = findSite(item, copied);
        if (site?.location?.lat && site.location?.lng) {
          item.location = {
            lat: site.location.lat + location.lat,
            lng: site.location.lng + location.lng
          };
          location.lat += 0.0002;
          location.lng += 0.0002;
        }
      }
      return item;
    });

    return items;
  }
}

function findSite(leaf: IAssetListItemViewModel, pool: IAssetListItemViewModel[]): IAssetListItemViewModel {
  let parent: IAssetListItemViewModel = leaf;
  let site: IAssetListItemViewModel = null;
  while (site == null) {
    const found = pool.find(item => item.key === parent.parentKey);
    if (found.entityType === EntityType.CustomerSite) {
      site = found;
    } else {
      parent = found;
    }
  }

  return site;
}
