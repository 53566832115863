import { render, staticRenderFns } from "./autherror.vue?vue&type=template&id=216073cc&scoped=true&"
import script from "./autherror.vue?vue&type=script&lang=ts&"
export * from "./autherror.vue?vue&type=script&lang=ts&"
import style0 from "./autherror.vue?vue&type=style&index=0&id=216073cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216073cc",
  null
  
)

export default component.exports