const middleware = {}

middleware['auth-access-check'] = require('../middleware/auth-access-check.ts')
middleware['auth-access-check'] = middleware['auth-access-check'].default || middleware['auth-access-check']

middleware['check-i18n-route-locale'] = require('../middleware/check-i18n-route-locale.ts')
middleware['check-i18n-route-locale'] = middleware['check-i18n-route-locale'].default || middleware['check-i18n-route-locale']

middleware['permission-check'] = require('../middleware/permission-check.ts')
middleware['permission-check'] = middleware['permission-check'].default || middleware['permission-check']

export default middleware
