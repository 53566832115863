import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IVariableDataRequest, IVariableDataResponse, IVariableDataService, IVariableDetailsResponseViewModel, IVariableLatestDataRequest } from '@/view-models';
import { AccessSettingsEnum } from '@/enums/variables';

export default class VariableDataService extends BaseService implements IVariableDataService {
  constructor(axios: AxiosInstance) {
    super('variables', axios, '');
  }

  /**
   * Get chart data for requested variables within supplied date range
   * @param variableDataRequest IVariableDataRequest
   * @returns {Promise<Array<IVariableDataResponse>>}
   */
   public getVariablesData(variableDataRequest: IVariableDataRequest): Promise<Array<IVariableDataResponse>> {
    const headers = { 'api-version': '2.0' };
    return this.httpClient.post<Array<IVariableDataResponse>>(`data?accessSetting=${AccessSettingsEnum.Dashboards}`, variableDataRequest, null, headers);
  }

  /**
   * Get latest data points for requested variables
   * @param variableLatestDataRequest IVariableLatestDataRequest
   * @returns {Promise<Array<IVariableDataResponse>>}
   */
  public getVariablesDataLatestValue(variableLatestDataRequest: IVariableLatestDataRequest): Promise<Array<IVariableDataResponse>> {
    const headers = { 'api-version': '2.0' };
    return this.httpClient.post<Array<IVariableDataResponse>>(`data/latest?accessSetting=${AccessSettingsEnum.Dashboards}`, variableLatestDataRequest, null, headers);
  }

  /**
   * Get details for requested variables
   * @param variableKeys string[]
   * @returns {Promise<Array<IVariableDetailsResponseViewModel>>}
   */
   public getVariableDetailsByKeys(variableKeys: string[]): Promise<Array<IVariableDetailsResponseViewModel>> {
    return this.httpClient.post<Array<IVariableDetailsResponseViewModel>>('details/fromKeys', variableKeys);
  }
}
