// List of Tower Types
export enum TowerInputTypesEnum {
  Tower = 'Tower',
  TowerEquipment = 'TowerEquipment',
  Validations = 'Validations',
  TowerStageInput = 'TowerStageInput'
}
// List of all equipment types
// All and None are additional utility types that support functionality in the UI
export enum TowerEquipmentTypeEnum {
  ChimneyCollector = 'ChimneyCollector',
  PartingBox = 'PartingBox',
  PackingBed = 'PackingBed',
  Tray = 'Tray',
  SumpTray = 'SumpTray',
  TroughDistributor = 'TroughDistributor',
  Downcomer = 'Downcomer',
  Panel = 'Panel',
  None = 'None',
  AllEquipment = 'AllEquipment'
}
// List of all equipment names
export enum TowerEquipmentNameEnum {
  DownComerSide = 'DC Side',
  DownComerCenter = 'DC Center',
  DowncomerDCOffCenterToS = 'DC Off Center To S',
  DowncomerDCOffCenterToC = 'DC Off Center To C',
  DowncomerDCOffSideToS = 'DC Off Side To S',
  DowncomerDCOffSideToC = 'DC Off Side To C',
  PanelA = 'Panel A',
  PanelB = 'Panel B',
  PanelC = 'Panel C',
  PackingBedFlexipacHC250Y = 'Packing Bed - FLEXIPAC HC250Y',
  PackingBedFlexipacHCp5Y = 'Packing Bed - FLEXIPAC HC2.5Y',
  PackingBedFlexipac2p5X = 'Packing Bed - FLEXIPAC 2.5X',
  PackingBedFlexipac3X = 'Packing Bed - FLEXIPAC 3X',
  // eslint-disable-next-line camelcase
  PackingBedFlexigrid2_45 = 'Packing Bed - FLEXIGRID 2_45',
  // Used for thresholds
  Inputs = 'Inputs'

}
// List of all equipment indicators
export enum EquipmentIndicatorDisplayValuesEnum {
  AerationFlood = 'AerationFlood', // DowncomerAerationFlood
  ExitVelocity = 'ExitVelocity', // DowncomerExitVelocity
  Flood = 'Flood', // DowncomerFlood
  ResidenceTime = 'ResidenceTime', // DowncomerResidenceTime
  DowncomerAerationFloodAverage = 'DowncomerAerationFloodAverage',
  DowncomerAerationFloodMax = 'DowncomerAerationFloodMax',
  DowncomerFloodAverage = 'DowncomerFloodAverage',
  DowncomerFloodMax = 'DowncomerFloodMax',
  DP = 'DP',
  Entrainment = 'Entrainment',
  FFactor = 'FFactor',
  CFactor = 'CFactor',
  FloodMax = 'FloodMax',
  FloodConstantLiquid = 'FloodConstantLiquid',
  FloodConstantLiquidVapor = 'FloodConstantLiquidVapor',
  JetFlood = 'JetFlood',
  JetFloodAverage = 'JetFloodAverage',
  JetFloodMax = 'JetFloodMax',
  LiquidHeight = 'LiquidHeight',
  LiquidHoldup = 'LiquidHoldup',
  LiquidLoad = 'LiquidLoad',
  PctApproachToOverflow = 'PctApproachToOverflow',
  TotalDp = 'TotalDp',
  TotalDpvsh = 'TotalDpvsh',
  Weepage = 'Weepage',
  WeirLoadSub = 'WeirLoadSub',
  WeirLoadTop = 'WeirLoadTop'
}

// List of all stage input indicators
export enum StageInputIndicatorDisplayValuesEnum {
  LiquidMassRate = 'LiquidMassRate',
  LiquidSurfaceTension = 'LiquidSurfaceTension',
  LiquidViscosity = 'LiquidViscosity',
  LiquidDensity = 'LiquidDensity',
  VaporDensity = 'VaporDensity',
  VaporMassRate = 'VaporMassRate',
  VaporViscosity = 'VaporViscosity'
}

// List of all tower indicators
export enum TowerIndicatorDisplayValuesEnum {
  TotalDP = 'TotalDP',
}

// List of all validation display names
export enum TowerValidationsDisplayValuesEnum {
  Actual = 'Actual',
  Simulated = 'Simulated'
}

// List of all units of measurement
export enum TowerUnitOfMeasurementsEnum {
  none = 'unitless',
  empty = '',
  percentage = '%',
  mmHg = 'mmHg',
  celsius = 'degC',
  SiFFactor = 'kg^.5/m^.5/s ', // SiFFactor'
  CubicMetersPerSecond = 'm3/m2/s', // CubicMetersPerSecond
  mbarM = 'mbar/m',
  meters = 'm',
  velocity = 'm/s', // velocity
  seconds = 's',
  cubicMeters = 'm3/hr/m', // cubicMeters
  millimeters = 'mm',
  kilogramsPerCubicMeter = 'kg/m^3',
  kilogramsPerSecond = 'kg/s',
  newtonsPerMeter = 'N/m',
  pascalSeconds = 'Pa-s',
  barrelsPerDay = 'bpd',
  millionStandardCubicFeetPerDay = 'MMSCFD',
  barrelsPerHour = 'bbl/hr',
  kiloBarrelsPerDay = 'kbpd',
  degreesFarenheit = 'degF',
  psia = 'psia',
  poundsPerSquareInchGauge = 'psig'
}
// Options for the dropdown on the stage summary page: _stageKey.vue */
export enum TowerStageFilterOptionEnum {
  All = 'All',
  Alerts = 'Alerts'
}
// List of all modal types within tower view
export enum TowerModalTypesEnum {
  Fullscreen = 'Fullscreen',
  ChartSettings = 'ChartSettings',
  Thresholds = 'Thresholds',
  Validations = 'Validations'
}

// List of all status types
export enum TowerStatusIndicatorEnum {
  Success = 'Success',
  Danger = 'Danger',
  None = 'None'
}

export enum TowerDataSetNameEnum {
  MeasuredTotalDpvsh = 'MeasuredTotalDpvsh',
  Actual = 'Actual'
}

// List of all threshold range types
export enum TowerThresholdRangesEnum {
  OutsideRange = 'OutsideRange',
  InsideRange = 'InsideRange',
  LessThanValue = 'LessThanValue',
  GreaterThanValue = 'GreaterThanValue',
}

export enum TowerThresholdTypeEnum {
  Range = 'Range',
  Value = 'Value'
}
