import Vue from 'vue';
import Highcharts, { LangOptions, TimeOptions } from 'highcharts';
import More from 'highcharts/highcharts-more';
import Highcharts3d from 'highcharts/highcharts-3d';
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import Gantt from 'highcharts/modules/gantt';
import solidGaugeInit from 'highcharts/modules/solid-gauge';
import bulletGaugeInit from 'highcharts/modules/bullet';
import noData from 'highcharts/modules/no-data-to-display';

More(Highcharts);
Highcharts3d(Highcharts);
stockInit(Highcharts);
Gantt(Highcharts);
noData(Highcharts);
solidGaugeInit(Highcharts);
bulletGaugeInit(Highcharts);

Highcharts.setOptions({
  time: {
    timezoneOffset: new Date().getTimezoneOffset()
  } as TimeOptions,
  lang: {
      decimalPoint: '.',
      thousandsSep: ','
    } as LangOptions
});

export default function<Nuxt, Plugin>(): void {
	Vue.use(HighchartsVue, {
		highcharts: Highcharts
	});
}
