import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { ICopyDashboardViewModel, IDashboardConfigViewModel, IDashboardViewModel } from '@/view-models/dashboard-view-models';
import { IDashboardService } from '@/view-models/service-interfaces';

export default class DashboardService extends BaseService implements IDashboardService {
  private _assetKey: string = null;
  private _customerKey: string = null;

  constructor(axios: AxiosInstance, customerKey: string, parentKey: string) {
    super('dv/dashboards', axios, '');
    this._customerKey = customerKey;
    this._assetKey = parentKey;
  }

  /**
   * Get dashboard config
   * @returns {Promise<IDashboardConfigViewModel>}
   */
   public getDashboardConfig(): Promise<IDashboardConfigViewModel> {
    return this.httpClient.get<IDashboardConfigViewModel>(`${this._customerKey}/asset/${this._assetKey}`);
  }

  /**
   * Save dashboard config
   * @param dashboardConfig IDashboardConfigViewModel
   * @returns {Promise<IDashboardConfigViewModel>}
   */
   public saveDashboardConfig(dashboardConfig: IDashboardConfigViewModel): Promise<IDashboardConfigViewModel> {
    return this.httpClient.put<IDashboardConfigViewModel>(`${this._customerKey}/asset/${this._assetKey}`, dashboardConfig);
  }

  /**
   * Get dashboards by keys
   * @param dashboardKeys string[]
   * @returns {Promise<Array<IDashboardViewModel>>}
   */
   public getDashboards(dashboardKeys: string[]): Promise<Array<IDashboardViewModel>> {
    return this.httpClient.post<Array<IDashboardViewModel>>(`${this._customerKey}/fromKeys`, dashboardKeys);
  }

  /**
   * Create dashboard
   * @param dashboard IDashboardViewModel
   * @returns {Promise<IDashboardViewModel>}
   */
   public createDashboard(dashboard: IDashboardViewModel): Promise<IDashboardViewModel> {
    return this.httpClient.post<IDashboardViewModel>(`${this._customerKey}/asset/${this._assetKey}/createDashboard`, dashboard);
  }

  /**
   * Save dashboard
   * @param dashboardKey string
   * @param dashboard IDashboardViewModel
   * @returns {Promise<IDashboardViewModel>}
   */
   public saveDashboard(dashboard: IDashboardViewModel): Promise<IDashboardViewModel> {
    return this.httpClient.put<IDashboardViewModel>(`${this._customerKey}/${dashboard.key}`, dashboard);
  }

  /**
   * Remove dashboard
   * @param dashboardKey string
   * @returns {Promise<string>}
   */
   public removeDashboard(dashboardKey: string): Promise<string> {
    return this.httpClient.delete<string>(`${this._customerKey}/${dashboardKey}`);
  }
  /**
   * Copy dashboard
   * @param dashboardKey string
   * @returns {Promise<string[]>} list of variable names that failed to copy
   */
   public copyDashboard(copyRequest: ICopyDashboardViewModel): Promise<string[]> {
    const url = `${this._customerKey}/asset/${this._assetKey}/copyDashboard`;
    return this.httpClient.post<string[]>(url, copyRequest);
  }
}
