export enum CameraProperty{
    Environment = 'environment',
    Playsinline = 'playsinline',
    Canvas = 'canvas',
    Image = 'image',
    Video = 'video',
    Play = 'play',
    Pause = 'pause'
  }

  export enum CameraViews {
    Gallery = 'Gallery',
    PreviewToGallery = 'PreviewToGallery',
    Camera = 'Camera',
    Preview = 'Preview',
    Editor = 'Editor',
    Video = 'Video',
    VideoPreview = 'VideoPreview'
  }

  export enum Thumbnails {
    removed = 'removed',
    prevSelected = 'prevSelected',
    selected = 'selected'
  }

export interface IImageCache{
    key:string;
    source:string;
}

export interface ITaskLogUploadImageApiDto{
    imageKey:string;
    preSignedUrl:string;
    s3Path:string;
  }

  export interface IDownloadImageRequest {
    imageKey: string;
    key?: string;
    assetKey?: string;
  }

  export interface ILogImageViewModel{
    thumbnail:string;
    imageKey:string;
    mediaKey?:string;
    title:string;
    isVideo:boolean;
    // For internal use.
    isSelected:boolean;
    isRemoved:boolean;
    isPrevSelected:boolean;
    imageSource:string;
    s3Path:string;
    description?: string;
  }

  export interface IBurnerCameraState {
    galleryThumbnail: Array<ILogImageViewModel>;
    selectedImages: Array<ILogImageViewModel>;
    burnerKey: string;
    assetLogFlag: boolean;
    imageSource: string;
    imageCache: Array<IImageCache>;
}
