import 'es6-promise/auto';
import { AxiosResponse, AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IGetLogsRequest, ITaskLogViewModel } from '@/view-models/assets';
import { ILogsService } from '@/view-models';
import { IDownloadImageRequest, ITaskLogUploadImageApiDto } from '@/enums';

export default class LogsService extends BaseService implements ILogsService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('log', axios, 'api', baseUrl);
  }

  /**
   * Returns logs
   * @returns {Promise<Array<ITaskLogViewModel>>}
   * @param request:IGetLogsRequest
   */
  public async getLogs(request: IGetLogsRequest): Promise<Array<ITaskLogViewModel>> {
    const response: AxiosResponse<Array<ITaskLogViewModel>> = await this.http.get(
    this.getUrl(`${request.parentEntityKey}/entity/${request.entityKey}/logs?currentPage=${request.currentPage}&perPage=${request.perPage}`)
    );
    return response.data;
  }

  /**
   * Update task log
   * @returns {Promise<Array<ITaskLogViewModel>>}
   * @param log: IBurnerLogsViewModel
   */
  public async updateTaskLog(log:ITaskLogViewModel): Promise<ITaskLogViewModel> {
    const response: AxiosResponse<ITaskLogViewModel> = await this.http.put(
      this.getUrl(''), log
    );
    return response.data;
  }

   /**
   * Add logs and it returns relevant task logs.
   * @returns {Promise<Array<IBurnerLogsViewModel>>}
   * @param log: IBurnerLogsViewModel
   */
  public async addTaskLog(log:ITaskLogViewModel): Promise<ITaskLogViewModel> {
    const response: AxiosResponse<ITaskLogViewModel> = await this.http.post(
      this.getUrl(''), log
    );
    return response.data;
  }

  /**
   * Delete Task log.
   * @param request: IDeleteLogsRequest
   */
  public async deleteTaskLog(request:ITaskLogViewModel): Promise<void> {
    await this.http.delete(this.getUrl(`entity/${request.entityKey}/log/${request.logKey}`));
  }

  /**
   * Uploads image and it returns image.
   * @returns {Promise<IBurnerLogImageViewModel>}
   * @param base64string: string
   */
  public async uploadImage(entityKey:string): Promise<ITaskLogUploadImageApiDto> {
    const uploadData: ITaskLogUploadImageApiDto = await this.http
      .get(this.getUrl(`entity/${entityKey}/uploadmedia`))
      .then((response: AxiosResponse<ITaskLogUploadImageApiDto>) => response.data);
    return uploadData;
  }

  /**
   * Downloads image as base64string.
   * @returns base64string string
   * @param request: IDownloadImageRequest
   */
  public async downloadImage(request:IDownloadImageRequest): Promise<string> {
    const resultData:string = await this.http
      .get(this.getUrl(`entity/${request.key}/media/${request.imageKey}/download`))
      .then((response: AxiosResponse<string>) => response.data);
    return resultData;
  }

  public async getAssetLogs(request: any): Promise<Array<ITaskLogViewModel>> {
    const response: AxiosResponse<Array<ITaskLogViewModel>> = await this.http.get(
    this.getUrl(`${request.assetKey}/entity/${request.assetKey}/logs?currentPage=${request.currentPage}&perPage=${request.perPage}&FromDate=${request.fromDate}&ToDate=${request.endDate}`)
    );
    return response.data;
  }
}
