import { createModule, mutation, action } from 'vuex-class-component';
import HelperMethods from '@/shared/helper-methods';
import EventBus, { Events } from '@/shared/event-bus';
import {
  IUserViewModel,
  IUserFavoriteViewModel,
  IRecentViewModel,
  User,
  IUser,
  IAuthService,
  IUserManagementService,
  IUserService,
  Permission,
  IEnvironmentVariables,
  IOrganization,
  IFavoriteTileItemViewModel
} from '@/view-models';
import { IAssignedCustomer, IAssetListItemViewModel } from '@/view-models/assets';
import { AuthService, UserService, UserManagementService } from '@/services';

import { ISetUserPayload, IUserStore } from '@/store/types';
import { getAxios } from '@/shared/http';
import { numericSorter } from '@/shared/array-utils';
import { EntityType, MeasurementSystemsEnum } from '@/enums';
import { authData } from '@/shared/auth';

const VuexModule = createModule({
  namespaced: 'user',
  strict: false,
  target: 'nuxt'
});

export const refreshTimeoutMinutes: number = 10;

export class UserStore extends VuexModule implements IUserStore {
  private get authService(): IAuthService {
    return new AuthService();
  }

  private get userManagementService(): IUserManagementService {
    return new UserManagementService(getAxios(), this.env.baseApiUrl);
  }

  private get userService(): IUserService {
    return new UserService(getAxios());
  }

  // State
  public firstTimeUser: boolean = false;
  public authUser: IUser = null;
  public authUserOrg: IOrganization = null;
  public favorites: Array<IUserFavoriteViewModel> = [];
  public favoriteTiles: Array<IFavoriteTileItemViewModel> = [];
  public loadingFavoriteTiles: boolean = false;
  public availableCustomers: Array<IAssignedCustomer> = [];
  public fetchingCustomers: boolean = false;
  public assignmentItems: Array<IAssetListItemViewModel> = [];
  public loading: boolean = false;
  public loadingRecents: boolean = false;
  public recents: Array<IRecentViewModel> = [];
  public permissions: string[] = [];
  public currentMeasurementSystem: string = MeasurementSystemsEnum.DefaultUnits;
  public uomOptions: string[] = Object.assign([], HelperMethods.enumKeys(MeasurementSystemsEnum));

  public env: IEnvironmentVariables = {
    baseApiUrl: '',
    localizationApiUrl: 'http://localhost:3000/',
    envId: 'DEV',
    authClientID: '',
    authDomain: '',
    authAudience: '',
    authErrorRedirect: {},
    childAppDomain: '',
    entityLogsApiUrl: 'http://localhost:3003/',
    burnerTreeApiUrl: 'http://localhost:4501/',
    emaApiUrl: '',
    carApiUrl: '',
    assetEditorApiUrl: '',
    notificationHubUrl: '',
    uomDashboardCustomerKey: 'deb397a6-10b9-4036-b20b-a34ed7b59f73',
    imperialUnitKey: '',
    documentStoreUrl: 'https://content-share.kesportaldev.com'
  };

  // Getters
  public get isFirstTimeUser(): boolean {
    return this.firstTimeUser;
  }
  public get hasPermission() {
    return (permission: string): boolean => {
      return this.permissions.includes(permission);
    };
  }
  public get customerAssetDetails() {
    return (assetInfo?: IAssetListItemViewModel[], assetKey?: string) => {
      const customerDetails = this.availableCustomers.find(item => item.key === this.authUser.activeCustomerKey);
      let assetDetails: IAssetListItemViewModel = {} as IAssetListItemViewModel;
      if (assetKey) {
        assetDetails = assetInfo.find(item => item.key === assetKey);
      }
      return JSON.stringify({
        customerId: customerDetails?.key ? customerDetails.key : '',
        customerName: customerDetails?.name ? customerDetails.name : '',
        assetId: assetDetails?.key ? assetDetails.key : '',
        assetName: assetDetails?.name ? assetDetails.name : ''
      });
    };
  }
  public get isSuperUser() {
    return this.authUser && this.authUser.isSuperUser;
  }
  public get customersInTiles(): string[] {
    return [...new Set(this.favoriteTiles.map(tile => tile.orgKey))];
  }
  public get sortedFavTiles(): Array<IFavoriteTileItemViewModel> {
    const tiles = this.favoriteTiles.map(t => Object.assign({}, t)).slice();
    return tiles.sort(numericSorter(t => t.sortOrder));
  }

  public get hasAssets() {
    return (): boolean => {
      return this.authUser.userAssignments?.length > 0;
    };
  }

  // Mutations
  @mutation
  public setCurrentMeasurementSystem(option: MeasurementSystemsEnum): void {
    this.currentMeasurementSystem = option;
  }
  @mutation
  public setUser({ user, org }: ISetUserPayload): void {
    cachedAuthUser = new User(user);
    this.authUser = new User(user);
    this.authUserOrg = org;
    EventBus.$emit(Events.USER_ACTIVE_CUSTOMER_CHANGED, this.authUser.activeCustomerKey);
  }
  @mutation
  public setFavorites(faves: IUserFavoriteViewModel[]): void {
    this.favorites = faves;
  }
  @mutation
  public deleteFavoriteTile(key: string) {
    this.favoriteTiles = this.favoriteTiles.filter(f => f.key !== key);
  }
  @mutation
  public setFavoriteTiles(tiles: IFavoriteTileItemViewModel[]): void {
    this.favoriteTiles = tiles;
  }
  @mutation
  public setEnv(env: IEnvironmentVariables): void {
    this.env = env;
  }
  @mutation
  public setRecents(recents: IRecentViewModel[]): void {
    this.recents = recents;
  }
  @mutation
  public updateFavoriteTilesSilently(tiles: IFavoriteTileItemViewModel[]) {
    /*
      update each existing tile individually
      & add any new tiles that come in
    */
    tiles.forEach((val) => {
      const index = this.favoriteTiles.findIndex(tile => tile.key === val.key);
      if (index > -1) {
        this.favoriteTiles.splice(index, 1, val);
      } else {
        this.favoriteTiles.splice(0, 0, val);
      }
    });

    // Remove any tiles from state that aren't included in new set of tiles
    this.favoriteTiles.forEach((tile, index) => {
      if (!tiles.some(found => found.key === tile.key)) {
        this.favoriteTiles.splice(index, 1);
      }
    });
  }
  @mutation
  public setLoadingFavoriteTiles(loading: boolean): void {
    this.loadingFavoriteTiles = loading;
  }
  @mutation
  public setLoadingRecents(loading: boolean): void {
    this.loadingRecents = loading;
  }
  @mutation
  public deleteFavorite(item: IUserFavoriteViewModel) {
    const filteredFavorites = this.favorites.filter((e) => {
      return e.favoritedEntityKey !== item.favoritedEntityKey;
    });
    this.favorites = filteredFavorites;
  }
  @mutation
  public addFavorite(item: IUserFavoriteViewModel) {
    this.favorites.push(item);
  }
  @mutation
  public setCustomers(customers: IAssignedCustomer[]): void {
    this.availableCustomers = customers;
  }
  @mutation
  public changeActiveCustomer(customerKey: string): void {
    if (this.authUser != null) {
      this.authUser.activeCustomerKey = customerKey;
      authData.user.activeCustomerKey = customerKey;
      EventBus.$emit(Events.USER_ACTIVE_CUSTOMER_CHANGED, customerKey);
    }
  }
  @mutation
  public setFetchingCustomersStatus(): void {
    this.fetchingCustomers = !this.fetchingCustomers;
  }
  @mutation
  public setPermissions(permissions: string[]) {
    this.permissions = permissions;
  }
  @mutation
  public setFirstTimeUser(isFirstTimeUser: boolean): void {
    this.firstTimeUser = isFirstTimeUser;
  }

  // Actions
  @action
  public async getUser(): Promise<IUserViewModel> {
    const user: IUserViewModel = await this.userManagementService.getUser();
    const org: IOrganization = {
      key: user.orgKey,
      name: user.orgName
    };

    this.setUser({ user, org });

    if (!user.permissions || !this.hasAssets || !user.permissions?.length) {
      this.setFirstTimeUser(true);
    } else {
      this.setFirstTimeUser(false);
    }
    return user;
  }

  @action
  public async getPermissions(): Promise<void> {
    const permissions: string[] = await this.userManagementService.getPermissions();
    this.setPermissions(permissions);
  }

  @action
  public logOut(): Promise<void> {
    try {
      this.authService.logOut();
    } catch (error) {
      HelperMethods.catch(getAxios(), error);
    }
    return Promise.resolve();
  }

  @action
  public async changePassword(request: IUserViewModel): Promise<IUserViewModel> {
    await this.userManagementService.changePassword(request);
    return request;
  }

  @action
  public async silentlyUpdateFavoriteTiles(): Promise<void> {
    let tiles: IFavoriteTileItemViewModel[] = await this.userService.getFavoritesTiles();
    if (!this.hasPermission(Permission.ReadTowers)) {
      tiles = tiles.filter(tile => tile.entityType !== EntityType.Tower);
    }
    this.updateFavoriteTilesSilently(tiles);
  }

  @action
  public async fetchFavorites(alsoTiles: boolean = true): Promise<void> {
    this.setLoadingFavoriteTiles(true);
    let tiles: IFavoriteTileItemViewModel[] = await this.userService.getFavoritesTiles();
    if (!this.hasPermission(Permission.ReadTowers)) {
      tiles = tiles.filter((tile: IFavoriteTileItemViewModel) => tile.entityType !== EntityType.Tower);
    }

    const faves: IUserFavoriteViewModel[] = tiles.map(tile => ({
        key: tile.favoriteKey,
        orgKey: tile.orgKey,
        name: tile.name,
        sortOrder: tile.sortOrder,
        favoritedEntityKey: tile.key,
        favoritedEntityType: tile.entityType
    }));

    this.setFavorites(faves);
    if (alsoTiles) {
      this.setFavoriteTiles(tiles);
    }
    this.setLoadingFavoriteTiles(false);
  }

  @action
  public async fetchRecents(): Promise<void> {
    this.setLoadingRecents(true);
    const recents = await this.userService.getRecents();
    this.setRecents(recents);
    this.setLoadingRecents(false);
  }
  @action
  public async addRecent(recent: IRecentViewModel): Promise<void> {
    await this.userService.addRecent(recent);
  }
  @action
  public async toggleFavorite(item: IUserFavoriteViewModel) {
    const found = this.favorites.find(a => a.favoritedEntityKey === item.favoritedEntityKey && a.favoritedEntityType === item.favoritedEntityType);
    if (found === undefined) {
      await this.userService.addFavorite(item);
      this.addFavorite(item);
    } else {
      await this.userService.deleteFavorite(item);
      this.deleteFavorite(item);
      this.deleteFavoriteTile(item.favoritedEntityKey);
    }
  }

  @action
  public async updateActiveCustomer(customerKey: string) {
    this.setFetchingCustomersStatus();
    await this.userManagementService.changeActiveCustomer(customerKey);
    this.changeActiveCustomer(customerKey);
    this.setFetchingCustomersStatus();
  }

  @action
  public async fetchAvailableCustomers() {
    this.setFetchingCustomersStatus();
    const customers = await this.userManagementService.getAssignedCustomers();
    this.setCustomers(customers || []);
    this.setFetchingCustomersStatus();
  }

  @action
  public dropItemAtPosition(request: IUserFavoriteViewModel): Promise<void> {
    return this.userService.updateSortOrder(request);
  }

  @action
  public async updateAuthUserLoginStatus(): Promise<void> {
    const updated = await this.userManagementService.updateCurrentUserLoginStatus();
    const org: IOrganization = {
      key: updated.orgKey,
      name: updated.orgName
    };

    this.setUser({ user: updated, org });
  }
}

// This is so we can use the current user in other stores
let cachedAuthUser: IUser = null;
export function getAuthUser() {
  return cachedAuthUser;
}
