import { Vue } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import { Route } from 'vue-router';
import HelperMethods from '@/shared/helper-methods';

export enum ChildAppRoutesEnum {
  AssetDiagramBuilderBasePath = '/utilities/ember/layout-editor/',
  AssetDiagramViewerBasePath = '/assets/heater/{key}/layout',
  PortalAssetHomeBasePath = '/assets/heater/{key}/',
  CustomerAssetEditorBasePath = '/utilities/ember/asset-editor/',
  CustomerAssetServicesBasePath = '/utilities/ember/asset-editor/asset-services',
  // HydraulicModelBuilderBasePath = '/utilities/ember/hydraulic-modeler/',
  PlantConnectorBasePath = '/admin/plant-connector/',
  UserManagementBasePath = '/admin/user-management/',
  UserManagementRoleBasePath = '/admin/user-management/role/',
  HierarchyBuilderBasePath = '/utilities/ember/hierarchy-builder/',
  // CustomerAssetReportingBasePath = '/reports/',
  VariableEditorBasePath = '/utilities/ember/variable-editor/',
  OrganizationManagementBasePath = '/admin/organization-management',
  TowerViewBasePath = '/assets/tower/{key}/stages',
  AssetsBasePath = '/assets/'
}

export default function<Nuxt, Plugin>({ app, params }: Context): void {
  Vue.config.ignoredElements = [
    'asset-diagram',
    'asset-diagram-viewer',
    'asset-diagram-builder',
    'customer-asset-editor',
    'customer-asset-reporting',
    'hierarchy-builder',
    // 'hydraulic-model-builder',
    'organization-management',
    'plant-connector',
    'portal-asset-home',
    'product-logging',
    'user-management',
    'variable-editor',
    'tower-stages'
  ];

  app.router.afterEach((to: Route) => {
    if (
      to.path.includes(ChildAppRoutesEnum.AssetDiagramBuilderBasePath) ||
      to.path.includes(ChildAppRoutesEnum.CustomerAssetEditorBasePath) ||
      // to.path.includes(ChildAppRoutesEnum.CustomerAssetReportingBasePath) ||
      // to.path.includes(ChildAppRoutesEnum.HydraulicModelBuilderBasePath) ||
      to.path.includes(ChildAppRoutesEnum.PlantConnectorBasePath) ||
      to.path.includes(ChildAppRoutesEnum.UserManagementBasePath) ||
      to.path.includes(ChildAppRoutesEnum.OrganizationManagementBasePath) ||
      to.path.includes(ChildAppRoutesEnum.UserManagementRoleBasePath) ||
      to.path.includes(ChildAppRoutesEnum.VariableEditorBasePath) ||
      to.path.includes(ChildAppRoutesEnum.HierarchyBuilderBasePath) ||
      to.path.includes(
        ChildAppRoutesEnum.AssetDiagramViewerBasePath.replace(
          '{key}',
          params.key
        )
      ) ||
      to.path.includes(
        ChildAppRoutesEnum.PortalAssetHomeBasePath.replace('{key}', params.key)
      ) ||
      to.path.includes(
        ChildAppRoutesEnum.TowerViewBasePath.replace('{key}', params.key)
      )
    ) {
      HelperMethods.pushParentRouteChangeEvent(to);
    }
  });
}
