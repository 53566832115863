import {
  IHierarchyTreeViewModel,
  IReportVariableNode,
  IReportAssetVariableViewModel,
  ReportVariableNode, IReportVariableFetchInputViewModel, IAssignmentTreeNodeViewModel
} from '@/view-models/report-variables-tree/report-variable-node';
import '@/shared/extensions/array.extensions.ts';
import { createModule, mutation, action } from 'vuex-class-component';
import { AssetHierarchyService } from '@/services/asset-hierarchy-service';
import { ReportVariableService } from '@/services/report-variable-service';
import { AssignmentNodeType } from '@/enums/assignment-node-type';
import { isStringEmpty } from '@/shared/string-utils';
import { addToLookup } from '@/shared/array-utils';
import AssignmentTreeService from '@/services/assignment-tree-service';
import {
  IFetchNodeLeavesPayload,
  IReportVariablesTreeStore
} from '@/view-models/report-variables-tree/report-variables-tree';
import {
  IAssetHierarchyService,
  IAssignmentTreeService,
  IEnvironmentVariables
} from '@/view-models';
import { getAxios } from '@/shared/http';

const VuexModule = createModule({
  namespaced: 'report-variables-tree',
  strict: false,
  target: 'nuxt'
});

// @Module({ dynamic: true, store, name: 'reports-variables-tree' })
export class ReportVariablesTreeStore extends VuexModule implements IReportVariablesTreeStore {
  public loadingReportVariablesTrees: boolean = false;
  public loadingReportVariableDataNodeLeaves: Array<string> = [];
  public variableTrees: IReportVariableNode[] = [];
  public variableDetails: Array<IReportAssetVariableViewModel> = [];
  public flattenedVariableNodes: IReportVariableNode[] = [];
  public searchedNodeKeys: string[] = [];
  public reportVariablesTreesLoaded: boolean = false;
  public invalidKeys: string[] = [];

  public env: IEnvironmentVariables = {
    baseApiUrl: 'http://localhost:5000/',
    localizationApiUrl: 'http://localhost:3000/',
    envId: 'DEV',
    authClientID: '',
    authDomain: '',
    authAudience: '',
    authErrorRedirect: {},
    childAppDomain: '',
    entityLogsApiUrl: 'http://localhost:3003/',
    burnerTreeApiUrl: 'http://localhost:4501/',
    emaApiUrl: '',
    carApiUrl: '',
    assetEditorApiUrl: '',
    notificationHubUrl: '',
    uomDashboardCustomerKey: 'deb397a6-10b9-4036-b20b-a34ed7b59f73',
    imperialUnitKey: '',
    documentStoreUrl: 'https://content-share.kesportaldev.com'
  };
  constructor() {
    super();
    this.resetState();
  }

  // Getters
  public get variableNameByKey(): (variableKey: string) => string {
    return (variableKey: string) => {
      return this.flattenedVariableNodes.find(n => n.variableKey === variableKey)?.displayName;
    };
  }
  public get variableByKey(): (variableKey: string) => IReportVariableNode {
    return (variableKey: string) => {
      return this.flattenedVariableNodes.find(n => n.variableKey === variableKey);
    };
  }
  private get assignmentTreeService(): IAssignmentTreeService {
    return new AssignmentTreeService(getAxios());
  }

  private get assetHierarchyService(): IAssetHierarchyService {
    return new AssetHierarchyService(getAxios(), this.env.carApiUrl);
  }

  private get reportVariableService(): ReportVariableService {
    return new ReportVariableService(getAxios(), this.env.carApiUrl);
  }

  public get doesContainSearchedNode(): (node: IReportVariableNode) => boolean {
    return (node: IReportVariableNode) => {
      if (this.searchedNodeKeys?.length > 0) {
        return recursiveDoesContainKeys(node, this.searchedNodeKeys);
      }
      return true;
    };
  }

  public get nodeByKey(): (key: string) => IReportVariableNode {
    return (nodeKey: string) => {
      return this.flattenedVariableNodes.find(node => node.key === nodeKey);
    };
  }

  public get isLoadingReportVariablesTrees(): boolean {
    return this.loadingReportVariablesTrees;
  }

  public get isLoadingReportVariableDataNodeLeaves(): (nodeKey: string) => boolean {
    return (nodeKey: string) => {
      const found = this.loadingReportVariableDataNodeLeaves.find(key => key === nodeKey);
      return found !== undefined;
    };
  }

  public get isSearchedNodeInclusive(): (node: IReportVariableNode) => boolean {
    return (node: IReportVariableNode) => {
      return this.searchedNodeKeys?.indexOf(node.key) > -1;
    };
  }
  public get variableIsValid() {
    return (key: string) => {
      return !this.invalidKeys.includes(key);
    };
  }
  @mutation
  public setEnv(env: IEnvironmentVariables): void {
    this.env = env;
  }

  @mutation
  public setLoadingReportVariablesTrees(loading: boolean): void {
    this.loadingReportVariablesTrees = loading;
  }

  @mutation
  public setReportVariablesTreesLoaded(loaded: boolean): void {
    this.reportVariablesTreesLoaded = loaded;
  }

  @mutation
  public setLoadingReportVariableDataNodeLeaves(data: { nodeKey: string; loading: boolean }): void {
    if (data.loading) {
      this.loadingReportVariableDataNodeLeaves.push(data.nodeKey);
    } else {
      this.loadingReportVariableDataNodeLeaves = this.loadingReportVariableDataNodeLeaves.filter(
        nodeKey => nodeKey !== data.nodeKey
      );
    }
  }

  @mutation
  public setReportVariableTrees(nodes: IReportVariableNode[]): void {
    this.variableTrees = nodes;
    this.flattenedVariableNodes.push(...nodes);
  }

  @mutation
  public setVariableDetails(variableDetails: Array<IReportAssetVariableViewModel>): void {
    this.variableDetails = variableDetails;
  }

  @mutation
  public setReportVariableNodeChild(child: IReportVariableNode): void {
    let parentReportVariableNode: IReportVariableNode;

    for (const treeNode of this.variableTrees) {
      parentReportVariableNode = recursiveFindNode(treeNode, child.parentKey);
      if (parentReportVariableNode != null) {
        break;
      }
    }

    if (parentReportVariableNode) {
      child.topDownHierarchyNames.push(...parentReportVariableNode.topDownHierarchyNames, child.name);
      parentReportVariableNode.children.push(child);
      this.flattenedVariableNodes.push(child);
    }
  }

  @mutation
  public setReportVariableNodeChildren(data: { parentNodeKey: string; children: IReportVariableNode[] }): void {
    let parentReportVariableNode: IReportVariableNode;

    for (const treeNode of this.variableTrees) {
      parentReportVariableNode = recursiveFindNode(treeNode, data.parentNodeKey);
      if (parentReportVariableNode != null) {
        break;
      }
    }

    if (parentReportVariableNode) {
      data.children.forEach((child) => {
        child.topDownHierarchyNames.push(...parentReportVariableNode.topDownHierarchyNames, child.name);
      });

      parentReportVariableNode.children = [...data.children];
      this.flattenedVariableNodes.push(...data.children);
    }
  }

  @mutation
  public setSearchedNodeKeys(keys: string[]): void {
    this.searchedNodeKeys = keys;
  }

  @mutation
  public toggleOpenNode(currentNodeKey: string): void {
    const currentVariableNode: IReportVariableNode = findNodeByKey(currentNodeKey, this.variableTrees);
    if (currentVariableNode) {
      currentVariableNode.isOpen = !currentVariableNode.isOpen;
    }
  }

  @mutation
  public setParentNodes(nodeKey: string): void {
    let currentVariableNode: IReportVariableNode = findNodeByKey(nodeKey, this.variableTrees);
    let parentNode: IReportVariableNode;

    do {
      parentNode = findNodeByKey(currentVariableNode.parentKey, this.variableTrees);
      if (parentNode) {
        parentNode.isOpen = true;
      }
      currentVariableNode = parentNode;
    } while (currentVariableNode);
  }
  @mutation
  public resetState(): void {
    this.loadingReportVariablesTrees = false;
    this.loadingReportVariableDataNodeLeaves = [];
    this.variableTrees = [];
    this.variableDetails = [];
    this.flattenedVariableNodes = [];
    this.searchedNodeKeys = [];
    this.reportVariablesTreesLoaded = false;
  }
  @mutation
  public setInvalidKeys(keys: string[]) {
    this.invalidKeys = keys;
  }

  // Actions
  @action
  public async fetchReportVariablesTree(input: IReportVariableFetchInputViewModel): Promise<void> {
    if (!this.loadingReportVariablesTrees && !this.reportVariablesTreesLoaded) {
      this.setLoadingReportVariablesTrees(true);
      this.setReportVariableTrees([]);

      const assignmentTree = await this.assignmentTreeService.getDashboardAssignmentsTree();
      const assetLevelNodes: Array<IReportVariableNode> = [];

      // Build report variable nodes based on assignment tree nodes
      // eslint-disable-next-line prefer-const
      let { topLevelReportVariableNodes, parentKeys, childLevelsLookUp }: { topLevelReportVariableNodes: IReportVariableNode[]; parentKeys: string[]; childLevelsLookUp: Record<string, IReportVariableNode[]>; } =
        buildVariableNodes(assignmentTree, input, assetLevelNodes);

      // set the top level nodes with no parents first
      this.setReportVariableTrees(topLevelReportVariableNodes);

      // Loop through the rest and add as children respective to their parent key
      do {
        const newParentKeys: string[] = [];
        for (const parentKey of parentKeys) {
          const children = childLevelsLookUp?.[parentKey] ?? [];
          this.setReportVariableNodeChildren({
              parentNodeKey: parentKey,
              children
            });
            newParentKeys.push(...children.map(node => node.key));
          }
        parentKeys = newParentKeys;
      } while (parentKeys.length > 0);

      const hierarchyTreesPromises = prepareHierarchyTrees(
        assetLevelNodes,
        input,
        this.assetHierarchyService,
        this.setReportVariableNodeChild,
        this.fetchAssetVariablesNodeLeaves
      );

      await Promise.all(hierarchyTreesPromises);
      this.setLoadingReportVariablesTrees(false);
      this.setReportVariablesTreesLoaded(true);
    }
  }
  @action
  public async fetchAssetVariablesNodeLeaves(payload: IFetchNodeLeavesPayload): Promise<void> {
    payload.dataNodes.forEach((dataNode) => {
      this.setLoadingReportVariableDataNodeLeaves({
        nodeKey: dataNode.key,
        loading: true
      });
    });

    try {
      const variables: Array<IReportAssetVariableViewModel> = await this.reportVariableService.getAssetVariables(payload.assetNode.assetKey);

      this.setVariableDetails(variables);

      const reportVariableNodeChildren = variables.map(
        (variable: IReportAssetVariableViewModel) =>
          new ReportVariableNode({
            key: `${variable.nodeKey}-leaf-${variable.displayName}`,
            variableKey: variable.variableKey,
            dataNodeKey: variable.nodeKey,
            name: variable.displayName,
            topDownHierarchyNames: [],
            displayName: variable.displayName,
            assetKey: payload.assetNode.assetKey,
            parentKey: `${variable.nodeKey}-data-node`,
            customerSiteKey: '',
            isLeaf: true,
            containsReportData: variable.hasData,
            dataRefs: variable.dataRefs,
            reportTableKey: variable.reportTableKey,
            measurementType: variable.measurementType,
            currentMeasurementSystem: payload.currentMeasurementSystem,
            displayValues: variable.displayValues
          })
      );

      const reportVariableNodeChildrenGroup = reportVariableNodeChildren.groupBy('dataNodeKey');
      reportVariableNodeChildrenGroup.forEach((result: IReportVariableNode[], dataNodeKey: string) => {
        const parentDataNodeKey = `${dataNodeKey}-data-node`;

        this.setReportVariableNodeChildren({
          parentNodeKey: parentDataNodeKey,
          children: result
        });
      });
    } finally {
      payload.dataNodes.forEach((dataNode) => {
        this.setLoadingReportVariableDataNodeLeaves({
          nodeKey: dataNode.key,
          loading: false
        });
      });
    }
  }
}

export function findNodeByKey(key: string, trees: IReportVariableNode[]): IReportVariableNode {
  for (const treeNode of trees) {
    const currentVariableNode = recursiveFindNode(treeNode, key);
    if (currentVariableNode != null) {
      return currentVariableNode;
    }
  }
}

function recursiveFindNode(node: IReportVariableNode, currentNodeKey: string): IReportVariableNode {
  if (node.key === currentNodeKey) {
    return node;
  }
  for (let i = 0; i < node.children?.length; i++) {
    const match = recursiveFindNode(node.children[i], currentNodeKey);
    if (match) {
      return match;
    }
  }
}

function recursiveDoesContainKeys(node: IReportVariableNode, keys: string[]): boolean {
  if (keys.includes(node.key)) {
    return true;
  }
  for (const child of node.children) {
    if (recursiveDoesContainKeys(child, keys)) {
      return true;
    }
  }
}
function prepareHierarchyTrees(
  assetLevelNodes: IReportVariableNode[],
  input: IReportVariableFetchInputViewModel,
  assetHierarchyService: IAssetHierarchyService,
  setReportVariableNodeChild: (child: IReportVariableNode) => void,
  fetchAssetVariablesNodeLeaves: (payload: IFetchNodeLeavesPayload) => Promise<void>
) {
  return assetLevelNodes.map(async (assetLevelNode) => {
    const hierarchyTrees = (await assetHierarchyService.getAssetHierarchyTrees(assetLevelNode.key)) ?? [];
    const reportVariableNodesByAsset: IReportVariableNode[] = [];

    hierarchyTrees.forEach((hierarchyTree: IHierarchyTreeViewModel) => {
      // Include all the parent hierarchy tree nodes except ones for asset level
      // Asset level node has already been added by the assignment tree above
      if (hierarchyTree.key !== assetLevelNode.key) {
        const reportVariableNode: IReportVariableNode = new ReportVariableNode({
          key: hierarchyTree.key,
          variableKey: '',
          name: hierarchyTree.name,
          topDownHierarchyNames: [],
          displayName: hierarchyTree.name,
          assetKey: hierarchyTree.assetKey,
          parentKey: hierarchyTree.parentKey,
          customerSiteKey: assetLevelNode.customerSiteKey,
          isLeaf: false,
          isOpen: false,
          children: [],
          currentMeasurementSystem: input.currentMeasurementSystem
        });

        setReportVariableNodeChild(reportVariableNode);
      }

      const nodes = hierarchyTree?.availableNodes ?? [];
      for (const node of nodes) {
        const reportVariableNode = new ReportVariableNode({
          key: `${node.nodeKey}-data-node`,
          variableKey: '',
          dataNodeKey: node.nodeKey,
          name: node.nodeName,
          topDownHierarchyNames: [],
          displayName: node.nodeName,
          assetKey: node.assetKey,
          parentKey: node.parentNodeKey,
          customerSiteKey: assetLevelNode.customerSiteKey,
          isLeaf: false,
          isOpen: false,
          isDataNode: true,
          children: [],
          currentMeasurementSystem: input.currentMeasurementSystem
        });

        setReportVariableNodeChild(reportVariableNode);

        reportVariableNodesByAsset.push(reportVariableNode);
      }
    });

    await fetchAssetVariablesNodeLeaves({
      assetNode: assetLevelNode,
      dataNodes: reportVariableNodesByAsset,
      currentMeasurementSystem: input.currentMeasurementSystem
    });
  });
}
function buildVariableNodes(assignmentTree: IAssignmentTreeNodeViewModel[], input: IReportVariableFetchInputViewModel, assetLevelNodes: IReportVariableNode[]) {
    const topLevelReportVariableNodes: IReportVariableNode[] = [];
    const parentKeys: string[] = [];
    const childLevelsLookUp: Record<string, IReportVariableNode[]> = {};
    for (const assignment of assignmentTree) {
      const reportVariableNode: IReportVariableNode = {
        key: assignment.key,
        variableKey: '',
        name: assignment.name,
        topDownHierarchyNames: [],
        displayName: assignment.name,
        assetKey: assignment.type === AssignmentNodeType.Heater ? assignment.key : '',
        parentKey: assignment.parentKey,
        customerSiteKey: assignment.customerSiteKey,
        isLeaf: false,
        isOpen: false,
        children: [],
        currentMeasurementSystem: input.currentMeasurementSystem
      };

      if (assignment.type === AssignmentNodeType.Heater && assignment.key === input.assetKey) {
        assetLevelNodes.push(reportVariableNode);
      }

      // collecting values in multiple states to access later;
      const isTopLevel = isStringEmpty(reportVariableNode.parentKey);
      if (isTopLevel) {
        parentKeys.push(reportVariableNode.key);
      } else {
        if (reportVariableNode.assetKey === input.assetKey) {
          topLevelReportVariableNodes.push(reportVariableNode);
        }
        addToLookup(childLevelsLookUp, reportVariableNode.parentKey, reportVariableNode);
      }
    }
    return { topLevelReportVariableNodes, parentKeys, childLevelsLookUp };
  }
