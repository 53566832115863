import { SeriesOptionsType } from 'highcharts';
import {
  IDisplayValueSchemaViewModel,
  ReportInputDisplayValueEnum,
  IUnitFormatViewModel
} from '@/view-models/report-view-models';
import { UnitOfMeasurementEnum } from '@/enums';
import { TimerLock } from './storage-utils';

export enum ReportCallError {
  NoInputAccess = 'UserNotAuthorizedForInput'
}

export type SeriesData = Array<number | Array<number | string | null> | object | null>;
export type ReportSeriesOptionsType = SeriesOptionsType & { name: string; data?: SeriesData, color?: string, borderColor?: string };

export default class ReportHelper {
  public static isInputAccessError(response: any): boolean {
    return response.data && response.data.error === ReportCallError.NoInputAccess;
  }

  public static maxYValue(seriesList: Array<ReportSeriesOptionsType>, yAxis: number): number {
    return ReportHelper.mathYValue(seriesList, yAxis, Math.max);
  }

  public static minYValue(seriesList: Array<ReportSeriesOptionsType>, yAxis: number): number {
    return ReportHelper.mathYValue(seriesList, yAxis, Math.min);
  }
  public static mathYValue(
    seriesList: Array<ReportSeriesOptionsType>,
    yAxis: number,
    mathFun: Function
  ): number {
    const seriesListFiltered = seriesList.filter(s => s.yAxis === yAxis);
    const seriesListFlattened = seriesListFiltered.flatMap(s => s.data);
    const dataList = seriesListFlattened.filter(d => !!d);

    return mathFun.apply(Math, dataList.map(tuple => tuple[1]));
  }
}

export function findUnitFormat(
  displayValue: string,
  schema: IDisplayValueSchemaViewModel[],
  finder: (unit: IUnitFormatViewModel) => boolean
  ): IUnitFormatViewModel {
    let defaultUnit: IUnitFormatViewModel = {
      unit: UnitOfMeasurementEnum.Default,
      decimalDigits: 2,
      isDefaultUnit: true
    };
    if (displayValue == null) {
      return defaultUnit;
    }
  const displayValueObject = schema.find(d => d.displayValue === displayValue);
  if (displayValueObject != null) {
    defaultUnit = displayValueObject.availableUnits.find(finder);
  }
  return defaultUnit;
}

export function formatInputVariableValue(
  value: number, displayValue: ReportInputDisplayValueEnum,
  UOM: UnitOfMeasurementEnum, schema: IDisplayValueSchemaViewModel[]
): number {
  // this logic will change to use UOM parameter once user has choice of UOM
  const unit = findUnitFormat(displayValue, schema, u => u.isDefaultUnit);
  return unit == null ? value : parseFloat(value.toFixed(unit.decimalDigits));
}

export function defaultUOM(displayValue: string, schema: IDisplayValueSchemaViewModel[]): UnitOfMeasurementEnum {
  const defaultUnit = findUnitFormat(displayValue, schema, u => u.isDefaultUnit);
  return defaultUnit == null ? UnitOfMeasurementEnum.Default : defaultUnit.unit;
}

const fetchInterval = 1000 * 60 * 10;
let reportsInputsFetchLock = new TimerLock(fetchInterval);

export function resetReportsInputsFetchLock() {
  reportsInputsFetchLock = new TimerLock(fetchInterval);
}

export function getReportsInputsFetchLock() {
  return reportsInputsFetchLock;
}
