import { ILocationViewModel } from '@/view-models/assets';
import { IDateRange } from '@/view-models/report-time-model';
import { WidgetDataPoint } from '@/view-models/widgets';
import {
  ChartTypesEnum,
  EquipmentIndicatorDisplayValuesEnum,
  StageInputIndicatorDisplayValuesEnum,
  TowerEquipmentNameEnum,
  TowerEquipmentTypeEnum,
  TowerIndicatorDisplayValuesEnum,
  TowerInputTypesEnum,
  TowerThresholdRangesEnum,
  TowerUnitOfMeasurementsEnum,
  TowerValidationsDisplayValuesEnum,
  WidgetTypes
} from '@/enums';

// Interface for TowerView Towers
export interface ITower {
  alerts: number;
  createdAt?: string;
  customerKey: string;
  assetGroupingKey?: string;
  customerSiteKey: string;
  imageSignedUrl?: string;
  key: string;
  lastModifiedAt?: string;
  lastTowerViewCalculation?: string;
  location?: ILocationViewModel;
  name: string;
  orgKey?: string;
  sortStagesAscending: boolean;
  stages: Array<ITowerStage>;
  validations?: Array<ITowerValidation>;
  indicators?: Array<ITowerIndicator>
}
// Interface for TowerView Stages
export interface ITowerStage {
  alerts?: Array<ITowerAlert>;
  equipment: Array<ITowerStageEquipment>;
  input: ITowerStageInput;
  stageKey: string;
  order: number;
}
// Interface for TowerView Stage Equipment
export interface ITowerStageEquipment {
  inWarningLevel?: boolean;
  indicators: Array<IEquipmentIndicatorRequest | IEquipmentIndicator>;
  equipmentKey: string;
  name?: TowerEquipmentNameEnum;
  type: TowerEquipmentTypeEnum;
}
export interface ITowerStageInput {
  inWarningLevel?: boolean;
  indicators: Array<IStageInputIndicatorRequest | IStageInputIndicator>;
}
// Interface for TowerView Alerts
export interface ITowerAlert {
  displayValue: string;
  equipmentName: TowerEquipmentNameEnum;
  equipmentType: TowerEquipmentTypeEnum;
  formattedDisplayValue?: string;
  value: number;
}
// Interface for TowerView Historical Alert data
export interface ITowerHistoricalAlert {
  towerKey : string;
  stageKey: string;
  equipmentKey: string;
  equipmentName: string;
  equipmentType: TowerEquipmentTypeEnum;
  displayName: EquipmentIndicatorDisplayValuesEnum | StageInputIndicatorDisplayValuesEnum;
  displayValue: EquipmentIndicatorDisplayValuesEnum | StageInputIndicatorDisplayValuesEnum;
  alertTimestamp: string;
  value: number;
  thresholdValue: number;
  status: string;
  clearedTimestamp: string;
  createdAt: string;
  chartSettings: IChartSettings;
  uom: TowerUnitOfMeasurementsEnum;
  data?: Array<number[]>;
  type: TowerInputTypesEnum;
}
// Interface for a single Tower Threshold
export interface ITowerThreshold {
  alertType: TowerThresholdRangesEnum;
  color: string;
  maxValue: number;
  minValue: number;
  thresholdName: string;
  thresholdType: string;
}
// Interface for Widget Chart Settings
export interface IChartSettings {
  axis: string;
  chartType: ChartTypesEnum;
  isFullScreen: boolean;
  showTitleAtBottom?: boolean;
  sortOrder: number;
  widgetType: WidgetTypes;
  scale: { min: number, max: number };
}
// Interface for property updates via updateStageWidgetData & updateValidationsWidgetData methods
export interface IObjectPropertyUpdate {
  validationKey?: string;
  indicatorKey?: string;
  equipmentKey?: string;
  equipmentName?: string;
  equipmentType: string;
  path: string;
  value: any;
}
// Interface for tower view local storage
export interface ITowerStageStorageModel {
  value: string;
  timestamp: number;
  date?: any;
}
// Interface for Tower Validations
export interface ITowerValidation {
  chartSettings: IChartSettings;
  displayValue: string;
  equipmentType: TowerEquipmentTypeEnum;
  inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: TowerValidationsDisplayValuesEnum
  }>;
  validationKey: string;
  type: TowerInputTypesEnum;
  unitOfMeasurement: string;
  toShow: string;
}
// Interface for TowerView Equipment Indicators
export interface IEquipmentIndicator extends IEquipmentIndicatorResponse{
  chartSettings: IChartSettings;
  equipmentKey: string;
  key: string;
  inWarningLevel: boolean;
  thresholds: Array<ITowerThreshold>;
  toShow: string;
  data: Array<WidgetDataPoint>;
}
// Interface ofr TowerView Stage Input Indicators
export interface IStageInputIndicator extends IStageInputIndicatorResponse{
  chartSettings: IChartSettings;
  key: string;
  inWarningLevel: boolean;
  thresholds: Array<ITowerThreshold>;
  unitOfMeasurement: string;
  toShow: string;
}
// Interface for TowerView Tower Level Indicators
export interface ITowerIndicator {
  chartSettings: IChartSettings;
  inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: string;
  }>;
  displayValue: TowerIndicatorDisplayValuesEnum;
  equipmentType: TowerEquipmentTypeEnum;
  equipmentName?: TowerEquipmentNameEnum;
  key: string;
  type: TowerInputTypesEnum;
  uom: TowerUnitOfMeasurementsEnum;
}
// Interface for Combined Chart Widget Data Types When Rendering Generic Chart Widget Container
// export type IWidgetDataTypes = ITowerIndicator & ITowerValidation & IEquipmentIndicator;
export type IWidgetDataTypes = any; // TODO refactor to incorporate the various types from the line above

// REQUESTS & RESPONSES
export interface ITowerWidgetDataRequest {
  towerKey: string;
  range: IDateRange;
  sampleSize: number;
  measurementSystemKey: string;
  unitOfMeasurement?: string;
}
export interface ITowerWidgetDataResponse extends Omit<ITowerWidgetDataRequest, 'range'|'sampleSize'> {
  inputs: Array<ITowerIndicator>
}

export interface IStageWidgetDataRequest extends ITowerWidgetDataRequest {
  stageKey: string;
  inputs: Array<IEquipmentIndicatorRequest|IStageInputIndicatorRequest>;
}
export interface IStageWidgetDataResponse extends Omit<IStageWidgetDataRequest, 'range'|'sampleSize'>{
  inputs: Array<IEquipmentIndicatorResponse|IStageInputIndicatorResponse>;
}

export interface IEquipmentIndicatorRequest {
  displayValue: EquipmentIndicatorDisplayValuesEnum;
  equipmentName?: TowerEquipmentNameEnum;
  equipmentType: TowerEquipmentTypeEnum;
  equipmentKey: string;
  key?: string;
  type: TowerInputTypesEnum;
  data?: Array<WidgetDataPoint>;
  unitOfMeasurement?: string;
}
export interface IEquipmentIndicatorResponse extends IEquipmentIndicatorRequest {
  data: Array<WidgetDataPoint>;
  unitOfMeasurement: string;
}

export interface IStageInputIndicatorRequest {
  displayValue: StageInputIndicatorDisplayValuesEnum;
  equipmentType: TowerEquipmentTypeEnum;
  type: TowerInputTypesEnum;
  key?: string;
}
export interface IStageInputIndicatorResponse extends IStageInputIndicatorRequest {
  data: Array<WidgetDataPoint>;
}
export interface ITowerDashboardRequest {
  key: string;
  measurementSystemKey: string
}
export interface IValidationsWidgetDataRequest {
  towerKey: string;
  range: IDateRange;
  sampleSize: number;
  inputs: Array<Pick<ITowerValidation, 'validationKey'>>;
  measurementSystemKey: string;
}
export interface IValidationsWidgetDataResponse extends Pick<IValidationsWidgetDataRequest, 'towerKey'> {
  inputs: Array<ITowerValidation>
}

export interface ITowerCustomThresholdRequest extends ITowerThreshold {
  towerKey: string;
  stageKey: string;
  equipmentKey?: string;
  equipmentName: string;
  equipmentType: string;
  displayName: string;
}
export interface ITowerAlertHistoryRequest {
  key: string;
  currentPage: number;
  sortBy: string;
  sortDesc: boolean;
  activeOnly: boolean;
}
export interface ITowerThresholdsResponse {
  towerKey: string;
  stages: Array<{
    stageInputs: Array<{
      displayName: StageInputIndicatorDisplayValuesEnum;
      thresholds: Array<ITowerThreshold>
    }>;
    equipment: Array<{
      equipmentKey: string;
      equipmentName: TowerEquipmentTypeEnum;
      performanceIndicators: Array<{
        displayName: EquipmentIndicatorDisplayValuesEnum;
        thresholds: Array<ITowerThreshold>
      }>
    }>;
    stageKey: string;
  }>
}

// CLASSES
export class TowerIndicatorRequest {
  public displayValue: TowerIndicatorDisplayValuesEnum;
  public equipmentType: TowerEquipmentTypeEnum;
  public key: string;
  public type: TowerInputTypesEnum;

  constructor(displayValue: TowerIndicatorDisplayValuesEnum, key: string) {
    this.displayValue = displayValue;
    this.equipmentType = TowerEquipmentTypeEnum.None;
    this.key = key;
    this.type = TowerInputTypesEnum.Tower;
  }
}
export class TowerIndicator implements ITowerIndicator {
  public chartSettings: IChartSettings;
  public inputs: Array<{
    data: Array<WidgetDataPoint>,
    dataSetName: TowerIndicatorDisplayValuesEnum
  }>
  public displayValue: TowerIndicatorDisplayValuesEnum;
  public equipmentType: TowerEquipmentTypeEnum;
  public key: string;
  public type: TowerInputTypesEnum;
  public uom: TowerUnitOfMeasurementsEnum;

  constructor(input, towerKey) {
    this.key = `${towerKey}:${input.displayValue}`;
    Object.assign(this, input);
  }
}

export class EquipmentIndicatorRequest implements IEquipmentIndicatorRequest {
  public displayValue: EquipmentIndicatorDisplayValuesEnum;
  public equipmentName?: TowerEquipmentNameEnum;
  public equipmentType: TowerEquipmentTypeEnum;
  public equipmentKey: string;
  public key?: string;
  public type: TowerInputTypesEnum;

  constructor(input: IEquipmentIndicatorRequest) {
    Object.assign(this, input);
  }
}
export class EquipmentIndicator implements IEquipmentIndicator {
  public chartSettings: IChartSettings;
  public data: Array<WidgetDataPoint>;
  public displayValue: EquipmentIndicatorDisplayValuesEnum;
  public equipmentName?: TowerEquipmentNameEnum;
  public equipmentType: TowerEquipmentTypeEnum;
  public inWarningLevel: boolean;
  public indicatorKey: string;
  public equipmentKey: string;
  public key: string;
  public thresholds: Array<ITowerThreshold>;
  public type: TowerInputTypesEnum;
  public toShow: string;
  public stageOrder?: number;
  public unitOfMeasurement: string;

  constructor(input: IEquipmentIndicatorResponse) {
    Object.assign(this, input);
    this.key = `${input?.equipmentKey}:${input.displayValue}`;
  }
}

export class StageInputIndicatorRequest implements IStageInputIndicatorRequest {
  public displayValue: StageInputIndicatorDisplayValuesEnum;
  public equipmentType: TowerEquipmentTypeEnum;
  public type: TowerInputTypesEnum;

  constructor(input: StageInputIndicatorDisplayValuesEnum) {
    this.displayValue = input;
    this.equipmentType = TowerEquipmentTypeEnum.None;
    this.type = TowerInputTypesEnum.TowerStageInput;
  }
}

export class StageInputIndicator implements IStageInputIndicator {
  public chartSettings: IChartSettings;
  public data: Array<WidgetDataPoint>;
  public displayValue: StageInputIndicatorDisplayValuesEnum;
  public equipmentType: TowerEquipmentTypeEnum.None;
  public inWarningLevel: boolean;
  public indicatorKey: string;
  public key: string;
  public thresholds: Array<ITowerThreshold>;
  public type: TowerInputTypesEnum;
  public uom: TowerUnitOfMeasurementsEnum;
  public toShow: string;
  public stageOrder?: number;
  public unitOfMeasurement: string;

  constructor(input: IStageInputIndicatorResponse) {
    Object.assign(this, input);
    this.key = input.displayValue;
  }
}

export class TowerValidation implements ITowerValidation {
  public chartSettings: IChartSettings;
  public displayValue: string;
  public inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: TowerValidationsDisplayValuesEnum
  }>;
  public equipmentType: TowerEquipmentTypeEnum;
  public validationKey: string;
  public type: TowerInputTypesEnum;
  public unitOfMeasurement: string;
  public toShow: string;

  constructor(input: ITowerValidation) {
    Object.assign(this, input);
  }
}
export class TowerThreshold implements ITowerThreshold {
  public alertType: TowerThresholdRangesEnum;
  public color: string;
  public maxValue: number;
  public minValue: number;
  public thresholdName: string;
  public thresholdType: string;

  constructor(alertType: TowerThresholdRangesEnum, color: string, maxValue: number, minValue: number, thresholdName: string, thresholdType: string) {
    this.alertType = alertType;
    this.color = color;
    this.maxValue = maxValue;
    this.minValue = minValue;
    this.thresholdName = thresholdName;
    this.thresholdType = thresholdType;
  }
}
