import { ReportAxis, ReportRangeType, SeriesTypes } from '@/enums/report-enums';

import { UnitOfMeasurementEnum } from '@/enums';
import { ReportVariableState } from '@/enums/report-variable-state';
import { newGuid, variableReplaceAll } from '@/shared/string-utils';
import { MeasurementTypesEnum } from '@/enums/variables';

// TODO: this should be renamed IReportViewModel to follow the convention
// of naming the models coming from the API
export interface IReport {
  key: string;
  name: string;
  orgKey: string;
  createdAt: string;
  createdBy: string;
  modifiedAt?: string;
  modifiedBy?: string;
  userKey: string;
  leftAxisUoM: string;
  rightAxisUoM: string;
  rangeType: ReportRangeType;
  fromDate?: string;
  toDate?: string;
  specificYear?: number;
  reportVariables: IReportVariableViewModel[];

  // Client only
  reportVariableGroups: IGroupedReportVariables[];
  isFavorite?: boolean;
  checksum?: string;
  showGridLines: boolean;
  sampleSize?: number;
}

// TODO: this should use a different name, maybe IReport or maybe it could be merged with above.
// If you change the name also change the name of the implementing class
export interface IReportViewModel extends IReport {
}

export interface IReportDataViewModel {
  key: string;

  // Client-side
  displayValue: string;
  name: string;
}

export class ReportViewModel implements IReportViewModel {
  public key: string = '';
  public name: string = '';
  public orgKey: string = '';
  public createdAt: string = undefined;
  public createdBy: string = '';
  public modifiedAt?: string;
  public modifiedBy?: string;
  public userKey: string;
  public leftAxisUoM: string;
  public rightAxisUoM: string;
  public rangeType: ReportRangeType = ReportRangeType.Today;
  public fromDate?: string;
  public toDate?: string;
  public specificYear?: number = new Date().getFullYear() - 1;
  public reportVariables: IReportVariableViewModel[] = [];
  public reportVariableGroups: IGroupedReportVariables[] = [];
  public checksum?: string;
  public showGridLines: boolean = false;
  public sampleSize: number;

  constructor(report?: IReport) {
    if (report != null) {
      Object.assign(this, report);
      this.reportVariables = (report.reportVariables || []).map(
        (variable: IReportVariableViewModel) => new ReportVariableViewModel(variable)
      );
      this.reportVariableGroups = this.buildReportVariableGroups(this.reportVariables);
      // this.resetChecksum();
    }
  }

  private buildReportVariableGroups(reportVariables: IReportVariableViewModel[]): IGroupedReportVariables[] {
    const groups: IGroupedReportVariables[] = this.reportVariableGroups;
    reportVariables.forEach((variable) => {
      const matchedReportVariableGroup = this.reportVariableGroups.find(
        (group: IGroupedReportVariables) => group.key === variable.nodeKey
      );

      if (matchedReportVariableGroup) {
        const matchedReportVariable = matchedReportVariableGroup.reportVariables.find(
          reportVariable => reportVariable.key === variable.key
        );
        if (!matchedReportVariable) {
          matchedReportVariableGroup.reportVariables.push(variable);
        }
      } else {
        let groupName = 'Unknown Group';
        if (variable.topDownHierarchyPathName) {
          groupName = variableReplaceAll(variable.topDownHierarchyPathName, '::', '/ ');
        }

        const newReportVariableGroup: IGroupedReportVariables = {
          key: variable.nodeKey,
          name: groupName,
          reportVariables: [variable],
          isOpen: true
        };

        groups.push(newReportVariableGroup);
      }
    });

    return groups;
  }
}

export interface IReportSnapshot {
  report: IReportViewModel;
  executedReport: IReportViewModel;
  reportData: IReportDataViewModel[];
}

export class ReportSnapshot {
  public report: IReportViewModel = new ReportViewModel();
  public executedReport: IReportViewModel = new ReportViewModel();
  public reportData: IReportDataViewModel[] = [];

  constructor(snapshot?: IReportSnapshot) {
    if (snapshot != null) {
      this.report = new ReportViewModel(snapshot.report);
      this.executedReport = new ReportViewModel(snapshot.executedReport);
      this.reportData = snapshot.reportData.slice(0);
    }
  }
}

export interface IReportVariableUomModel {
  reportVariable: IReportVariableViewModel;
  toUoM: UnitOfMeasurementEnum;
}

export interface IReportVariableViewModel {
  key: string;
  assetKey: string;
  variableKey: string;
  nodeKey: string;
  dataRefs: string[];
  displayName: string;
  sortOrder: number;
  axis: ReportAxis;
  chartType: SeriesTypes;
  tempusTableKey: string;
  unitOfMeasurement: UnitOfMeasurementEnum;
  mergedDetails?: IReportVariableMergedDetailsViewModel;

  // client side
  measurementType: MeasurementTypesEnum;
  // state?: ReportVariableState;
  topDownHierarchyPathName?: string;
}

export interface IReportVariableMergedDetailsViewModel {
  // mergedState: ReportVariableMergedState;
  preDisplayNames: string[];
  postDisplayNames: string[];
}

export interface IGroupedReportVariables {
  key: string;
  name: string;
  reportVariables: IReportVariableViewModel[];
  isOpen: boolean;
}

export class ReportVariableViewModel implements IReportVariableViewModel {
  public key: string = newGuid();
  public assetKey: string = '';
  public nodeKey: string = '';
  public variableKey: string = '';
  public dataRefs: string[] = [];
  public displayName: string = '';
  public sortOrder: number = 0;
  public axis: ReportAxis = ReportAxis.Left;
  public chartType: SeriesTypes = SeriesTypes.Line;
  public isOpen: boolean = false;
  public tempusTableKey: string;
  public unitOfMeasurement: UnitOfMeasurementEnum = UnitOfMeasurementEnum.Default;
  public state: ReportVariableState = ReportVariableState.Ok;
  public topDownHierarchyPathName?: string;
  public measurementType: MeasurementTypesEnum = MeasurementTypesEnum.Unknown;
  public mergedDetails?: IReportVariableMergedDetailsViewModel;

  constructor(variable?: IReportVariableViewModel) {
    if (variable != null) {
      Object.assign(this, variable);
    }
  }
}

export type UnixEpochTime = number; // milliseconds
