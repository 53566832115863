import { Context } from '@nuxt/types';
import { Permission } from '@/view-models';

export default function <Nuxt, Middleware>({ route, redirect, store }: Context) {
  const path = route.path;
  const toPaths = {
    'user-management': Permission.ManageUserAndRole,
    'organization-management': Permission.ManageCustomerAccounts,
    'admin/companies': Permission.ManageKESCompanies,
    'admin/plant-connector': Permission.ViewPlantConnectorAgent,
    'ember/asset-editor': Permission.ManageAssetConfiguration,
    'ember/hierarchy-builder': Permission.ManageHierarchy,
    'ember/hydraulic-modeler': Permission.ViewHydraulicModel,
    'ember/layout-editor': Permission.CreateAssetDiagram,
    'ember/variable-editor': Permission.ViewVariableEditor,
    '/tower': Permission.ManageTowers,
    '/reports': Permission.ManageReports,
    '/dashboard': Permission.ViewDashboards,
    '/layout': Permission.ViewAssetDiagram
  };

  for (const toPath in toPaths) {
    const permission = Permission[toPaths[toPath]];
    if (path.includes(toPath) && !hasPermission(path, toPath, permission, store)) {
      redirect('/no-permission');
    }
  }
}

export function hasPermission(path: string, toPath: string, permission: Permission, store): boolean {
  return store.getters['user/hasPermission'](permission);
}
