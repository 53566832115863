export interface TableAction {
  key: TableActionType;
  iconClass: string;
  text?: string;
}

export enum TableActionType {
  Edit = 'edit',
  Delete = 'delete',
  Navigate = 'navigate'
}

export interface TableColumn {
  field: string;
  text?: string;
  slotName?: string;
  cellFormatter?: (value: any, row: any) => string;
  sortable?: boolean;
}

export interface TableFetchOptions {
  currentPage: number;
  perPage: number;
  sortBy?: string;
  sortDesc?: boolean;
  searchTerm?: string;
}
