import { Plugin } from '@nuxt/types';
import { authClient, authData, authErrorHandler } from '@/shared/auth';

const plugin: Plugin = async ({ $vxm, error: errorHandler }): Promise<void> => {
  try {
    const authenticated = await authClient.isAuthenticated();
    if (authenticated) {
      // If user is logged in, fill the store with data
      authData.user = await $vxm.user.getUser();
    }
  } catch (error) {
    authErrorHandler(true, error, errorHandler);
  }
};

export default plugin;
