import Vue from 'vue';
import { createModule, mutation, action } from 'vuex-class-component';
import { getCarAxios } from '@/shared/http';
import { VariableDataService } from '@/services';
import { IDateTimeRange, IVariableDataItem, IVariableDataRequest, IVariableDataResponse } from '@/view-models';
import { TimeTypeEnum, UnitOfMeasurementEnum } from '@/enums';
import { IVisualizationV2 } from '@/store';
import { debounce, timeConversion, timeRangeDifference } from '@/shared/date-time-utils';
import { globalAppCatch } from '@/shared/helper-methods';
import { sortedArrayFindIndex } from '@/shared/array-utils';
import { Nil } from '@/view-models/shared/types';
import { isEqual } from 'lodash';
import { deepClone } from '@/shared/object-utils';

const VuexModule = createModule({
  namespaced: 'visualization-v2',
  strict: false,
  target: 'nuxt'
});

export class VisualizationV2 extends VuexModule implements IVisualizationV2 {
  // #region State
  public variableDataService: VariableDataService;
  public activeDateRange: IDateTimeRange;
  public activeXAxisValue: number;
  public fullscreenElement: string;
  public variableData: Record<string, IVariableDataItem>;
  public selectedTime: Date;
  public variableDataBySelectedTime: Record<string, IVariableDataItem>;
  public loadingVariableData: string[];
  public viewingHistoricalData: boolean;
  public currentMeasurementSystemKey: string;
  public dataSampleLimit: number;
  public dataLastUpdated: Nil<Date>;
  private visibleVariableKeys: string[];

  constructor() {
    super();
    this.resetState();
  }
  // #endregion State

  // #region Getters
  public get timePaused(): boolean {
    return this.viewingHistoricalData || this.selectedTime != null;
  }
  public get isDataSampled(): boolean {
    const allDataArrays: Array<IVariableDataItem> = this.variableData != null ? Object.values(this.variableData) : [];
    const dataPointLengths: number[] = allDataArrays.filter(p => p != null).flatMap(p => p.data.length);

    return Math.max(...dataPointLengths) >= this.dataSampleLimit;
  }
  public get lastTimestampsOfCurrentDateRange(): Record<string, number> {
    const endDateUnix: number = this.activeDateRange.toDate?.getTime() ?? Date.now();

    return Object.fromEntries(Object.keys(this.variableData).map((d) => {
      const dataArray: Array<[number, number]> = this.variableData[d]?.data ?? [];
      const closestIndexToEndDate: number = sortedArrayFindIndex(dataArray).findClosest(endDateUnix, 'below', v => v[0]);

      return [
        d,
        closestIndexToEndDate >= 0 ? dataArray[closestIndexToEndDate][0] : null
      ];
    }));
  }
  public get variableValue(): ([variableKey, currentTimestamp]: [string, number]) => {timestamp: number, value: number, uom: UnitOfMeasurementEnum} {
    return ([variableKey, currentTimestamp]: [string, number]) => {
        const returnValue = {
          timestamp: currentTimestamp,
          value: null,
          uom: null
        };

        if (currentTimestamp == null) {
          return returnValue;
        }
        let dataItem: IVariableDataItem = this.variableData[variableKey];
        const selectedValueData: IVariableDataItem = this.variableDataBySelectedTime[variableKey];
        if (selectedValueData?.data[0] && selectedValueData.data[0][0] === currentTimestamp) {
          dataItem = selectedValueData;
        }
        const indexOfXValue: number = dataItem != null ? sortedArrayFindIndex(dataItem.data).find(currentTimestamp, v => v[0]) : -1;
        const foundDataPoint: [number, number] = indexOfXValue >= 0 ? dataItem?.data[indexOfXValue] : null;

        returnValue.timestamp = foundDataPoint?.[0] ?? currentTimestamp;
        returnValue.value = foundDataPoint?.[1] ?? null;
        returnValue.uom = dataItem?.unitOfMeasure;

        return returnValue;
    };
  }
  // #endregion Getters

  // #region Mutations
  @mutation
  public resetState(): void {
    const now: Date = new Date();
    this.variableDataService = null;
    this.activeDateRange = {
      fromDate: new Date(now.getTime() - timeConversion(1, TimeTypeEnum.Days, TimeTypeEnum.Milliseconds)),
      toDate: now
    };
    this.viewingHistoricalData = false;
    this.activeXAxisValue = null;
    this.fullscreenElement = null;
    this.variableData = {};
    this.selectedTime = null;
    this.variableDataBySelectedTime = {};
    this.loadingVariableData = [];
    this.currentMeasurementSystemKey = null;
    this.dataSampleLimit = 2000;
    this.dataLastUpdated = null;
    this.visibleVariableKeys = [];
  }
  @mutation
  public setVariableDataService(): void {
    if (this.variableDataService == null) {
      this.variableDataService = new VariableDataService(getCarAxios());
    }
  }
  @mutation
  public setActiveXAxisValue(value: number): void {
    this.activeXAxisValue = value == null || typeof value === 'number' ? value : null;
  }
  @mutation
  public toggleFullscreenElement(key: string): void {
    this.fullscreenElement = this.fullscreenElement !== key ? key : null;
  }
  @mutation
  public setActiveDateRange(newDateRange: IDateTimeRange): void {
    const now: Date = new Date();
    // eslint-disable-next-line keyword-spacing
    if (newDateRange.toDate == null || newDateRange.toDate > now) {
      newDateRange.toDate = now;
      newDateRange.fromDate = new Date(newDateRange.toDate.getTime() - timeRangeDifference(newDateRange));
    }

    if (this.activeDateRange != null && newDateRange.fromDate.getTime() === this.activeDateRange.fromDate.getTime() && newDateRange.toDate.getTime() === this.activeDateRange.toDate.getTime()) {
      return;
    }

    this.viewingHistoricalData = newDateRange.toDate.getTime() < now.getTime();
    this.activeDateRange = newDateRange;
  }
  @mutation
  public incrementTime(): void {
    if (this.viewingHistoricalData || this.selectedTime != null) {
      return;
    }

    const now: Date = new Date();
    const activeDateRangeTo: Date = this.activeDateRange.toDate ?? now;
    const timeDifference: number = timeRangeDifference({ fromDate: activeDateRangeTo, toDate: now });

    Object.keys(this.variableData).forEach((k) => {
      const data: IVariableDataItem = this.variableData[k];

      if (data != null) {
        data.representedDateRange.toDate = now;
      }
    });

    this.activeDateRange = {
      fromDate: new Date(this.activeDateRange.fromDate.getTime() + timeDifference),
      toDate: new Date(activeDateRangeTo.getTime() + timeDifference)
    };
  }
  @mutation
  public setSelectedTime(newDate: Date): void {
    this.selectedTime = newDate instanceof Date && !isNaN(newDate.getTime()) ? newDate : null;
  }
  @mutation
  public clearAllVariableData(): void {
    this.variableData = {};
    this.variableDataBySelectedTime = {};
  }
  @mutation
  private setLoadingVariableData(variableKeys: string[]): void {
    this.loadingVariableData = variableKeys ?? [];
  }
  @mutation
  public addVisibleVariableKeys(variableKeys: string[]): void {
    if (!Array.isArray(variableKeys) || variableKeys.length <= 0) {
      return;
    }

    this.visibleVariableKeys = this.visibleVariableKeys.concat(variableKeys);
  }
  @mutation
  public removeVisibleVariableKeys(variableKeys: string[]): void {
    if (!Array.isArray(variableKeys) || variableKeys.length <= 0) {
      return;
    }

    variableKeys.forEach((k) => {
      const index: number = this.visibleVariableKeys.indexOf(k);
      if (index >= 0) {
        this.visibleVariableKeys.splice(index, 1);
      }
    });
  }
  @mutation
  private setVariableData(variableDataItem: IVariableDataItem): void {
    Object.freeze(variableDataItem);

    Vue.set(this.variableData, variableDataItem.variableKey, variableDataItem);
  }
  @mutation
  private setVariableDataBySelectedTime(dataPoints: Array<IVariableDataItem>): void {
    this.variableDataBySelectedTime = Object.fromEntries(dataPoints.map(d => [d.variableKey, d]));
  }
  @mutation
  public setCurrentMeasurementSystemKey(key: string): void {
    this.currentMeasurementSystemKey = key;
  }
  @mutation
  public setDataLatestTime(lastUpdate: Date): void {
    if (this.dataLastUpdated == null || this.dataLastUpdated < lastUpdate) {
      this.dataLastUpdated = new Date(lastUpdate);
    }
  }
  // #endregion Mutations

  // #region Actions
  @action
  public async getUnsampledDataByActiveTimeRange(variableKeys: string[]): Promise<Array<IVariableDataItem>> {
    if (this.variableDataService == null) {
      this.setVariableDataService();
    }

    const dedupedVariableDataKeys: string[] = Array.from(new Set(variableKeys));
    const request: IVariableDataRequest = {
      dateRange: {
        fromDate: new Date(this.activeDateRange.fromDate.getTime()),
        toDate: new Date(this.activeDateRange.toDate.getTime())
      },
      variables: dedupedVariableDataKeys.map(k => ({ key: k, unitOfMeasure: null })),
      measurementSystemKey: this.currentMeasurementSystemKey,
      options: {
        sortAscending: true
      }
    };

    try {
      this.setLoadingVariableData(dedupedVariableDataKeys);
      const variableData: Array<IVariableDataResponse> = await this.variableDataService.getVariablesData(request);
      return variableData.map((d) => {
        return {
          variableKey: d.variableKey,
          unitOfMeasure: d.unitOfMeasurement,
          representedDateRange: request.dateRange,
          data: d.data
        } as IVariableDataItem;
      });
    } catch (e) {
      globalAppCatch(e);
    } finally {
      this.setLoadingVariableData(null);
    }
  }
  @action
  public async getVariableDataByVisibleKeys(): Promise<void> {
    if (this.variableDataService == null) {
      this.setVariableDataService();
    }

    await debounce('getVariableData', 300, async () => {
      const zoomingOnSampledData: (dataKey: string) => boolean = (dataKey: string) => {
        const data: IVariableDataItem = this.variableData[dataKey];
        return this.isDataSampled && (data == null || timeRangeDifference(data.representedDateRange) > timeRangeDifference(this.activeDateRange));
      };
      const variableDataInsufficient: (dataKey: string) => boolean = (dataKey: string) => {
        const variableDataItem: IVariableDataItem = this.variableData[dataKey];

        if (variableDataItem == null) {
          return true;
        }

        // eslint-disable-next-line keyword-spacing
        const minInsufficient: boolean = variableDataItem.representedDateRange.fromDate > this.activeDateRange.fromDate;
        const maxInsufficient: boolean = variableDataItem.representedDateRange.toDate < this.activeDateRange.toDate;

        return !Array.isArray(variableDataItem.data) || minInsufficient || maxInsufficient;
      };

      const dedupedVariableDataKeys: string[] = Array.from(new Set(this.visibleVariableKeys));
      const filteredVariableDataKeys: string[] = dedupedVariableDataKeys.filter((dataKey: string) => zoomingOnSampledData(dataKey) || variableDataInsufficient(dataKey));

      if (filteredVariableDataKeys.length <= 0) {
        return;
      }

      const dateRangeAtTimeOfRequest: IDateTimeRange = deepClone(this.activeDateRange);
      const bufferInPercentageOfTimeRange = 0.1;
      const bufferInMs: number = timeRangeDifference({
        fromDate: dateRangeAtTimeOfRequest.fromDate,
        toDate: dateRangeAtTimeOfRequest.toDate || new Date()
      }) * bufferInPercentageOfTimeRange;

      const request: IVariableDataRequest = {
        dateRange: {
          fromDate: new Date(dateRangeAtTimeOfRequest.fromDate.getTime() - bufferInMs),
          toDate: new Date(dateRangeAtTimeOfRequest.toDate.getTime() + bufferInMs)
        },
        variables: filteredVariableDataKeys.map(k => ({ key: k, unitOfMeasure: null })),
        measurementSystemKey: this.currentMeasurementSystemKey,
        options: {
          sampleSize: this.dataSampleLimit,
          sortAscending: true
        }
      };

      try {
        this.setLoadingVariableData(filteredVariableDataKeys);
        const variableData: Array<IVariableDataResponse> = await this.variableDataService.getVariablesData(request);
        if (!isEqual(this.activeDateRange, dateRangeAtTimeOfRequest)) {
          return;
        }

        variableData.forEach((d) => {
          const variableDataItem: IVariableDataItem = {
            variableKey: d.variableKey,
            unitOfMeasure: d.unitOfMeasurement as UnitOfMeasurementEnum,
            representedDateRange: this.activeDateRange,
            data: d.data
          };

          this.setVariableData(variableDataItem);
        });
      } catch (e) {
        globalAppCatch(e);
      } finally {
        this.setLoadingVariableData(null);
      }
    });
  }
  @action
  public async getVariableDataBySelectedTime(variableKeys: string[]): Promise<void> {
    if (this.variableDataService == null) {
      this.setVariableDataService();
    }

    if (this.selectedTime == null) {
      return;
    }

    await debounce('getSelectedTimeSingleDataPoints', 300, async () => {
      const dedupedVariableDataKeys: string[] = Array.from(new Set(variableKeys));
      const request: IVariableDataRequest = {
        dateRange: {
          fromDate: this.selectedTime,
          toDate: this.selectedTime
        },
        variables: dedupedVariableDataKeys.map(k => ({ key: k, unitOfMeasure: null })),
        measurementSystemKey: this.currentMeasurementSystemKey,
        options: {
          sortAscending: true
        }
      };

      try {
        this.setLoadingVariableData(dedupedVariableDataKeys);
        const variableData: Array<IVariableDataResponse> = await this.variableDataService.getVariablesData(request);

        const result = variableData.map((d) => {
          return {
            variableKey: d.variableKey,
            unitOfMeasure: d.unitOfMeasurement as UnitOfMeasurementEnum,
            representedDateRange: { fromDate: this.selectedTime, toDate: this.selectedTime },
            data: d.data
          };
        });

        this.setVariableDataBySelectedTime(result);
      } catch (e) {
        globalAppCatch(e);
      } finally {
        this.setLoadingVariableData(null);
      }
    });
  }
  @action
  public async getLatestSingleDataPoints(variableKeys: string[]): Promise<void> {
    if (this.variableDataService == null) {
      this.setVariableDataService();
    }

    await debounce('getLatestSingleDataPoints', 300, async () => {
      const request = {
        variables: variableKeys.map(k => ({ key: k, unitOfMeasure: null })),
        measurementSystemKey: this.currentMeasurementSystemKey
      };

      try {
        const variableData: Array<IVariableDataResponse> = await this.variableDataService.getVariablesDataLatestValue(request);

        this.setDataLatestTime(new Date(latestDateFromDataPoints(variableData.flatMap(d => d.data))));
      } catch (e) {
        globalAppCatch(e);
      }
    });
  }
  // #endregion Actions
}

function latestDateFromDataPoints(latestSingleDataPoints: Array<[number, number]>): Date {
  const lastDataPoints: Array<[number, number]> = latestSingleDataPoints != null ? Object.values(latestSingleDataPoints).filter(p => p != null) : null;
  if (lastDataPoints == null || lastDataPoints.length <= 0) {
    return null;
  }

  const latest: number = Math.max(...lastDataPoints.map(p => p[0]));

  return isFinite(latest) ? new Date(latest) : null;
}
