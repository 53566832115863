import {
  ReportAxis,
  ReportRangeType,
  SeriesTypes,
  UnitsOfMeasure,
  WidgetTypes,
  UnitOfMeasurementEnum
} from '@/enums';
import { newGuid } from '@/shared/string-utils';
import { IEntityViewModel } from '@/view-models/global-view-model';
import { cloneArray } from '@/shared/array-utils';
import { DateRange, IDateRange, IReportTimeOptions, ReportTimeOptions } from './report-time-model';
import { DataTable, IDataTable } from './data-table';

export interface IAvailableInputViewModel {
  key: string;
  name: string;
  parentKey?: string;
  availableInputs?: Array<IInputViewModel>;
}

export interface IReportSummaryRow {
  inputName: string;
  key: string;
  peakValue: number;
  peakDateTime: Date;
  scheduleName: string;
  totalValue: number;
  totalCost: number;
  valueUnit: UnitsOfMeasure;
  costUnit: UnitsOfMeasure;
  peakValueUnit: UnitsOfMeasure;
}

export enum ReportInputTypeEnum {
  Unknown = 'Unknown',
  JZHCHeaterInput = 'JZHCHeaterInput',
  JZHCEmberHierarchyInput = 'JZHCEmberHierarchyInput',
  JZHCBurnerInput = 'JZHCBurnerInput'
}

export interface IReportInputBase {
  key?: string;
  inputType: ReportInputTypeEnum;
  inputEntityKey: string;
  customerSiteKey: string;
}

export interface IInputViewModel extends IReportInputBase {
  equipmentConfigKey: string;
  inputName: string;
  parentEntityKey?: string;

  // clientOnly
  siteKey?: string;
}

export class InputViewModel implements IInputViewModel {
  public inputEntityKey: string;
  public inputType: ReportInputTypeEnum;
  public inputName: string;
  public parentEntityKey?: string;
  public equipmentConfigKey: string;
  public customerSiteKey: string;

  constructor(input?: IInputViewModel) {
    if (input != null) {
      Object.assign({}, this);
      // this.timesOfUse = input.timesOfUse.map((t: ITimeOfUseViewModel) => new TimeOfUseViewModel(t));
    }
  }
  public static getInputPlaceholder(): IInputViewModel {
    const inputPlaceholderId: string = 'abc'; // This synchronizes the backend and frontend.
    const placeholderInput: InputViewModel = new InputViewModel();
    placeholderInput.inputEntityKey = inputPlaceholderId;
    placeholderInput.inputType = ReportInputTypeEnum.Unknown;
    placeholderInput.inputName = 'report.inputPlaceholder';
    // placeholderInput.interval = null;
    placeholderInput.equipmentConfigKey = '';
    return placeholderInput;
  }
  public static unknownTimeOfUseId: number = 0;
}

export interface IAssetDisplayOptionsViewModel {
  entityType: ReportInputTypeEnum;
  allowedDisplayOptions: string[];
}

export interface IValidUOMViewModel {
  displayValue: string;
  unitsOfMeasurement: UnitOfMeasurementEnum[];
}

export interface IDisplayValueSchemaViewModel {
  displayValue: ReportInputDisplayValueEnum;
  availableUnits: Array<IUnitFormatViewModel>;
}

export interface IUnitFormatViewModel {
  unit: UnitOfMeasurementEnum;
  decimalDigits: number;
  isDefaultUnit?: boolean;
}

export enum ReportInputDisplayValueEnum {
  Unknown = 'Unknown',
  Draft = 'Draft',
  ZoneO2 = 'ZoneO2',
  StackNOx = 'StackNOx',
  StackCO = 'StackCO',
  FuelMassFlow = 'FuelMassFlow',
  TargetO2 = 'TargetO2',
  CleanTipExcessAir = 'CleanTipExcessAir',
  CleanTipExpectedDryO2 = 'CleanTipExpectedDryO2',
  CleanTipExpectedWetO2 = 'CleanTipExpectedWetO2',
  CleanTipLeakageAirFlowRate = 'CleanTipLeakageAirFlowRate',
  CleanTipHeatRelease = 'CleanTipHeatRelease',
  CustomerFlowExcessAir = 'CustomerFlowExcessAir',
  CustomerFlowExpectedDryO2 = 'CustomerFlowExpectedDryO2',
  CustomerFlowExpectedWetO2 = 'CustomerFlowExpectedWetO2',
  CustomerFlowLeakageAirFlowRate = 'CustomerFlowLeakageAirFlowRate',
  CustomerFlowHeatRelease = 'CustomerFlowHeatRelease',
  TipHealthIndicator = 'TipHealthIndicator',
  StackCombustibles = 'StackCombustibles',
  ArchTemperature = 'ArchTemperature',
  MidBoxTemperature = 'MidBoxTemperature',
  FloorTemperature = 'FloorTemperature',
  AmbientPressure = 'AmbientPressure',
  LowerHeatingValue = 'LowerHeatingValue',
  LowerHeatingValueBtuscf = 'LowerHeatingValueBtuscf',
  CleanTipBurnerLambdas = 'CleanTipBurnerLambdas',
  CleanTipBurnerSubStoich = 'CleanTipBurnerSubStoich',
  CustomerFlowBurnerLambdas = 'CustomerFlowBurnerLambdas',
  CustomerFlowBurnerSubStoich = 'CustomerFlowBurnerSubStoich',
  CleanTipBurnerOutOfControllableRange = 'CleanTipBurnerOutOfControllableRange',
  CleanTipRecommendedDamperSetting = 'CleanTipRecommendedDamperSetting',
  SubStoichWarning = 'SubStoichWarning'
}

export type ReportInputData = Array<[string | number, number]>;

export interface IReportDataViewModel {
  key: string;
  data: ReportInputData;

  // Client-side
  inputName: string;
}

export interface IInputReference {
  key: string;
  displayValue: string;
}

export function getInputUniqueKey(input: IInputReference) {
  return input != null ? `${input.key}.${input.displayValue}` : null;
}

export function getInputRefKey(type: WidgetTypes, input: IInputReference) {
  const prefix = [WidgetTypes.Gauge, WidgetTypes.Status].includes(type)
    ? `${WidgetTypes.Gauge}-${WidgetTypes.Status}`
    : type.toString();

  return `${prefix}.${getInputUniqueKey(input)}`;
}

export interface IInputDataViewModel {
  key: string;
  displayValue: string;
  data: ReportInputData;

  // Client-side
  inputName: string;
  variableKey?: string;
}

export interface IInputDataBuckets {
  [inputRef: string]: IInputDataViewModel;
}

export class ReportDataViewModel implements IReportDataViewModel {
  public data: ReportInputData;
  public inputName: string;
  public key: string;

  constructor(reportData?: IReportDataViewModel) {
    if (reportData != null) {
      Object.assign(this, reportData);
    }
  }
}

export interface IReportChartSettings {
  is3D: boolean;
  showGridLines: boolean;
  leftUnitOfMeasure: UnitsOfMeasure;
  rightUnitOfMeasure: UnitsOfMeasure;
  syncAxisLimits: boolean;
}

export class ReportChartSettings implements IReportChartSettings {
  public is3D: boolean;
  public showGridLines: boolean;
  public leftUnitOfMeasure: UnitsOfMeasure;
  public rightUnitOfMeasure: UnitsOfMeasure;
  public syncAxisLimits: boolean;

  constructor(chartSettings?: IReportChartSettings) {
    if (chartSettings != null) {
      Object.assign(this, chartSettings);
    }
  }
}

// export interface IInputViewModel extends IReportInputBase {
//   interval: number;
//   inputName: string;
//   isNormalizable: boolean;
//   unitDisplay: string;
// }
// export class InputViewModel implements IInputViewModel {
//   public inputType: ReportInputTypeEnum;
//   public inputEntityKey: string;
//   public parentEntityKey?: string;
//   public interval: number;
//   public inputName: string;
//   public isNormalizable: boolean
//   public unitDisplay: string;

//   constructor(input?: IInputViewModel) {
//     if (input != null) {
//       Object.assign({}, this);
//     } else {

//     }
//   }
//   public static getInputPlaceholder(): IInputViewModel {
//     return new InputViewModel({
//       inputEntityKey: null,
//       inputType: ReportInputTypeEnum.Unknown,
//       inputName: 'report.inputPlaceholder',
//       interval: null,
//       unitDisplay: '',
//       isNormalizable: false
//     });
//   }
// }

// TODO: this should be renamed IReportViewModel to follow the convention
// of naming the models coming from the API
export interface IReport extends IEntityViewModel {
  checksum: string;
  chartSettings: IReportChartSettings;
  reportInputs: Array<IReportInputViewModel>;
  pendingSnapshot: IReportSnapshot;
  timeOptions: IReportTimeOptions;
  isActive: boolean;
  isTemplate: boolean;
  canEdit: boolean;
  isNormalized: boolean;
  drillDownRequested: boolean;
}
// TODO: this should use a different name, maybe IReport or maybe it could be merged with above.
// If you change the name also change the name of the implementing class
export interface IReportViewModel extends IReport {
  readonly validInputs: IReportInputViewModel[];
  readonly placeholderInputs: IReportInputViewModel[];
  prepareForNewSave(reportName: string): void;
  determineRange(existingRange: IDateRange): void;
  isDirty(): boolean;
  resetChecksum(): void;
  findInput(inputs: IReportInputBase): IReportInputViewModel;
  findInputs(inputs: IReportInputBase[]): IReportInputViewModel[];
}

export class ReportViewModel implements IReportViewModel {
  public key: string = null;
  public name: string = null;
  public checksum: string = null;
  public chartSettings: IReportChartSettings = {
    is3D: false,
    showGridLines: false,
    leftUnitOfMeasure: UnitsOfMeasure.WET,
    rightUnitOfMeasure: UnitsOfMeasure.DRY,
    syncAxisLimits: false
  };
  public createdAt: string = null;
  public createdBy: string = null;
  public canEdit: boolean;
  public reportInputs: Array<IReportInputViewModel> = [];
  public isActive: boolean = true;
  public isTemplate: boolean = false;
  public isNormalized: boolean = true;
  public lastModifiedAt: string = null;
  public lastModifiedBy: string = null;
  public timeOptions: IReportTimeOptions = new ReportTimeOptions();
  public pendingSnapshot: IReportSnapshot = null;
  public drillDownRequested: boolean = false;

  public get validInputs(): IReportInputViewModel[] {
    return (this.reportInputs || []).filter(input => input.isPlaceholder !== true);
  }
  public get placeholderInputs(): IReportInputViewModel[] {
    return (this.reportInputs || []).filter(input => input.isPlaceholder === true);
  }

  constructor(report?: IReport) {
    if (report != null) {
      Object.assign(this, report);
      this.reportInputs = (report.reportInputs || []).map(
        (input: IReportInputViewModel) => new ReportInputViewModel(input)
      );
      this.chartSettings = new ReportChartSettings(report.chartSettings);
      this.timeOptions = new ReportTimeOptions(report.timeOptions);
      // this.resetChecksum();
    }
  }

  public static rangeTypeToDateRange(rangeType: ReportRangeType, year: number, existingRange: IDateRange): IDateRange {
    let from: Date = new Date();
    let to: Date = new Date();
    if (rangeType === ReportRangeType.Custom) {
      // The logic for non-Custom assumes From and To are today to begin with.
      if (existingRange != null) {
        from = new Date(existingRange.fromDate);
        to = new Date(existingRange.toDate);
      }
    } else {
      const padRangeTypes: Array<ReportRangeType> = [
        ReportRangeType.Today,
        ReportRangeType.Yesterday,
        ReportRangeType.SpecificYear,
        ReportRangeType.Custom
      ];
      from.setHours(0);
      from.setMinutes(0);
      from.setSeconds(0);
      from.setMilliseconds(0);
      if (padRangeTypes.includes(rangeType)) {
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(0);
      }
    }
    switch (rangeType) {
      case ReportRangeType.Custom:
        break;
      case ReportRangeType.Today:
        break;
      case ReportRangeType.Yesterday:
        to.setDate(to.getDate() - 1);
        from.setDate(from.getDate() - 1);
        break;
      case ReportRangeType.Last24Hours:
        to = new Date();
        from = new Date();
        from.setDate(from.getDate() - 1);
        break;
      case ReportRangeType.Last7Days:
        from.setDate(from.getDate() - 6);
        break;
      case ReportRangeType.Last30Days:
        from.setDate(from.getDate() - 29);
        break;
      case ReportRangeType.Last90Days:
        from.setDate(from.getDate() - 89);
        break;
      case ReportRangeType.Last180Days:
        from.setDate(from.getDate() - 179);
        break;
      case ReportRangeType.MonthToDate:
        from.setDate(1);
        break;
      case ReportRangeType.YearToDate:
        from.setMonth(0, 1);
        break;
      case ReportRangeType.SpecificYear:
        from.setFullYear(year);
        from.setMonth(0, 1);
        to.setFullYear(year);
        to.setMonth(11, 31);
        break;
    }
    return new DateRange({
      fromDate: from.toISOString(),
      toDate: to.toISOString()
    });
  }

  public prepareForNewSave(reportName: string): void {
    this.key = null;
    this.name = reportName;
  }
  public isDirty(): boolean {
    return this.checksum !== this.getChecksum();
  }

  private getChecksum(): string {
    const copy: IReportViewModel = Object.assign({}, this);
    copy.checksum = null;
    return JSON.stringify(copy);
  }
  public resetChecksum(): void {
    this.checksum = this.getChecksum();
  }
  public determineRange(existingRange: IDateRange): void {
    const rangeType = this.timeOptions.reportTime.reportRangeType;
    const year = this.timeOptions.reportTime.year;
    const newDateRange = ReportViewModel.rangeTypeToDateRange(rangeType, year, existingRange);
    const prevIsDirty = this.isDirty();
    try {
      this.timeOptions.reportTime.timePeriod.dateRange = newDateRange;
    } finally {
      if (!prevIsDirty) {
        // Change to report range type should not make the report dirty if it wasn't.
        this.resetChecksum();
      }
    }
  }
  public findInput(input: IReportInputBase): IReportInputViewModel {
    return this.reportInputs.find(ri => ri.equals(input));
  }
  public findInputs(inputs: IReportInputBase[]): IReportInputViewModel[] {
    let results: IReportInputViewModel[] = [];
    inputs = inputs || [];
    if (inputs.length > 0) {
      const map: { [key: string]: ReportInputTypeEnum } = inputs.reduce((acc, input) => {
        acc[input.inputEntityKey] = input.inputType;
        return acc;
      }, {});
      results = this.reportInputs.filter(ri => map[ri.inputEntityKey] && map[ri.inputEntityKey] === ri.inputType);
    }

    return results;
  }
}

export interface IReportDataRow {
  cost: number;
  date: Date;
  inputName: string;
  key: string;
  scheduleName: string;
  value: number;
  min: number;
  max: number;
  avg: number;
  cusum: number;
  valueUnit: UnitsOfMeasure;
  costUnit: UnitsOfMeasure;
  minUnit: UnitsOfMeasure;
  maxUnit: UnitsOfMeasure;
  avgUnit: UnitsOfMeasure;
  cusumUnit: UnitsOfMeasure;
}

export interface IReportSnapshot {
  report: IReportViewModel;
  executedReport: IReportViewModel;
  reportData: Array<IReportDataViewModel>;
  reportSummary: IDataTable<IReportSummaryRow>;
  reportTable: IDataTable<IReportDataRow>;
}

export class ReportSnapshot {
  public report: IReportViewModel;
  public executedReport: IReportViewModel;
  public reportData: Array<IReportDataViewModel>;
  public reportSummary: IDataTable<IReportSummaryRow>;
  public reportTable: IDataTable<IReportDataRow>;

  constructor(snapshot?: IReportSnapshot) {
    if (snapshot != null) {
      this.report = new ReportViewModel(snapshot.report);
      this.executedReport = new ReportViewModel(snapshot.executedReport);
      this.reportData = snapshot.reportData.slice(0);
      this.reportSummary = new DataTable(snapshot.reportSummary);
      this.reportTable = new DataTable(snapshot.reportTable);
    }
  }
}

/**
 * State necessary to rebuild a report page by making a
 * new request to the backend.
 */
export interface IReportExecutionState {
  report: IReportViewModel;
  // inputs needed for lookups.
  inputs: Array<IInputViewModel>;
}

export class ReportExecutionState {
  public report: IReportViewModel;
  public inputs: Array<IInputViewModel>;

  constructor(object?: IReportExecutionState) {
    if (object != null) {
      this.report = new ReportViewModel(object.report);
      this.inputs = cloneArray(object.inputs);
    }
  }
}

export interface IReportInput extends IReportInputBase {
  displayValue: string;
  unitOfMeasurement: UnitOfMeasurementEnum;
  axis: ReportAxis;
  chartType: SeriesTypes;
  sortOrder: number;
  isPlaceholder?: boolean;
}

export interface IGeneraReportInput extends IReportInput {
  equipmentConfigKey: string;
  displayName: string;
}

export interface IReportInputViewModel extends IGeneraReportInput {
  equals(input: IReportInputBase): boolean;
}

export class ReportInputViewModel implements IReportInputViewModel {
  public key: string = newGuid();
  public inputType: ReportInputTypeEnum = ReportInputTypeEnum.Unknown;
  public inputEntityKey: string;
  public equipmentConfigKey: string;
  public customerSiteKey: string;
  public displayValue: string = null;
  public axis: ReportAxis = ReportAxis.Left;
  public chartType: SeriesTypes = SeriesTypes.Line;
  public sortOrder: number;
  public isPlaceholder?: boolean;
  public displayName: string = '';
  public unitOfMeasurement: UnitOfMeasurementEnum;

  constructor(reportInput?: IGeneraReportInput | IReportInputViewModel) {
    if (reportInput != null) {
      Object.assign(this, reportInput);
    }
  }

  public equals(input: IReportInputBase): boolean {
    return input && input.key === this.key && input.inputType === this.inputType;
  }
}
