interface Array<T> {
  /**
   * Returns an array of array of items in chunks.
   * @param size the number of items in each chunk
   */
  chunk(size: number): Array<Array<T>>;

  /**
   * Returns a key/value pair of array grouped by provided prop
   * @param prop the prop to group by
   */
  groupBy(prop: string): Map<string, Array<T>>;

  /**
   * Returns a boolean checking if this array is identical to given array
   * @param other the array to compare with
   */
  identicalTo(other: Array<T>): boolean;

  /**
   * Compares current array with given array and returns if they are equal or not, ignoring the order
   * @param other the array to compare with
   */
  equalsTo(other: Array<T>, ignoreOrder?: boolean): boolean;
}

// eslint-disable-next-line no-extend-native
Array.prototype.chunk = function(size: number) {
  const result = [];
  const arrayCopy = [...this];
  while (arrayCopy.length > 0) {
    result.push(arrayCopy.splice(0, size));
  }
  return result;
};

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function<T>(prop: string) {
  return this.reduce((result: Map<string, Array<T>>, currentValue) => {
    if (!result.has(currentValue[prop])) {
      result.set(currentValue[prop], []);
    }
    result.get(currentValue[prop]).push(currentValue);
    // (result.get(prop) = result[currentValue[prop]] || []).push(currentValue);
    return result;
  }, new Map<string, Array<T>>());
};

// eslint-disable-next-line no-extend-native
Array.prototype.identicalTo = function<T>(other: Array<T>) {
  return this.length === other.length && other.every((val, index) => val === this[index]);
};

// eslint-disable-next-line no-extend-native
Array.prototype.equalsTo = function<T>(other: Array<T>, ignoreOrder?: boolean) {
  if (ignoreOrder) {
    return this.length === other.length && other.every(val => this.includes(val));
  }
  return this.identicalTo(other);
};
