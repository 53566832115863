import { Context } from '@nuxt/types';
import { IVueI18n } from 'vue-i18n/types';

export default function <Nuxt, Middleware>({ isHMR, app, store, route, params, error, redirect }: Context) {
  // If middleware is called from hot module replacement, ignore it
  if (isHMR) {
    return;
  }
  const i18n: IVueI18n = app.i18n as IVueI18n;
  const defaultLocale = i18n.fallbackLocale;
  // Get locale from params
  const locale = params.lang || defaultLocale;
  if (!store.state.locales.includes(locale)) {
    return error({ message: 'This page could not be found.', statusCode: 404 });
  }
  // Set locale
  store.commit('setLocale', locale);
  i18n.locale = store.state.locale;
  // If route is /<defaultLocale>/... -> redirect to /...
  if (locale === defaultLocale && route.fullPath.indexOf('/' + defaultLocale) === 0) {
    const toReplace = '^/' + defaultLocale + (route.fullPath.includes('/' + defaultLocale + '/') ? '/' : '');
    const re = new RegExp(toReplace);
    return redirect(route.fullPath.replace(re, '/'));
  }
}
