import BaseService from '@/services/base-service';
import { AxiosResponse, AxiosInstance } from 'axios';

export default class BurnerReadingService extends BaseService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('heater', axios, '', baseUrl);
  }

  public async getBurnerReadings(assetKey: string): Promise<any> {
    const response: AxiosResponse<any> = await this.http.get(
      this.getUrl(`${assetKey}/burnertree`)
    );
    return response;
  }
}
