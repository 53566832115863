import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IAeAssetDetailViewModel, IAeAssetDetailsService } from '@/view-models';
import { getAeAxios } from '@/shared/http';

export default class AeAssetDetailsService extends BaseService implements IAeAssetDetailsService {
  constructor(axios: AxiosInstance) {
    super('asset-details', axios, '');
  }

  public static factory(): AeAssetDetailsService {
    return new AeAssetDetailsService(getAeAxios());
  }

  public getAssetDetails(assetKey: string): Promise<IAeAssetDetailViewModel> {
    return this.httpClient.get<IAeAssetDetailViewModel>(assetKey);
  }
}
