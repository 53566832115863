import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IEmaCustomerService } from '@/view-models';
import { IEmaCustomerOpScoreViewModel } from '@/view-models/ema-view-models';
import { getEmaAxios } from '@/shared/http';

export default class EmaCustomerService extends BaseService implements IEmaCustomerService {
  constructor(axios: AxiosInstance) {
    super('customer', axios, '');
  }

  public static factory(): IEmaCustomerService {
    return new EmaCustomerService(getEmaAxios());
  }

  public async getHeatersOpportunityScores(customerKey: string, heaterKeys: string[]): Promise<Record<string, number>> {
    const response = await this.httpClient.post<IEmaCustomerOpScoreViewModel[]>(`${customerKey}/opportunity-scores`, heaterKeys);
    return (response ?? []).reduce((acc, item) => {
      acc[item.heaterKey] = item.opportunityScore;
      return acc;
    }, {} as Record<string, number>);
  }
}
