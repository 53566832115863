import Vue from 'vue';
import { Context } from '@nuxt/types';
import VeeValidate from 'vee-validate';
import enValidationMessages from 'vee-validate/dist/locale/en';
import frValidationMessages from 'vee-validate/dist/locale/fr';

export default function<Nuxt, Plugin>({ app }: Context): void {
	Vue.use(VeeValidate, {
		delay: 1,
		dictionary: {
			// TODO handle other languages
			'en-US': enValidationMessages,
			'fr-FR': frValidationMessages
		},
		fieldsBagName: 'veeFields',
		i18nRootKey: 'validation',
		i18n: app.i18n,
		inject: true
	});
}
