import { Plugin } from '@nuxt/types';
import { authClient, authErrorHandler } from '@/shared/auth';

const plugin: Plugin = async ({ $vxm, error: errorHandler }): Promise<void> => {
  try {
    if (await authClient.isAuthenticated()) {
      // If user is logged in, fill the store with data
      await $vxm.user.getPermissions();
    }
  } catch (error) {
    authErrorHandler(true, error, errorHandler);
  }
};

export default plugin;
