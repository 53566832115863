import 'es6-promise/auto';
import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { IUser, IUserManagementService, IUserViewModel } from '@/view-models';
import { IAssignedCustomer } from '@/view-models/assets';

export default class UserManagementService extends BaseService implements IUserManagementService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('users', axios, '', baseUrl);
  }

   public getUser(): Promise<IUserViewModel> {
    return this.httpClient.get<IUserViewModel>('current');
  }

  public getAssignedCustomers(): Promise<Array<IAssignedCustomer>> {
    return this.httpClient.get('assignedCustomers');
  }

  public getPermissions(): Promise<string[]> {
    return this.httpClient.get<string[]>('userPermission');
  }

  public async changeActiveCustomer(customerKey: string): Promise<void> {
    await this.httpClient.post<void>(`setActiveCustomer/${customerKey}`);
  }

  public async changePassword(request: IUserViewModel): Promise<void> {
    const userKey = request.key;
    await this.httpClient.post<void>(`${userKey}/resend-email`);
  }

  public updateCurrentUserLoginStatus(): Promise<IUser> {
    return this.httpClient.patch<IUser>('current/loginStatus');
  }
}
