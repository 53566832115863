











































// Libraries
import { Component } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import CustomerSwitch from '@/components/layout/CustomerSwitch.vue';
import PasswordResetModal from '@/components/layout/PasswordResetModal.vue';

// Stores

@Component({
  components: {
    CustomerSwitch,
    PasswordResetModal
  },
	name: 'header-nav-bar'
})
export default class HeaderNavBar extends BaseComponent {
  // Vue.js Props
  // VUEX
  // Properties
  public promptLogoutModal: boolean = false;
  public passwordRequestModal: boolean = false;

  // Fields
  // Getters
  public get isFirstTimeUser() {
    return this.$vxm.user.firstTimeUser;
  }
  private get isAssetsRoute(): boolean {
    return this.$route.fullPath.startsWith('/assets');
  }

  public get fullName(): string {
    return String.isNullOrWhiteSpace(this.$vxm.user.authUser.firstName)
      ? this.$vxm.user.authUser.username
      : `${this.$vxm.user.authUser.firstName} ${this.$vxm.user.authUser.lastName}`;
  }

  public get isSsoUser(): boolean {
    return this.$vxm.user.authUser.isSsoUser;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}

  // Private Methods
  // Helper Methods
  // Event Methods
  public async openPasswordChangeModal() {
    try {
      await this.$vxm.user.changePassword(this.$vxm.user.authUser);
      this.passwordRequestModal = true;
    } catch (error) {
      this.catch(error, false);
    };
  }
  public signOut(): void {
    this.$vxm.user.logOut();
  }
  public toggleShowPasswordRequestModal(flag: boolean): void {
    this.passwordRequestModal = flag;
  }
  // Watchers
  // Emitters
}
