import { AxiosInstance } from 'axios';
import BaseService from '@/services/base-service';
import { AccessSettingsEnum } from '@/enums/variables';
import {
  IReportAssetVariableViewModel,
  IReportVariablesViewModel
} from '@/view-models/report-variables-tree/report-variable-node';

export class ReportVariableService extends BaseService {
  constructor(axios: AxiosInstance, baseUrl: string) {
    super('assets', axios, '', baseUrl);
  }

  public async getAssetVariables(assetKey: string): Promise<IReportAssetVariableViewModel[]> {
    const response = await this.httpClient.get<IReportVariablesViewModel>(
      `${assetKey}/variables`,
      { accessSetting: AccessSettingsEnum.Dashboards }
    );
    return response?.variables ?? [];
  }
}
