


































// Libraries
import { Component } from 'nuxt-property-decorator';
import { numericSorter } from '@/shared/array-utils';
// View Models
import { Permission } from '@/view-models';
// Components
import BaseComponent from '@/shared/BaseComponent';
import Icon from '@/components/shared/Icon.vue';
import { IIcon } from '@/components/types';
// Services
// Stores

export interface MenuSection {
  type: 'main' | 'admin';
  text: string;
  order: number;
  show: boolean;
}

export interface IconLink {
  to?: string;
  href?: string;
  external?: boolean;
  click?: (event: Event) => void | Promise<void>;
  id: string;
  icon: IIcon;
}

export interface MenuItem extends IconLink {
  section: MenuSection;
  text: string;
  name: string;
  besideButton?: IconLink;
  order: number;
  exact: boolean;
  show: boolean;
}

export interface MenuArea {
  section: MenuSection;
  items: MenuItem[];
}

@Component({
  name: 'main-navigation-phase-2',
  components: {
    Icon
  }
})
export default class MainNavigationPhase2 extends BaseComponent {
  // VUE.JS Props
  // VUEX
  // Properties
  // Fields
  // Getters
  public get isFirstTimeUser() {
    return this.$vxm.user.firstTimeUser;
  }
  public get menuAreas(): MenuArea[] {
    const mainSection: MenuSection = { type: 'main', text: 'global.nav.main', order: 0, show: true };
    const orgMgmtPermission: Permission = Permission.ManageCustomerAccounts;

    let areas: MenuArea[] = [
      {
        section: mainSection,
        items: [
          {
            section: mainSection,
            text: 'global.nav.phase_2.home',
            name: 'index',
            to: '/',
            id: this.elementId('mainNavigationPhase2', 'homeView'),
            icon: { mdi: 'mdi mdi-home' },
            order: 1,
            exact: true,
            show: true
          },
          {
            section: mainSection,
            text: 'global.nav.phase_2.assets',
            name: 'assets',
            to: '/assets',
            id: this.elementId('mainNavigationPhase2', 'assetView'),
            icon: { mdi: 'mdi mdi-map-marker-radius' },
            order: 2,
            exact: false,
            show: this.$vxm.user.hasAssets()
          },

          // {
          //   section: mainSection,
          //   text: 'global.nav.phase_2.reports',
          //   name: 'reports',
          //   to: ChildAppRoutesEnum.CustomerAssetReportingBasePath,
          //   click: () => {
          //     if (this.$route.path === ChildAppRoutesEnum.CustomerAssetReportingBasePath) {
          //       HelperMethods.pushParentRouteChangeEvent(this.$route);
          //     }
          //   },
          //   id: this.elementId('mainNavigationPhase2', 'accountView'),
          //   icon: { mdi: 'mdi mdi-file-document' },
          //   order: 3,
          //   exact: false,
          //   show: this.$vxm.user.hasAssets() && this.$vxm.user.hasPermission(Permission.ManageReports)
          // },
          {
            section: mainSection,
            text: 'global.nav.phase_2.utilities',
            name: 'utilities',
            to: '/utilities',
            id: this.elementId('mainNavigationPhase2', 'utilitiesView'),
            icon: { mdi: 'mdi mdi-tools' },
            order: 4,
            exact: false,
            show:
              this.$vxm.user.hasAssets() &&
              (this.$vxm.user.hasPermission(Permission.CreateAssetDiagram) ||
                this.$vxm.user.hasPermission(Permission.ManageAssetConfiguration) ||
                this.$vxm.user.hasPermission(Permission.ManageHierarchy) ||
                this.$vxm.user.hasPermission(Permission.ViewHydraulicModel))
          },
          {
            section: mainSection,
            text: 'global.nav.phase_2.admin',
            name: 'admin',
            to: '/admin',
            id: this.elementId('mainNavigationPhase2', 'adminView'),
            icon: { mdi: 'mdi mdi-cog' },
            order: 5,
            exact: false,
            show:
              this.$vxm.user.hasPermission(Permission.ViewPlantConnectorAgent) ||
              this.$vxm.user.hasPermission(orgMgmtPermission) ||
              this.$vxm.user.hasPermission(Permission.ManageKESCompanies) ||
              this.$vxm.user.hasPermission(Permission.ManageUsers)
          }
          // {
          //   section: mainSection,
          //   text: 'Documents',
          //   name: 'documents',
          //   href: this.$vxm.root.env.documentStoreUrl,
          //   external: false,
          //   id: this.elementId('mainNavigationPhase2', 'documentsView'),
          //   icon: { mdi: 'mdi mdi-file-document-multiple' },
          //   order: 6,
          //   exact: false,
          //   show: true
          // }
        ]
      }
    ];

    areas = this.processMenuAreas(areas);

    return areas;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private processMenuAreas(areas: MenuArea[]) {
    return areas
      .reduce((processed: MenuArea[], area: MenuArea) => {
        if (area.section.show) {
          area.items = this.processMenuItems(area.items);
          area.section.text = this.$t(area.section.text).toString();
          processed.push(area);
        }
        return processed;
      }, [])
      .sort(numericSorter<MenuArea>((area: MenuArea) => area.section.order));
  }
  private processMenuItems(items: MenuItem[]) {
    return items
      .reduce((processed: MenuItem[], item: MenuItem) => {
        if (item.show) {
          item.text = this.$t(item.text).toString();
          processed.push(item);
        }
        return processed;
      }, [])
      .sort(numericSorter<MenuItem>(item => item.order));
  }

  public getRoutePath(item) {
    if (item.name === 'assets-key') {
      return { name: item.name };
    } else {
      return { path: item.to };
    }
  }

  // Helper Methods
  // Event Methods
  public handleMainClick(event: Event, item: MenuItem): void {
    if (item.click != null && typeof item.click === 'function') {
      item.click(event);
    }
    if (item.text !== this.$t('global.nav.phase_2.assets').toString()) {
      this.$vxm.assets.setActiveAsset(null);
    }
  }
  // Watchers
  // Emitters
}
