import { PagedResult } from '@/components/types/result';

export interface IDataTable<T> {
  headerTranslationStrings: string[];
  valueProperties: string[];
  pageProperties: PagedResult<T>
  rows: Array<T>;
}

export class DataTable<T> implements IDataTable<T> {
  public headerTranslationStrings: string[] = [];
  public valueProperties: string[] = [];
  public pageProperties: PagedResult<T>;
  public rows: Array<T> = [];

  constructor(dataTable?: IDataTable<T>) {
  	if (dataTable != null) {
  		Object.assign(this, dataTable);
  	}
  }
}
