
import { MeasurementTypesEnum } from '@/enums/variables';
import { MeasurementSystemsEnum, UnitOfMeasurementEnum } from '@/enums';
import { ISelectItem } from '@/components/types';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models/measurement-systems-view-model';
import VueI18n from 'vue-i18n';

const pressureDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.Pa,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.mmH2O,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.inH2O,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.mmH2O,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.Pa
};

const temperatureDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.degK,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.degC,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.degF,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.degC,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.degK
};

const distanceDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.m,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.m,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.ft,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.mm,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.m
};

const flowRateDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.kgs,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.kgs,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.lbs,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.kghr,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.kgs
};

const concentrationDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.ppm,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.ppm,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.ppm,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.percentmol,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.ppm
};

const energyDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.W,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.W,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.BTUh,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.Mmkcalhr,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.W
};

const fuelPropertyEnergyDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.Jkg,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.Jkg,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.BTUlbm,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.kCalkg,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.BTUscf
};

const areaDefaultUoMBySystem: {[system in MeasurementSystemsEnum]: UnitOfMeasurementEnum} = {
  [MeasurementSystemsEnum.SIUnits]: UnitOfMeasurementEnum.sqm,
  [MeasurementSystemsEnum.MetricUnits]: UnitOfMeasurementEnum.sqm,
  [MeasurementSystemsEnum.ImperialUnits]: UnitOfMeasurementEnum.sqft,
  [MeasurementSystemsEnum.PTTGCUnits]: UnitOfMeasurementEnum.sqm,
  [MeasurementSystemsEnum.DefaultUnits]: UnitOfMeasurementEnum.sqft
};

export function getMeasurementTypeDefaultUoM(
  measurementType: MeasurementTypesEnum,
  measurementSystem: MeasurementSystemsEnum
): UnitOfMeasurementEnum {
  let unitOfMeasurement: UnitOfMeasurementEnum;
  switch (measurementType) {
    case MeasurementTypesEnum.Pressure:
      unitOfMeasurement = pressureDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.Temperature:
      unitOfMeasurement = temperatureDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.Distance:
      unitOfMeasurement = distanceDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.FlowRate:
      unitOfMeasurement = flowRateDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.Concentration:
      unitOfMeasurement = concentrationDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.Energy:
      unitOfMeasurement = energyDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.O2MoleFraction:
      unitOfMeasurement = UnitOfMeasurementEnum.Percent;
      break;
    case MeasurementTypesEnum.FuelPropertyEnergy:
      unitOfMeasurement = fuelPropertyEnergyDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.Numeric:
      unitOfMeasurement = measurementSystem === MeasurementSystemsEnum.PTTGCUnits
          ? UnitOfMeasurementEnum.Percent : unitOfMeasurement = UnitOfMeasurementEnum.Fraction;
      break;
    case MeasurementTypesEnum.Boolean:
      unitOfMeasurement = UnitOfMeasurementEnum.Bool;
      break;
    case MeasurementTypesEnum.Text:
      unitOfMeasurement = UnitOfMeasurementEnum.String;
      break;
    case MeasurementTypesEnum.Area:
      unitOfMeasurement = areaDefaultUoMBySystem[measurementSystem];
      break;
    case MeasurementTypesEnum.Unknown:
    default:
      unitOfMeasurement = UnitOfMeasurementEnum.Default;
      break;
  }
  return unitOfMeasurement;
}

export function getUoMBasedOnMeasurementType(measurementType: MeasurementTypesEnum): UnitOfMeasurementEnum[] {
  const result: UnitOfMeasurementEnum[] = [];

  switch (measurementType) {
    case MeasurementTypesEnum.Pressure:
      result.push(
        UnitOfMeasurementEnum.Pa,
        UnitOfMeasurementEnum.psi,
        UnitOfMeasurementEnum.psig,
        UnitOfMeasurementEnum.psia,
        UnitOfMeasurementEnum.inH2O,
        UnitOfMeasurementEnum.mmH2O,
        UnitOfMeasurementEnum.inHg,
        UnitOfMeasurementEnum.bar,
        UnitOfMeasurementEnum.barg,
        UnitOfMeasurementEnum.mbar,
        UnitOfMeasurementEnum.mbarg
      );
      break;
    case MeasurementTypesEnum.Temperature:
      result.push(
        UnitOfMeasurementEnum.degF,
        UnitOfMeasurementEnum.degC,
        UnitOfMeasurementEnum.degR,
        UnitOfMeasurementEnum.degK
      );
      break;
    case MeasurementTypesEnum.Distance:
      result.push(
        UnitOfMeasurementEnum.ft,
        UnitOfMeasurementEnum.in,
        UnitOfMeasurementEnum.m,
        UnitOfMeasurementEnum.mm
      );
      break;
    case MeasurementTypesEnum.FlowRate:
      result.push(
        UnitOfMeasurementEnum.kgs,
        UnitOfMeasurementEnum.kghr,
        UnitOfMeasurementEnum.lbms,
        UnitOfMeasurementEnum.lbmhr,
        UnitOfMeasurementEnum.klbmhr,
        UnitOfMeasurementEnum.lbs
      );
      break;
    case MeasurementTypesEnum.Concentration:
      result.push(
          UnitOfMeasurementEnum.ppm,
          UnitOfMeasurementEnum.percentmol
      );
      break;
    case MeasurementTypesEnum.Energy:
      result.push(
        UnitOfMeasurementEnum.W,
        UnitOfMeasurementEnum.MW,
        UnitOfMeasurementEnum.Gcalhr,
        UnitOfMeasurementEnum.MMBTUhr,
        UnitOfMeasurementEnum.Mmkcalhr,
        UnitOfMeasurementEnum.BTUh
      );
      break;
    case MeasurementTypesEnum.O2MoleFraction:
      result.push(
        UnitOfMeasurementEnum.DRY,
        UnitOfMeasurementEnum.WET,
        UnitOfMeasurementEnum.Percent,
        UnitOfMeasurementEnum.Fraction
      );
      break;
    case MeasurementTypesEnum.FuelPropertyEnergy:
      result.push(
          UnitOfMeasurementEnum.BTUscf,
          UnitOfMeasurementEnum.kCalkg,
          UnitOfMeasurementEnum.BTUlbm,
          UnitOfMeasurementEnum.Jkg
      );
      break;
    case MeasurementTypesEnum.Numeric:
      result.push(UnitOfMeasurementEnum.Fraction, UnitOfMeasurementEnum.Percent);
      break;
    case MeasurementTypesEnum.Boolean:
      result.push(UnitOfMeasurementEnum.Bool);
      break;
    case MeasurementTypesEnum.Text:
      result.push(UnitOfMeasurementEnum.String);
      break;
    case MeasurementTypesEnum.Area:
      result.push(
          // Leaving off sqin and sqcm for now until requested later
          UnitOfMeasurementEnum.sqft,
          // UnitOfMeasurementEnum.sqin,
          UnitOfMeasurementEnum.sqm
          // UnitOfMeasurementEnum.sqcm
      );
      break;
    case MeasurementTypesEnum.Unknown:
    default:
      result.push(UnitOfMeasurementEnum.Default);
      break;
  }

  return result;
}

export function uomsAsSelectItemList(
  uoms: IAvailableMeasurementSystemsViewModel[],
  i18n: VueI18n): ISelectItem<string>[] {
  return uoms.reduce((acc, option) => {
    if (option != null) {
      const i18nKey = `dashboard.measurementSystems.${option.systemName}`;
      const text = i18n.te(i18nKey) ? i18n.t(i18nKey).toString() : '';
      acc.push({
        text,
        value: option.systemKey
      });
    }
    return acc;
  }, [] as ISelectItem<string>[]);
}
