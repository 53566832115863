



















// Libraries
import { Component } from 'nuxt-property-decorator';
// View Models
// Components
import BaseComponent from '@/shared/BaseComponent';
import LanguageSelection from '@/components/layout/LanguageSelection.vue';
// Stores

@Component({
  name: 'footer-section',
  components: {
    LanguageSelection
  }
})
export default class FooterSection extends BaseComponent {
  // Vue.js Props
  // VUEX
  // Properties
  public dynamicDate: number = new Date().getFullYear();
  // public overflowValue: string = 'hidden';
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // public shown() {
  //   this.overflowValue = 'visible';
  // }
  // public hidden() {
  //   this.overflowValue = 'hidden';
  // }
  // Event Methods
  // Watchers
  // Emitters
}
