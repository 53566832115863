export enum DiagnosticColorState {
  'Gray'= 'gray',
  'Yellow'= 'yellow',
  'Orange'= 'orange',
  'Green'= 'green',
  'Physics'= 'Physics',
  'PhysicsAndAI'= 'PhysicsAndAI',
  'Normal'= 'Normal',
  'DiagnosticState' = 'state'
}
