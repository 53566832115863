import { AxiosResponse, AxiosInstance } from 'axios';
import moment from 'moment';
import VueI18n from 'vue-i18n';
import { Route } from 'vue-router';
import Vue from 'vue';
import EventBus, { Events } from '@/shared/event-bus';
import { LoggerService } from '@/services';
import { ErrorLogViewModel, IErrorViewModel } from '@/view-models';
import { authClient } from '@/shared/auth';
import { DateTimeInput } from '@/view-models/report-time-model';
import { getAxios } from '@/shared/http';
import * as dateUtils from './date-time-utils';
import * as stringUtils from './string-utils';

export default class HelperMethods {
  public static errorModalEmission = 'error-modal-event';

  public static enumKeys(enumType: object): string[] {
    return Object.keys(enumType)
      .map(k => enumType[k])
      .filter(v => typeof v === 'string');
  }

  /**
   * format a date string
   * @param date
   * @param format
   */
  public static getDateString(date: string, format: string = 'MM/DD/YY HH:mm'): string {
    const oDate: moment.Moment = moment(date);
    return oDate.format(format);
  }

  /**
   * parse a percentage into a readable string
   * @param percent
   * @param decimalPlaces
   * @param isDecimal
   * @returns {string}
   */
  public static getPercentString(percent: number, decimalPlaces: number = 2, isDecimal: boolean = false): string {
    if (isDecimal) {
      percent *= 100;
    }
    return HelperMethods.roundDecimal(percent, decimalPlaces);
  }

  /**
   * Attempt to extract the http response code from an error.
   * @param error
   */
  public static extractHttpResponseCode(error: any): number {
    let statusCode: number = null;

    if (error.response != null) {
      const response: AxiosResponse = error.response;
      if (error.response.data != null && error.response.data !== '') {
        error = response.data;
      }
      statusCode = response.status;
    }
    return statusCode;
  }

  /**
   * @param axios
   * @param error
   * @param options
   */
  public static catch(axios: AxiosInstance, error: any, options: ICatchOptions = null): void {
    if (options == null) {
      this.defaultCatch(axios, error);
    } else {
      switch (this.extractHttpResponseCode(error)) {
        case 401:
          this.handleResponseOverride(options.http401, axios, error, options);
          break;
        case 403:
          this.handleResponseOverride(options.http403, axios, error, options);
          break;
        case 404:
          this.handleResponseOverride(options.http404, axios, error, options);
          break;
        case 500:
          this.handleResponseOverride(options.http500, axios, error, options);
          break;
        default:
          this.handleResponseOverride(options.httpUnspecified, axios, error, options);
          break;
      }
    }
  }

  /**
   * @param responseMethod
   * @param axios
   * @param error
   * @param options
   */
  public static handleResponseOverride(
    responseMethod: Function,
    axios: AxiosInstance,
    error: any,
    options: ICatchOptions = null
  ): void {
    if (responseMethod != null) {
      responseMethod(error);
    } else {
      this.defaultCatch(axios, error, options.sendLog);
    }
  }

  /**
   * global Error Call
   * @param axios
   * @param error
   * @param sendLog
   * @returns {void}
   */
  public static defaultCatch(axios: AxiosInstance, error: any, sendLog: boolean = true, logSilently: boolean = false, errorTag: string = null): void {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error == null) {
      return;
    }

    const logEntry: ErrorLogViewModel = HelperMethods.errorToErrorLogViewModel(error);
    if (errorTag != null) {
      logEntry.Message = `[${errorTag}]: ${logEntry.Message}`;
    }

    let message: string | null;
    // const passMessage = error.ExceptionMessage != null || error === logEntry.Message;
    const responseMessage = error?.response?.data?.message;
    const isUnauthorizedResponse = error?.response?.data && error?.response?.data?.className === 'KES.Common.Exceptions.UserUnauthorizedAccessException';

    if (isUnauthorizedResponse && responseMessage) {
      message = responseMessage;
    } else {
      switch (this.extractHttpResponseCode(error)) {
        case 401:
          authClient.logOut({ saveReturnPath: true });
          return;
        case 403:
          message = 'errorModal.forbidden';
          break;
        case 404:
          message = 'errorModal.notFound';
          break;
        case 500:
          message = 'errorModal.internalError';
          break;
        default:
          // message = passMessage ? logEntry.Message : null;
          message = logEntry.Message;
          break;
      }
    }

    const errorViewModel: IErrorViewModel = {
      message,
      hash: logEntry.ErrorCode,
      id: logEntry.ClientErrorId,
      redirect: null,
      isUnauthorizedResponse
    };

    HelperMethods.emitError(sendLog, logSilently, axios, logEntry, errorViewModel);
  }

  private static emitError(sendLog: boolean, logSilently: boolean, axios: AxiosInstance, logEntry: ErrorLogViewModel, errorViewModel: IErrorViewModel) {
    if (sendLog && !logSilently) {
      HelperMethods.sendLogError(axios, logEntry)
        .then(() => {
          EventBus.$emit(HelperMethods.errorModalEmission, errorViewModel);
        });
    } else if (sendLog && logSilently) {
      HelperMethods.sendLogError(axios, logEntry);
    } else {
      EventBus.$emit(HelperMethods.errorModalEmission, errorViewModel);
    }
  }

  public static logError(axios: AxiosInstance, error: any) {
    // eslint-disable-next-line no-console
    console.error(error);

    const logEntry: ErrorLogViewModel = HelperMethods.errorToErrorLogViewModel(error);
    HelperMethods.sendLogError(axios, logEntry);
  }

  public static errorToErrorLogViewModel(error: any): ErrorLogViewModel {
    const logEntry: ErrorLogViewModel = new ErrorLogViewModel();

    if (error.ExceptionMessage != null) {
      logEntry.Message = error.ExceptionMessage;
      logEntry.ErrorCode = HelperMethods.hashString(
        `${error.ExceptionMessage}${logEntry.BrowserName}${logEntry.Url}`,
        true
      );
    } else if (error.Message != null) {
      logEntry.Message = error.Message;
      if (error.Data != null) {
        const data = error.Data;
        if (data.ErrorCode != null) {
          logEntry.ErrorCode = data.ErrorCode;
          // sendLog = false;
        } else {
          logEntry.ErrorCode = HelperMethods.hashString(logEntry.Message, true);
        }
        if (data.ClientErrorId != null) {
          logEntry.ClientErrorId = data.ClientErrorId;
        }
      }
    } else if (error.message != null) {
      logEntry.Message = error.message;
      logEntry.ErrorCode = HelperMethods.hashString(error.toString(), true);
    } else if (typeof error === 'string') {
      logEntry.Message = error;
      logEntry.ErrorCode = HelperMethods.hashString(`${error}${logEntry.BrowserName}${logEntry.Url}`, true);
    } else {
      logEntry.Message = JSON.stringify(error);
      logEntry.ErrorCode = HelperMethods.hashString(`${error}${logEntry.BrowserName}${logEntry.Url}`, true);
    }
    logEntry.StackTrace = HelperMethods.updateStackTrace(error, logEntry);

    return logEntry;
  }

  private static updateStackTrace(error: any, logEntry: ErrorLogViewModel) {
    let stackTrace = logEntry.StackTrace;
    if (error.StackTrace != null) {
      stackTrace = JSON.stringify(error.StackTrace);
    } else if (error.StackTraceString != null) {
      stackTrace = error.StackTraceString;
    } else if (error.stack) {
      stackTrace = JSON.stringify(error.stack);
    } else {
      stackTrace = JSON.stringify(error);
    }
    return stackTrace;
  }

  public static async sendLogError(axios: AxiosInstance, logEntry: ErrorLogViewModel) {
    const logger: LoggerService = new LoggerService(axios);
    try {
      return await logger.postError(logEntry);
    } catch (e) {
      const message = 'The platform is temporarily unavailable';
      const hash = HelperMethods.hashString(message, true);
      const errorViewModel: IErrorViewModel = {
        message,
        hash,
        id: hash,
        redirect: null
      };
      EventBus.$emit(HelperMethods.errorModalEmission, errorViewModel);
      throw e;
    }
  }

  /**
   * replace a regex string with another string
   * @param string string to perform the replacement on
   * @param replaceWith string to replace with (Default: ' — ' (space em-dash space))
   * @param replaceWhat RegEx to be replaced (Default: /\|/gi)
   * @returns {string}
   */
  public static replacePipe(string: string, replaceWith: string = ' — ', replaceWhat: RegExp = /\|/gi): string {
    return string.replace(replaceWhat, replaceWith);
  }

  /**
   * compares two strings; result to be used with the .sort() array method
   * @param a first string
   * @param b second string
   * @param alphabetical sort strings alphabetically (false for reverse alpha)
   * @returns comparison result between first and second strings
   */
  public static sortString(a: string, b: string, alphabetical: boolean = true): number {
    const above = alphabetical ? -1 : 1;
    const below = alphabetical ? 1 : -1;

    if (a == null) {
      return b == null ? 0 : above;
    }
    if (b == null) {
      return below;
    }

    const textA = a.toLowerCase();
    const textB = b.toLowerCase();

    if (textA < textB) {
      return above;
    }
    if (textA > textB) {
      return below;
    }
    return 0;
  }
  /**
   * returns a rounded number string. Will indicate if the rounding would make it zero that the number is less than the rounding places.
   * @param number number to be rounded
   * @param places number of places to round to
   * @param abbr boolean whether to abbreviate large numbers or not.
   * @returns {string}
   */
  public static roundDecimal(number: number, places: number, abbr: boolean = false): string {
    if (number == null || typeof number !== 'number') {
      throw new TypeError("'number' has to be a number");
    }
    if (places == null || typeof places !== 'number') {
      throw new TypeError("'places' has to be a number");
    }

    const roundedNumber: number = parseFloat(number.toFixed(places));
    if (roundedNumber === 0) {
      const sNumber: string = number.toFixed(20);
      const test: number = parseFloat(sNumber.substring(sNumber.indexOf('.') + places));
      if (test > 0) {
        let lessValue = '<0.';
        while (places > 1) {
          lessValue += '0';
          places--;
        }
        lessValue += '1';
        return lessValue;
      }
    }

    if (abbr) {
      if (roundedNumber >= 1000000000) {
        return `${this.roundDecimal(roundedNumber / 1000000000, places)}B`;
      } else if (roundedNumber >= 1000000) {
        return `${this.roundDecimal(roundedNumber / 1000000, places)}M`;
      } else if (roundedNumber >= 1000) {
        return `${this.roundDecimal(roundedNumber / 1000, places)}K`;
      }
    }
    return roundedNumber.toString();
  }

  public static roundDecimalTranslated(number: number, places: number, translator: VueI18n, translationType: string, abbr: boolean = false): string {
    const roundedNumber: number = parseFloat(this.roundDecimal(number, places));

    if (abbr) {
      if (roundedNumber >= 1000000000) {
        return `${translator.n(parseFloat(this.roundDecimal(roundedNumber / 1000000000, places)), translationType)}B`;
      } else if (roundedNumber >= 1000000) {
        return `${translator.n(parseFloat(this.roundDecimal(roundedNumber / 1000000, places)), translationType)}M`;
      } else if (roundedNumber >= 1000) {
        return `${translator.n(parseFloat(this.roundDecimal(roundedNumber / 1000, places)), translationType)}K`;
      }
    }
    return translator.n(roundedNumber, translationType);
  }

  /**
   * Returns a hash of the passed in string. Primarily used to sanitize the string for use in name and id properties.
   * @param string: string
   * @param asHex?: boolean - default false
   * @returns {string}
   */
  public static hashString(string: string, asHex: boolean = false): string {
    /* jshint bitwise:false */
    let i: number;
    let l: number;
    let hval: number = 0x811C9DC5;

    for (i = 0, l = string.length; i < l; i++) {
      hval ^= string.charCodeAt(i);
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if (asHex) {
      // Convert to 8 digit hex string
      return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
    }
    return (hval >>> 0).toString();
  }

  /**
   * Adds a CSS class (which is expected to have an animation attached to it) to a HTML element and waits for animation to end before removing the class. Optionally runs a callback method at time of end event.
   * @param className: string
   * @param targetEl: HTMLElement
   * @param callback: Function - default null
   * @returns {Promise(void)}
   */
  public static async runAnimation(
    className: string,
    targetEl: HTMLElement,
    keepClassAfterAnimate: boolean = false
  ): Promise<any> {
    await new Promise((resolve: (value?: {} | PromiseLike<{}>) => void) => {
      targetEl.addEventListener('animationend', () => {
        if (!keepClassAfterAnimate) {
          targetEl.classList.remove(className);
        }
        resolve();
      });

      targetEl.classList.add(className);
    });
  }

  /**
   * Removes an animation class from an HTML element. For cases where the element doesn't hear the end event or if its set to run infinitely.
   * @param className: string
   * @param targetEl: HTMLElement
   * @returns {void}
   */
  public static killAnimation(className: string, targetEl: HTMLElement): void {
    targetEl.classList.remove(className);
  }

  /**
   * Bin string shortening. Allows for ellipsis to be on ends of text or to be shown after a set number of characters.
   * @param text : string
   * @param maxLength : number
   * @param clip: number
   * @param reverse: boolean
   * @param separator: string
   */
  public static shortenBinText(
    text: string,
    maxLength: number = 15,
    clip: number = 0,
    reverse: boolean = false,
    separator: string = '...'
  ) {
    if (text == null) {
      return text;
    }
    text = text.toString().trim();
    const pipe = '|';
    const nameSplit = text.split(pipe);
    const from = parseFloat(nameSplit[0]);
    const to = parseFloat(nameSplit[1]);

    if (nameSplit.length === 2 && !isNaN(from) && !isNaN(to)) {
      const places = !(from % 1) && !(to % 1) ? 0 : 2;
      return HelperMethods.shortenText(from.toFixed(places), maxLength / 2, 0, false) +
        pipe +
        HelperMethods.shortenText(to.toFixed(places), maxLength / 2, 0, false);
    }

    return this.shortenText(text, maxLength, clip, reverse, separator);
  }

  /**
   * String shortening. Allows for ellipsis to be on ends of text or to be shown after a set number of characters.
   * @param text : string
   * @param maxLength : number
   * @param clip: number
   * @param reverse: boolean
   * @param separator: string
   */
  public static shortenText(
    text: string,
    maxLength: number = 15,
    clip: number = 0,
    reverse: boolean = false,
    separator: string = '...'
  ) {
    if (text == null) {
      return text;
    }
    text = text.toString().trim();
    if (text.length <= maxLength) {
      return text;
    }
    if (clip >= maxLength) {
      clip = 0;
    }

    const clippedText: string = reverse ? text.substring(0, clip) : text.substring(text.length - clip, text.length);
    const shortenedText: string = reverse
      ? text.substring(text.length - maxLength + (separator.length + clip), text.length)
      : text.substring(0, maxLength - (separator.length + clip));

    return reverse ? clippedText + separator + shortenedText : shortenedText + separator + clippedText;
  }

  /**
   * @deprecated Use the asDate() function in date-time-utils.ts
   */
  public static asDate(date: any, asIs: boolean = false): Date {
    return dateUtils.asDate(date, asIs);
  }

  public static asMoment(date: DateTimeInput) {
    return date == null || date === '' ? null : moment(date);
  }

  /**
   * @deprecated Use the newGuid() function in string-utils.ts
   */
  public static newGuid(): string {
    return stringUtils.newGuid();
  }

  /**
   * Get the number of days in a particular month.
   * @param date: Date
   * @returns numberOfDays: number
   */
  public static getDaysInMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  public static isMinusSign(keyCode: number): boolean {
    return (
      keyCode === 109 || // numpad
      keyCode === 189 || // keyboard
      keyCode === 173
    ); // Firefox
  }

  public static isDelLeftOrRight(keyCode: number): boolean {
    return keyCode === 8 || keyCode === 46 || keyCode === 37 || keyCode === 39;
  }

  public static keyCodeToChar(keyCode: number): string {
    if (keyCode >= 96 && keyCode <= 105) {
      // Numpad keys
      return String.fromCharCode(keyCode - 48);
    } else {
      return String.fromCharCode(keyCode);
    }
  }

  /**
  * returns the value of a variable from the querystring.
  * @param variable
  * @returns {string}
  */
  public static getQueryVariable(variable: string): string {
    const hashs: string[] = window.location.search.substring(1).split('&');
    const vars: { [key: string]: string } = {};
    for (let i = 0; i < hashs.length; i++) {
      const pair: string[] = hashs[i].split('=');
      vars[pair[0]] = pair[1];
    }
    return vars[variable];
  }

  public static preventNonNumericalInput(event: KeyboardEvent) {
    // Firefox needs this, as type="number" is not enough.
    const keyCode = typeof event.which === 'undefined' ? event.keyCode : event.which;
    if (!HelperMethods.isDelLeftOrRight(keyCode) && !HelperMethods.isMinusSign(keyCode)) {
      const charStr = HelperMethods.keyCodeToChar(keyCode);
      if (!charStr.match(/^[0-9-]+$/)) {
        event.preventDefault();
      }
    }
  }

  /**
  * Debouncing method that runs passed in function.
  * @param name: string
  * @param delay: number
  * @param action: Function
  * @returns {void}
  */
  public static debounce(name: string, delay: number, action: Function): void {
    this.clearDebounce(name);

    window[`debounce${name}`] = window.setTimeout(action, delay);
  }
  /**
  * Manually clears debounce method by name
  * @param name: string
  * @returns {void}
  */
  public static clearDebounce(name: string): void {
    window.clearTimeout(window[`debounce${name}`]);
  }

  /**
  * Find the index of an item in an ordered list using a binary search. A function can be passed in cases where the sortedArray is not made up of simple types.
  * @param sortedArray : Array<any>
  * @param valueToFind : string | number | Date
  * @param comparePropertyFunction : (sortedArrayItem: any) => string | number | Date
  * @returns {number}
  */
  public static findIndexBinarySearch(sortedArray: Array<any>, valueToFind: string | number | Date, comparePropertyFunction: (sortedArrayItem: any) => string | number | Date = i => i): number {
    let lowIndex = 0;
    let highIndex = sortedArray.length - 1;
    while (lowIndex <= highIndex) {
      const midIndex = Math.floor((lowIndex + highIndex) / 2);
      if (valueToFind instanceof Date && (comparePropertyFunction(sortedArray[midIndex]) as Date).getTime() === valueToFind.getTime()) {
        return midIndex;
      } else if (comparePropertyFunction(sortedArray[midIndex]) === valueToFind) {
        return midIndex;
      } else if (comparePropertyFunction(sortedArray[midIndex]) < valueToFind) {
        lowIndex = midIndex + 1;
      } else {
        highIndex = midIndex - 1;
      }
    }

    return -1;
  }

  public static isMethod(object: object, functionName: string): boolean {
    return object != null && typeof object[functionName] === 'function';
  }

  public static spaceCase(text: string): string {
    return text.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
  }

  public static pushParentRouteChangeEvent(route: Route): void {
    EventBus.$emit(Events.ROUTE_CHANGE, route);
  }
}

export interface ICatchOptions {
  sendLog?: boolean;
  http401?: Function;
  http403?: Function;
  http404?: Function;
  http500?: Function;
  httpUnspecified?: Function;
}

export function valueOr(...variables: any[]) {
  for (const v of variables) {
    if (v != null) {
      return v;
    }
  }
  return null;
}

// This is just to make Vue.set type safe since it takes a string for the key
export function vueSet<T extends Record<string, any>, K extends keyof T>(obj: T, key: K, value: T[K]): void {
  if (obj != null && key != null) {
    Vue.set(obj, key.toString(), value);
  }
}

export function vueMerge<T extends Record<string, any>>(obj: T, replacements: Partial<T>): void {
  if (obj != null) {
    for (const key in replacements) {
      vueSet(obj, key, replacements[key]);
    }
  }
}

export function vueDelete<T extends Record<string, any>, K extends keyof T>(obj: T, key: K): void {
  if (obj != null && key != null) {
    Vue.delete(obj, key.toString());
  }
}

export function globalAppCatch(error: any) {
  HelperMethods.catch(getAxios(), error);
};
