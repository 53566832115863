import { Vue } from 'nuxt-property-decorator';
import {
  SocketConnection,
  ISocketConnectionOptions,
  SocketHandler,
  toSocketHandlerConfig
} from '@/modules/vue-signalr/socket-connection';

export default {
  install(vue: typeof Vue, options: ISocketConnectionOptions) {
    if (options.connection == null) {
      throw new Error('[Vue-SignalR] connection information is required.');
    }

    const socket = new SocketConnection(options);

    vue.socket = socket;

    vue.prototype.$socket = socket;

    vue.mixin({
      data() {
        return {
          socketHandlers: {}
        };
      },
      created(this: Vue) {
        if (socket != null && this.$options.sockets != null) {
          const methods = Object.getOwnPropertyNames(this.$options.sockets);

          methods.forEach((method: string) => {
            const handler = this.$options.sockets[method];
            const config = toSocketHandlerConfig(handler);
            socket.listen(method);
            this.socketHandlers[method] = (...args: any[]) => config.listener.call(this, ...args);
            socket.on(method, this.socketHandlers[method]);
          });
        }
      },
      beforeDestroy(this: Vue) {
        if (socket != null && this.$options.sockets != null) {
          const methods = Object.keys(this.socketHandlers);

          methods.forEach((method: string) => {
            socket.off(method, this.socketHandlers[method]);
          });
        }
      }
    });
  }
};

export * from '@/modules/vue-signalr/socket-connection';

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    sockets?: { [key: string]: SocketHandler };
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    readonly $socket?: SocketConnection;
    socketHandlers: {[key: string]: (args: any[]) => void}
  }
  interface VueConstructor {
    socket?: SocketConnection;
    sockets?: { [key: string]: SocketHandler };
  }
}

declare module 'vuex' {
  interface Store<S> {
    readonly $socket?: SocketConnection;
  }
}
